import * as React from "react";
import { connect, useDispatch } from "react-redux";
import { ApplicationState } from "../../store";
import { NuheatSwimlane } from "./NuheatSwimlane";
import { AlertType } from "../../store/AlertStore/models";
import { popupAlert } from "../../Utility";
import { Action, genericActionCreator, GenericActionCreator, mergeActionCreators, StateToUpdate } from "../../store/LegacyStore/actions";
import { nuheatHub } from "../../store/LegacyStore/hubs";
import { NuheatThermostat } from "../../store/LegacyStore/models/nuheat";
import { nuheatActionCreator, NuheatActionCreator } from "../../store/LegacyStore/actions/nuheat";

type Props =
    ApplicationState &
    GenericActionCreator &
    NuheatActionCreator;

const NuheatList = (props: Props) => {
    // Component state
    const [searchText, setSearchText] = React.useState("");
    const dispatch = useDispatch();

    /**
     * Normalize string.
     */
    const normalizeString = (stringToNormalize: string): string => (stringToNormalize || "").trim().toUpperCase();

    /**
     * Search for thermostats matchnig the search text.
     */
    const searchThermostats = () => {
        const normalizedSearchText = normalizeString(searchText);

        if (normalizedSearchText === "") {
            return props.legacy.NuHeat.thermostats
                .filter(thermostat => thermostat.workspaceID === props.workspace.focused.id)
                .map(thermostat => thermostat as NuheatThermostat);
        }

        return props.legacy.NuHeat.thermostats
            .filter(thermostat => thermostat.workspaceID === props.workspace.focused.id)
            .filter(thermostat => normalizeString(thermostat.internalStorage.name).includes(normalizedSearchText))
            .map(thermostat => thermostat as NuheatThermostat);
    };

    /**
     * Remove all thermostats in the selected workspace.
     */
    const removeAll = () => {
        if (window.confirm("Do you wish to remove all Nuheat thermostats?")) {
            props.legacy.NuHeat.thermostats
                .filter(t => t.workspaceID === props.workspace.focused.id)
                .forEach(t => props.invoke(t.thermostatID, Action.Remove));

            popupAlert(dispatch, "Thermostats removed!", "You have successfully removed all the thermostats.", AlertType.SUCCESS);
        }
    };

    /**
     * Saves all thermostats to database in the selected workspace.
     */
    const saveAll = () => {
        props.legacy.NuHeat.thermostats
            .filter(t => t.workspaceID === props.workspace.focused.id)
            .forEach(t => props.invoke(t.thermostatID, Action.Save));

        popupAlert(dispatch, "Thermostats saved!", "You have successfully saved all the thermostats.", AlertType.SUCCESS);
    };

    // Render
    return (
        <>
            {/*Search Bar*/}
            <p className="row">
                <label className="col-2">Search by Name:</label>
                <input
                    type="text"
                    className="form-control form-control-sm col-3"
                    style={{ outline: "none" }}
                    value={searchText}
                    onChange={(e) => setSearchText(e.target.value)} />
            </p>

            {/* Nuheat thermostats */}
            <p>
                <span className="badge badge-secondary badge-pill align-middle mt-n1">{searchThermostats().length}</span> Nuheat thermostats
                <button className="btn btn-sm btn-link text-primary mt-n1" onClick={() => saveAll()}>Save all</button>
                <button className="btn btn-sm btn-link text-danger mt-n1" onClick={() => removeAll()}>Remove all</button>
            </p>
            {searchThermostats().length > 0 ?
                <div>
                    {searchThermostats().map(thermostat =>
                        <NuheatSwimlane
                            key={thermostat.thermostatID}
                            thermostat={thermostat}
                            invokeAction={(action: Action) => props.invoke(thermostat.thermostatID, action)}
                            updateState={(value: any, toUpdate: StateToUpdate) => props.updateState(thermostat.thermostatID, value, toUpdate)}
                            ScheduleHoldUntil={(hours: number, setpoint: number) => props.scheduleHoldUntil(thermostat.thermostatID, hours, setpoint)}
                            ScheduleHoldPermanent={(setpoint: number) => props.scheduleHoldPermanent(thermostat.thermostatID, setpoint)}
                        />
                    )}
                </div>
                :
                <p className="text-center">There are no Nuheat thermostats in the selected workspace! Try a different workspace.</p>
            }
        </>
    );
};

export default connect(
    (state: ApplicationState) => state,
    mergeActionCreators(genericActionCreator(nuheatHub), nuheatActionCreator(nuheatHub))
)(NuheatList as any);
