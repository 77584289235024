import * as React from "react";
import { useDispatch } from "react-redux";
import { AlertType } from "../../store/AlertStore/models";
import { Category, Workspace } from "../../store/WorkspaceStore/models";
import { popupAlert } from "../../Utility";
import { ModalStatus } from "./WorkspaceDropdown";

interface Props {
    workspace: Workspace,
    category: Category,
    categories: Category[],
    status: ModalStatus,
    parentProps: any,
}

const WorkspaceModal = (props: Props) => {
    const [workspaceName, setWorkspaceName] = React.useState("");
    const [workspaceAuthor, setWorkspaceAuthor] = React.useState("");
    const [workspaceCategory, setWorkspaceCategory] = React.useState<Category>({} as Category);
    const dispatch = useDispatch();

    React.useEffect(() => {
        setWorkspaceName(props.workspace.name);
        setWorkspaceAuthor(props.workspace.author);
        setWorkspaceCategory(props.category);
    }, [props.workspace, props.category, setWorkspaceName, setWorkspaceAuthor, setWorkspaceCategory]);

    /**
    * Maps the modal state to the right handler
    */
    const clickWorkspace = (): void => {
        switch (props.status) {
            case ModalStatus.Add:
                addWorkspace();

                break;
            case ModalStatus.Edit:
                editWorkspace();

                break;
            case ModalStatus.Clone:
                cloneWorkspace();

                break;
            default:
        }
    };

    /**
     * Handles creating a new workspace through the modal
     */
    const addWorkspace = (): void => {
        if (workspaceName.length === 0) {
            popupAlert(dispatch, "Enter in name!", "You will have to enter a valid name for the workspace.", AlertType.DANGER);

            return;
        }

        props.parentProps.addWorkspace(workspaceName, workspaceCategory.id, workspaceAuthor);
        popupAlert(dispatch, "Workspace added!", "You have successfully added a new workspace.", AlertType.SUCCESS);
    };

    /**
     * Handles editing a workspace through the modal
     */
    const editWorkspace = (): void => {
        if (workspaceName.length === 0) {
            popupAlert(dispatch, "Enter in name!", "You will have to enter a valid name for the workspace.", AlertType.DANGER);

            return;
        }

        props.workspace.name = workspaceName;
        props.workspace.categoryID = workspaceCategory.id;
        props.workspace.author = workspaceAuthor;
        props.parentProps.updateWorkspace(props.workspace);
        popupAlert(dispatch, "Workspace updated!", "You have successfully updated the workspace.", AlertType.SUCCESS);
    };

    /**
    * Handles cloning a new workspace from another through the modal
    */
    const cloneWorkspace = (): void => {
        if (workspaceName.length === 0) {
            popupAlert(dispatch, "Enter in name!", "You will have to enter a valid name for the workspace.", AlertType.DANGER);

            return;
        }

        props.workspace.name = workspaceName;
        props.workspace.categoryID = workspaceCategory.id;
        props.workspace.author = workspaceAuthor;
        props.parentProps.cloneWorkspace(props.workspace);
        popupAlert(dispatch, "Workspace cloned!", "You have successfully cloned the workspace.", AlertType.SUCCESS);
    };

    return (
        <>
            <div className="modal fade" id="workspaceModal" tabIndex={-1} aria-labelledby="workspaceModalLabel" aria-hidden="true">
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="workspaceModalLabel">{props.status.toString()} Workspace</h5>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <form>
                                <div className="form-group">
                                    <label className="col-form-label">Name:</label>
                                    <input type="text" className="form-control" value={workspaceName || ""} onChange={e => setWorkspaceName(e.target.value)} />

                                    <label className="col-form-label">Category:</label>
                                    <div className="d-flex flex-column dropdown">
                                        <button className="btn btn-secondary dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                            {workspaceCategory === undefined ? "" : workspaceCategory.name }
                                        </button>
                                        <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                                            {props.categories.map(c =>
                                                <button key={c.id} type="button" className="dropdown-item" onClick={() => setWorkspaceCategory(c)}>{c.name}</button>
                                            )}
                                        </div>
                                    </div>

                                    <label className="col-form-label">Author:</label>
                                    <input type="text" className="form-control" value={workspaceAuthor || ""} onChange={e => setWorkspaceAuthor(e.target.value)} />
                                </div>
                            </form>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
                            <button type="button" className="btn btn-primary" data-dismiss="modal" onClick={() => clickWorkspace()}>{props.status.toString()} workspace</button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default WorkspaceModal;