import * as React from "react";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { AlertType } from "../../store/AlertStore/models";
import { ErrorDescriptions, Errors, UWG5NventSettings } from "../../store/UWG5NventStore/models";
import { popupAlert } from "../../Utility";
import { TableRowInput, TableRowToggle } from "../Common";

type Props = {
    isConnected: boolean,
    settings: UWG5NventSettings,
    errorState: Errors,
    isInStandby: boolean,
    saveSettings(settings: UWG5NventSettings): void,
    setErrors(errors: Errors): void,
    setStandbyState(isInStandby: boolean): void
}

export const UWG5NventSettingsComponent = (props: Props) => {
    // Component state mapped from props
    const [settings, setSettings] = React.useState(props.settings);
    const [isInStandby, setStandbyState] = React.useState(props.isInStandby);
    const [errorState, setErrorState] = React.useState(props.errorState);

    // internal state
    const dispatch = useDispatch();

    // force state to update when props changed from parent
    useEffect(() => setSettings(props.settings), [props.settings]);
    useEffect(() => setStandbyState(props.isInStandby), [props.isInStandby]);
    useEffect(() => setErrorState(props.errorState), [props.errorState]);

    const saveSettings = (updatedSettings?: UWG5NventSettings) => {
        const newSettings = {
            ...settings,
            ...(updatedSettings || {})
        };
        setSettings(newSettings);
        props.saveSettings(newSettings);
        popupAlert(dispatch, "Settings updated!", "You have successfully updated the thermostat settings.", AlertType.SUCCESS);
    };

    /**
     * Get className for error button.
     */
    const getErrorButtonClassName = (error: Errors): string => {
        return (errorState & error) === error ? "btn btn-sm btn-danger mr-1" : "btn btn-sm btn-secondary mr-1";
    };

    /**
     * Set error state.
     */
    const setErrorStateInternal = (error: Errors) => {
        let newErrorState = errorState;

        if ((errorState & error) === error) {
            newErrorState &= ~error;
        } else {
            newErrorState |= error;
        }

        setErrorState(newErrorState);
        props.setErrors(newErrorState);
    };

    // Render
    return (
        <table className="table table-sm mb-1">
            <tbody>
                <TableRowInput
                    name="Thermostat id"
                    readOnly
                    inputType="text"
                    currentValue={props.settings.deviceInternalId} />

                <TableRowInput
                    name="Server url"
                    disabled={props.isConnected}
                    inputType="text"
                    onSave={saveSettings}
                    onChange={newValue => setSettings({ ...settings, serverUrl: newValue as string })}
                    originalValue={props.settings.serverUrl}
                    currentValue={settings.serverUrl} />

                <TableRowToggle
                    name="SSL"
                    value={settings.useSsl === true}
                    ifTrue="Using SSL"
                    ifFalse="Not using SSL"
                    readOnly={props.isConnected}
                    onChange={newValue => saveSettings({ ...settings, useSsl: newValue })} />

                <TableRowInput
                    name="User id"
                    inputType="text"
                    onChange={newValue => setSettings({ ...settings, userId: newValue as string })}
                    disabled={props.isConnected}
                    onSave={saveSettings}
                    currentValue={settings.userId}
                    originalValue={props.settings.userId} />

                <TableRowInput
                    name="OTP Code"
                    inputType="text"
                    onChange={newValue => setSettings({ ...settings, otpCode: newValue as string })}
                    onSave={saveSettings}
                    currentValue={settings.otpCode}
                    originalValue={props.settings.otpCode} />

                <TableRowInput
                    name="Access Code"
                    inputType="text"
                    onChange={newValue => setSettings({ ...settings, accessCode: newValue as string })}
                    onSave={saveSettings}
                    currentValue={settings.accessCode}
                    originalValue={props.settings.accessCode} />

                <tr>
                    <th className="align-middle">Error Codes</th>
                    <td>
                        <button
                            className={getErrorButtonClassName(Errors.InternalFailure)}
                            onClick={() => setErrorStateInternal(Errors.InternalFailure)}
                            data-toggle="tooltip"
                            data-placement="top"
                            title={ErrorDescriptions.get(Errors.InternalFailure)}>E0</button>
                        <button
                            className={getErrorButtonClassName(Errors.InternalSensorFailure)}
                            onClick={() => setErrorStateInternal(Errors.InternalSensorFailure)}
                            data-toggle="tooltip"
                            data-placement="top"
                            title={ErrorDescriptions.get(Errors.InternalSensorFailure)}>E1</button>
                        <button
                            className={getErrorButtonClassName(Errors.ExternalWiredFloorSensorFailure)}
                            onClick={() => setErrorStateInternal(Errors.ExternalWiredFloorSensorFailure)}
                            data-toggle="tooltip"
                            data-placement="top"
                            title={ErrorDescriptions.get(Errors.ExternalWiredFloorSensorFailure)}>E2</button>
                        <button
                            className={getErrorButtonClassName(Errors.InternalCompensationSensorFailure)}
                            onClick={() => setErrorStateInternal(Errors.InternalCompensationSensorFailure)}
                            data-toggle="tooltip"
                            data-placement="top"
                            title={ErrorDescriptions.get(Errors.InternalCompensationSensorFailure)}>E3</button>
                        <button
                            className={getErrorButtonClassName(Errors.BootloaderError)}
                            onClick={() => setErrorStateInternal(Errors.BootloaderError)}
                            data-toggle="tooltip"
                            data-placement="top"
                            title={ErrorDescriptions.get(Errors.BootloaderError)}>E4</button>
                        <button
                            className={getErrorButtonClassName(Errors.InternalOverheating)}
                            onClick={() => setErrorStateInternal(Errors.InternalOverheating)}
                            data-toggle="tooltip"
                            data-placement="top"
                            title={ErrorDescriptions.get(Errors.InternalOverheating)}>E5</button>
                        <button
                            className={getErrorButtonClassName(Errors.CommunicationError)}
                            onClick={() => setErrorStateInternal(Errors.CommunicationError)}
                            data-toggle="tooltip"
                            data-placement="top"
                            title={ErrorDescriptions.get(Errors.CommunicationError)}>E6</button>
                        <button
                            className={getErrorButtonClassName(Errors.GFCIError)}
                            onClick={() => setErrorStateInternal(Errors.GFCIError)}
                            data-toggle="tooltip"
                            data-placement="top"
                            title={ErrorDescriptions.get(Errors.GFCIError)}>E7</button>
                    </td>
                    <td></td>
                </tr>

                <TableRowToggle
                    name="Standby"
                    value={isInStandby}
                    ifTrue="Thermostat is in standby"
                    ifFalse="Thermostat is operating normally"
                    onChange={newValue => props.setStandbyState(newValue)} />
            </tbody>
        </table>
    );
};
