export interface AlertState {
    alerts: Alert[];
}

export interface Alert {
    epoch: number,
    header: string,
    body: string,
    type: AlertType
}

export enum AlertType {
    PRIMARY = "alert-primary",
    SECONDARY = "alert-secondary",
    SUCCESS = "alert-success",
    DANGER = "alert-danger",
    WARNING = "alert-warning",
    INFO = "alert-info",
    LIGHT = "alert-light",
    DARK = "alert-dark"
}