import * as React from "react";
import { Collapse } from "reactstrap";
import { NuheatStateComponent } from "./NuheatState";
import { SwimlaneColourDot } from "../ColourDot";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { AlertType } from "../../store/AlertStore/models";
import { formatFullDates, popupAlert } from "../../Utility";
import { Action, StateToUpdate } from "../../store/LegacyStore/actions";
import { NuheatScheduleMode, NuheatThermostat } from "../../store/LegacyStore/models/nuheat";

type Props = {
    thermostat: NuheatThermostat,
    invokeAction(action: Action): void,
    updateState(value: any, toUpdate: StateToUpdate): void,
    ScheduleHoldUntil(hours: number, setpoint: number): void,
    ScheduleHoldPermanent(setpoint: number): void
}

export const NuheatSwimlane = (props: Props) => {
    // Component state mapped from props
    const [thermostat, setThermostat] = React.useState(props.thermostat);
    const dispatch = useDispatch();

    // Update state when props changed
    useEffect(() => setThermostat(props.thermostat), [props.thermostat]);

    // Internal state
    const [showDetails, setShowDetails] = React.useState(false);
    const [holdHours, setHoldHours] = React.useState("");
    const [wantedSetPoint, setWantedSetPoint] = React.useState("");

    const currMode = thermostat.internalStorage.scheduleMode;

    const defaultLocale = "en-US";

    const getModeLabel = (): string => {
        switch (currMode) {
            case NuheatScheduleMode.Auto:
                return "Auto";
            case NuheatScheduleMode.PermanentHold:
                return "Permanent Hold";
            case NuheatScheduleMode.Hold:
                if (thermostat.internalStorage.holdUntilUtc !== undefined) {
                    return `Hold until ${formatFullDates(thermostat.internalStorage.holdUntilUtc, defaultLocale)}`;
                } else {
                    return "Hold";
                }
            case NuheatScheduleMode.NotSpecified:
            default:
                return "Unspecified";
        }
    };

    const getSetpointLabel = (): string => {
        return `${thermostat.internalStorage.setPointInCelsius}°C`;
    };

    const saveThermostat = (): void => {
        props.invokeAction(Action.Save);
        popupAlert(dispatch, "Thermostat saved!", "You have successfully saved the thermostat.", AlertType.SUCCESS);
    };

    const removeThermostat = (): void => {
        props.invokeAction(Action.Remove);
        popupAlert(dispatch, "Thermostat removed!", "You have successfully removed the thermostat.", AlertType.SUCCESS);
    };

    // Render
    return (
        <div className="bg-white rounded-lg shadow-sm border mb-1">
            <div className="row pl-2 pt-2 pr-2 pb-0 mb-0">
                <div className="container ml-1 col">
                    <h5 className="d-flex">
                        {SwimlaneColourDot(props.thermostat.internalStorage.serialNumber)}
                        Thermostat name: {props.thermostat.internalStorage.name}
                    </h5>
                    <p className="text-muted">
                        {props.thermostat.currentState}
                    </p>
                </div>
                <div className="col-1">
                    <svg width="1.1em" height="1.1em" viewBox="0 0 16 16" className="bi bi-x-square btn-outline-danger float-right mt-1 mr-1" fill="currentColor" color="red" onClick={() =>  removeThermostat() }>
                        <path fillRule="evenodd" d="M14 1H2a1 1 0 0 0-1 1v12a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1zM2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2z" />
                        <path fillRule="evenodd" d="M11.854 4.146a.5.5 0 0 1 0 .708l-7 7a.5.5 0 0 1-.708-.708l7-7a.5.5 0 0 1 .708 0z" />
                        <path fillRule="evenodd" d="M4.146 4.146a.5.5 0 0 0 0 .708l7 7a.5.5 0 0 0 .708-.708l-7-7a.5.5 0 0 0-.708 0z" />
                    </svg>
                    <svg width="1.1em" height="1.1em" fill="currentColor" className="bi bi-save2 btn-outline-secondary float-right mt-1 mr-1" viewBox="0 0 16 16" color="gray" onClick={() => saveThermostat() }>
                        <path d="M2 1a1 1 0 0 0-1 1v12a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1H9.5a1 1 0 0 0-1 1v4.5h2a.5.5 0 0 1 .354.854l-2.5 2.5a.5.5 0 0 1-.708 0l-2.5-2.5A.5.5 0 0 1 5.5 6.5h2V2a2 2 0 0 1 2-2H14a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V2a2 2 0 0 1 2-2h2.5a.5.5 0 0 1 0 1H2z" />
                    </svg>
                </div>
            </div>
            <div className="container ml-2 mr-1 mb-1 pl-1">
                <div className="row">
                    <div className="col">
                        <b>SN: </b>{props.thermostat.internalStorage.serialNumber}
                    </div>

                    <div className="col">
                        <b>Mode: </b>{getModeLabel()}
                    </div>
                    <div className="col">
                        <b>Setpoint: </b>{getSetpointLabel()}
                    </div>
                    <div className="col">
                        <b>Room Temp: </b>{props.thermostat.internalStorage.currentTemperatureInCelsius}&deg;C
                    </div>
                </div>
            </div>

            <div className="row pl-2 pr-2 pb-2">
                <div className="container ml-1 col" style={{ display: "-webkit-inline-box" }}>
                    {props.thermostat.currentState !== "ReadyToConnect" ?
                        <>
                            <svg width="1.5em" height="1.7em" viewBox="0 0 16 16" className="bi bi-wifi mr-1" fill="currentColor" color={props.thermostat.currentState === "ReadyToUse" ? "green" : "orange"}>
                                <path d="M15.385 6.115a.485.485 0 0 0-.048-.736A12.443 12.443 0 0 0 8 3 12.44 12.44 0 0 0 .663 5.379a.485.485 0 0 0-.048.736.518.518 0 0 0 .668.05A11.448 11.448 0 0 1 8 4c2.507 0 4.827.802 6.717 2.164.204.148.489.13.668-.049z" />
                                <path d="M13.229 8.271c.216-.216.194-.578-.063-.745A9.456 9.456 0 0 0 8 6c-1.905 0-3.68.56-5.166 1.526a.48.48 0 0 0-.063.745.525.525 0 0 0 .652.065A8.46 8.46 0 0 1 8 7a8.46 8.46 0 0 1 4.577 1.336c.205.132.48.108.652-.065zm-2.183 2.183c.226-.226.185-.605-.1-.75A6.472 6.472 0 0 0 8 9c-1.06 0-2.062.254-2.946.704-.285.145-.326.524-.1.75l.015.015c.16.16.408.19.611.09A5.478 5.478 0 0 1 8 10c.868 0 1.69.201 2.42.56.203.1.45.07.611-.091l.015-.015zM9.06 12.44c.196-.196.198-.52-.04-.66A1.99 1.99 0 0 0 8 11.5a1.99 1.99 0 0 0-1.02.28c-.238.14-.236.464-.04.66l.706.706a.5.5 0 0 0 .708 0l.707-.707z" />
                            </svg>
                            <button
                                className="btn btn-sm btn-outline-primary mr-2"
                                onClick={() => props.invokeAction(Action.Disconnect)}>
                                Disconnect
                            </button>
                        </>
                        :
                        <>
                            <svg width="1.5em" height="1.7em" viewBox="0 0 16 16" className="bi bi-wifi mr-1" fill="currentColor" color="red">
                                <path d="M15.385 6.115a.485.485 0 0 0-.048-.736A12.443 12.443 0 0 0 8 3 12.44 12.44 0 0 0 .663 5.379a.485.485 0 0 0-.048.736.518.518 0 0 0 .668.05A11.448 11.448 0 0 1 8 4c2.507 0 4.827.802 6.717 2.164.204.148.489.13.668-.049z" />
                                <path d="M13.229 8.271c.216-.216.194-.578-.063-.745A9.456 9.456 0 0 0 8 6c-1.905 0-3.68.56-5.166 1.526a.48.48 0 0 0-.063.745.525.525 0 0 0 .652.065A8.46 8.46 0 0 1 8 7a8.46 8.46 0 0 1 4.577 1.336c.205.132.48.108.652-.065zm-2.183 2.183c.226-.226.185-.605-.1-.75A6.472 6.472 0 0 0 8 9c-1.06 0-2.062.254-2.946.704-.285.145-.326.524-.1.75l.015.015c.16.16.408.19.611.09A5.478 5.478 0 0 1 8 10c.868 0 1.69.201 2.42.56.203.1.45.07.611-.091l.015-.015zM9.06 12.44c.196-.196.198-.52-.04-.66A1.99 1.99 0 0 0 8 11.5a1.99 1.99 0 0 0-1.02.28c-.238.14-.236.464-.04.66l.706.706a.5.5 0 0 0 .708 0l.707-.707z" />
                            </svg>
                            <button
                                disabled={props.thermostat.internalStorage.isConnectingToServer}
                                className="btn btn-sm btn-outline-primary mr-2"
                                onClick={() => props.invokeAction(Action.Connect)}>
                                {props.thermostat.internalStorage.isConnectingToServer ? "Connecting..." : "Connect"}
                            </button>
                        </>
                    }

                    <span className="text-dark mr-1 ml-1">Wanted Setpoint</span>
                    <input
                        type="text"
                        className="border border-primary text-primary rounded text-right"
                        style={{ width: "3em", height: "1.7em", outline: "none" }}
                        value={wantedSetPoint}
                        onChange={(e) => setWantedSetPoint(e.target.value)} />

                    <svg width="25px" height="1.7em" viewBox="0 0 16 16" className="bi bi-check btn-outline-primary border border-primary rounded align-top" fill="currentColor" onClick={() => props.updateState(Number(wantedSetPoint), StateToUpdate.SetPoint)}>
                        <path fillRule="evenodd" d="M10.97 4.97a.75.75 0 0 1 1.071 1.05l-3.992 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425a.236.236 0 0 1 .02-.022z" />
                    </svg>
                    <svg width="20px" height="1.7em" viewBox="0 0 16 16" className="bi bi-plus btn-outline-primary border border-primary rounded align-top" fill="currentColor" onClick={() => props.invokeAction(Action.IncreaseSetpoint)}>
                        <path fillRule="evenodd" d="M8 3.5a.5.5 0 0 1 .5.5v4a.5.5 0 0 1-.5.5H4a.5.5 0 0 1 0-1h3.5V4a.5.5 0 0 1 .5-.5z" />
                        <path fillRule="evenodd" d="M7.5 8a.5.5 0 0 1 .5-.5h4a.5.5 0 0 1 0 1H8.5V12a.5.5 0 0 1-1 0V8z" />
                    </svg>
                    <svg width="20px" height="1.7em" viewBox="0 0 16 16" className="bi bi-dash btn-outline-primary border border-primary rounded align-top mr-1" fill="currentColor" onClick={() => props.invokeAction(Action.DecreaseSetpoint)}>
                        <path fillRule="evenodd" d="M3.5 8a.5.5 0 0 1 .5-.5h8a.5.5 0 0 1 0 1H4a.5.5 0 0 1-.5-.5z" />
                    </svg>

                    <button className="btn btn-sm btn-outline-primary ml-1" onClick={() => props.invokeAction(Action.SetAuto)}>
                        Auto
                    </button>
                    <button
                        className="btn btn-sm btn-outline-primary mr-1"
                        disabled={wantedSetPoint === ""}
                        onClick={() => props.ScheduleHoldPermanent(Number(wantedSetPoint))}>
                        Hold permanent
                    </button>
                    <button
                        className="btn btn-sm btn-outline-primary"
                        disabled={wantedSetPoint === "" || holdHours === ""}
                        onClick={() => props.ScheduleHoldUntil(Number(holdHours), Number(wantedSetPoint))}>
                        Hold until
                    </button>
                    <span className="text-dark mr-1 ml-1">Hours</span>
                    <input
                        type="text"
                        className="border border-primary text-primary rounded text-right"
                        style={{ width: "3em", height: "1.7em", outline: "none" }}
                        value={holdHours}
                        onChange={(e) => setHoldHours(e.target.value)} />
                </div>
                <div className="col-1">
                    {showDetails ?
                        <svg width="1.2em" height="1.2em" viewBox="0 0 16 16" className="bi bi-arrows-collapse btn-outline-secondary border border-secondary rounded float-right mr-1 mt-2" fill="currentColor" onClick={() => setShowDetails(!showDetails)}>
                            <path fillRule="evenodd" d="M2 8a.5.5 0 0 1 .5-.5h11a.5.5 0 0 1 0 1h-11A.5.5 0 0 1 2 8zm6-7a.5.5 0 0 1 .5.5V6a.5.5 0 0 1-1 0V1.5A.5.5 0 0 1 8 1z" />
                            <path fillRule="evenodd" d="M10.354 3.646a.5.5 0 0 1 0 .708l-2 2a.5.5 0 0 1-.708 0l-2-2a.5.5 0 1 1 .708-.708L8 5.293l1.646-1.647a.5.5 0 0 1 .708 0zM8 15a.5.5 0 0 0 .5-.5V10a.5.5 0 0 0-1 0v4.5a.5.5 0 0 0 .5.5z" />
                            <path fillRule="evenodd" d="M10.354 12.354a.5.5 0 0 0 0-.708l-2-2a.5.5 0 0 0-.708 0l-2 2a.5.5 0 0 0 .708.708L8 10.707l1.646 1.647a.5.5 0 0 0 .708 0z" />
                        </svg>
                        :
                        <svg width="1.2em" height="1.2em" viewBox="0 0 16 16" className="bi bi-arrows-expand btn-outline-secondary border border-secondary rounded float-right mr-1 mt-2" fill="currentColor" onClick={() => setShowDetails(!showDetails)}>
                            <path fillRule="evenodd" d="M2 8a.5.5 0 0 1 .5-.5h11a.5.5 0 0 1 0 1h-11A.5.5 0 0 1 2 8zm6-1.5a.5.5 0 0 0 .5-.5V1.5a.5.5 0 0 0-1 0V6a.5.5 0 0 0 .5.5z" />
                            <path fillRule="evenodd" d="M10.354 3.854a.5.5 0 0 0 0-.708l-2-2a.5.5 0 0 0-.708 0l-2 2a.5.5 0 1 0 .708.708L8 2.207l1.646 1.647a.5.5 0 0 0 .708 0zM8 9.5a.5.5 0 0 1 .5.5v4.5a.5.5 0 0 1-1 0V10a.5.5 0 0 1 .5-.5z" />
                            <path fillRule="evenodd" d="M10.354 12.146a.5.5 0 0 1 0 .708l-2 2a.5.5 0 0 1-.708 0l-2-2a.5.5 0 0 1 .708-.708L8 13.793l1.646-1.647a.5.5 0 0 1 .708 0z" />
                        </svg>
                    }
                </div>
            </div>

            <Collapse isOpen={showDetails}>
                <NuheatStateComponent isConnected={thermostat.internalStorage.isConnectedToServer}
                    state={thermostat.internalStorage}
                    invokeAction={props.invokeAction}
                    updateState={props.updateState}
                />
            </Collapse>

        </div>
    );
};
