export enum OWD5RegulationMode {
    NotSpecified,
    Auto,
    Comfort,
    Manual,
    Vacation,
    NotUsed01,
    FrostProtection,
    NotUsed02,
    Boost,
    Eco
}

export interface OWD5InternalStorage {
    name: string,
    serverIp: string,
    serverPort: number,
    setPointInCelsius: number,
    currentTemperatureInCelsius: number,
    serialNumber: string,
    softwareVersion: string,
    minSetPointInCelsius: number,
    maxSetPointInCelsius: number,
    regulationMode: OWD5RegulationMode,
    vacationEndDate: Date,
    comfortEndDate: Date,
    boostEndDate: Date,
    isLoggedOn: boolean,
    useDaylightSavingsTime: boolean,
    distributerId: number,
    isConnectedToServer: boolean,
    isConnectingToServer: boolean,
    isConfirmed: boolean
}

export interface OWD5Thermostat {
    ID: number,
    thermostatID: string,
    internalStorage: OWD5InternalStorage,
    currentState: string,
    workspaceID: number;
}