import * as React from "react";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { actionCreator } from "../../store/UWG5NventStore/actions";
import { connect } from "react-redux";
import { ApplicationState } from "../../store";

type Props =
    ApplicationState &
    typeof actionCreator;

const UWG5NventServerUrl = (props: Props) => {
    // Component state
    const [showModal, setShowModal] = React.useState(false);
    const [serverUrl, setServerUrl] = React.useState("");

    /**
     * Toggle modal visibility.
     */
    const toggleModalVisibility = () => {
        if (!showModal && props.uwg5Nvent.thermostats.length > 0) {
            setServerUrl(props.uwg5Nvent.thermostats[0].settings.serverUrl);
        }
        setShowModal(!showModal);
    };

    /**
     * Validate server url. Returns undefined if server url is valid,
     * else a validation message is returned.
     */
    const validateServerUrl = () => {
        const sanitizedServerUrl = (serverUrl || "").trim();
        const urlParts = sanitizedServerUrl.split(":");

        if (sanitizedServerUrl === "") {
            return "Server url is required";
        } else if (urlParts.length !== 2) {
            return "Port is required";
        } else if (urlParts[0].trim() === "") {
            return "Host is invalid";
        } else if (isNaN(parseInt(urlParts[1]))) {
            return "Port is invalid";
        } else {
            return undefined;
        }
    };

    /**
     * Check if server url can be saved based on current application state.
     */
    const canSaveServerUrl = () => {
        return !validateServerUrl() && props.uwg5Nvent.thermostats.length > 0;
    };

    /**
     * Save server url and close modal.
     */
    const saveServerUrl = () => {
        props.setServerUrl(serverUrl);
        toggleModalVisibility();
    };

    // Render
    return (
        <div className="btn text-bottom text-white" onClick={toggleModalVisibility}>
            Server URL
            <Modal isOpen={showModal} toggle={toggleModalVisibility}>
                <ModalHeader toggle={toggleModalVisibility}>What do you wish to set the Server URL to?</ModalHeader>
                <ModalBody>
                    <input
                        type="text"
                        className={`form-control ${!!validateServerUrl()}`}
                        value={serverUrl}
                        onChange={(e) => setServerUrl(e.target.value)} style={{ width: "-webkit-fill-available", outline: "none" }} />
                    <span className="font-weight-light">Server URL will only update on disconnected thermostats.</span>
                    <br />
                    <span className="text-danger">{validateServerUrl()}</span>
                </ModalBody>
                <ModalFooter>
                    {props.uwg5Nvent.thermostats.length > 0 ? null : <span className="text-danger">No thermostats to set server url on</span>}
                    <button
                        disabled={!canSaveServerUrl()}
                        className="btn btn-outline-primary"
                        onClick={() => saveServerUrl()}>Set URL</button>
                    <button
                        className="btn btn-outline-secondary"
                        onClick={toggleModalVisibility}>Cancel</button>
                </ModalFooter>
            </Modal>
        </div>
    );
};

export default connect(
    (state: ApplicationState) => state,
    actionCreator
)(UWG5NventServerUrl as any);