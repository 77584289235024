import * as React from "react";
import { useLocation } from "react-router-dom";
import { Page } from ".";

export const NotFound = () => {
    const location = useLocation();

    return (
        <Page title="Not found">
            <p>The page you're looking for was not found.</p>
            <pre className="p-2 bg-light m-0 rounded-lg">{location.pathname}{location.search}{location.hash}</pre>
        </Page>
    );
};
