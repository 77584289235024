import { Store } from "redux";
import { Category, Workspace } from "./models";
import {
    NewFocusedWorkspace,
    NewCategory,
    ReceieveUserCategories,
    RemoveCategory,
    UpdateCategory
} from "./actionTypes";
import { buildHub } from "../../ApiGateway";
import { HubConnectionState } from "@microsoft/signalr";

// Create Workspace hub
export const workspaceHub = buildHub("WorkspaceHub");

/**
 * Connect to Workspace SignalR hub and register handlers.
 */
export function connectToWorkspaceHub(store: Store) {
    if (workspaceHub.state !== HubConnectionState.Disconnected) {
        return;
    }

    workspaceHub.start()
        .catch(err => console.error("Failed to start WorkspaceHub", err));

    workspaceHub.on("ReceiveFocusedWorkspace", (selected: Workspace) => {
        store.dispatch({ type: "NEW_FOCUSED_WORKSPACE", selected } as NewFocusedWorkspace);
    });

    workspaceHub.on("ReceiveUserCategories", (categories: Category[]) => {
        store.dispatch({ type: "RECEIVE_ALL_USER_CATEGORIES", categories } as ReceieveUserCategories);
    });

    workspaceHub.on("ReceieveNewCategory", (category: Category) => {
        store.dispatch({ type: "NEW_CATEGORY", category } as NewCategory);
    });

    workspaceHub.on("RemoveCategory", (category: Category) => {
        store.dispatch({ type: "REMOVE_CATEGORY", category } as RemoveCategory);
    });

    workspaceHub.on("ReceieveUpdatedCategory", (category: Category) => {
        store.dispatch({ type: "UPDATE_CATEGORY", category } as UpdateCategory);
    });
}
