import { UserManager, UserManagerSettings } from "oidc-client";
import { createUserManager } from "redux-oidc";

let userManager: UserManager;

/**
 * Initialize user manager with UserManagerSettings.
 * Throws error if the user manager has already been initialized.
 */
export const initializeUserManager = (settings: UserManagerSettings) => {
    if (userManager === undefined) {
        userManager = createUserManager(settings);

        return userManager;
    } else {
        throw Error("User manager is already initialized");
    }
};

/**
 * Get user manager.
 * Throws error if the user manager has not been initialized.
 */
export const getUserManager = () => {
    if (userManager === undefined) {
        throw Error("User manager is not initialized");
    } else {
        return userManager;
    }
};

/**
 * Redirect to user to the sign in page. The user will be redirected
 * back to the current pathname after signing in.
 */
export const signIn = () => {
    const redirectUrl = window.location.pathname || "/";
    const args = { data: { uri: redirectUrl } };
    getUserManager().signinRedirect(args);
};

/**
 * Sign out user.
 */
export const signOut = () => {
    getUserManager().signoutRedirect();
};

export const getUserAccessToken = () : Promise<string | null> => {
    return getUserManager().getUser()
        .then(u => u?.access_token || null)
        .catch(e => {
            console.error("Failed to get access token", e);

            return null;
        });
};
