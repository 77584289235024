import React, { FC } from "react";

export interface NumberInputProps {
    value: number
    onChange?: (newValue: number) => void
    readOnly?: boolean
    disabled?: boolean
    maxValue?: number
    minValue?: number
    floatingPoint?: boolean
    className?: string
}

export const NumberInput: FC<NumberInputProps> = (props) => {
    const handleUpdate = (newValue: string) => {
        // Check if handler exists
        if (!props.onChange) {
            return;
        }
        const newNumberValue = props.floatingPoint ? Number(newValue) : parseInt(newValue);

        // Check if number is NaN
        if (isNaN(newNumberValue)) {
            return;
        }

        // Check if number is within range
        if (props.minValue !== undefined && newNumberValue < props.minValue) {
            return;
        } else if (props.maxValue !== undefined && newNumberValue > props.maxValue) {
            return;
        }

        // Call handler
        props.onChange(newNumberValue);
    };

    return (
        <>
            <input
                readOnly={props.readOnly}
                disabled={props.disabled}
                value={String(props.value)}
                type="number"
                max={props.maxValue}
                min={props.minValue}
                className={`form-control ${props.className}`}
                onChange={event => handleUpdate(event.target.value)} />
        </>
    );
};
