import React, { FC, useState, useEffect } from "react";
import { TableRowInput } from "../Common";
import { DeviceInfo as Model } from "../../store/UWG5NventStore/models";

export interface DeviceInfoProps {
    deviceInfo: Model,
    isThermostatConnected: boolean;
    saveDeviceInfo: (newDeviceInfo: Model) => void
}

export const DeviceInfo: FC<DeviceInfoProps> = (props) => {
    const [deviceInfo, setDeviceInfo] = useState(props.deviceInfo);

    useEffect(() => {
        setDeviceInfo(props.deviceInfo);
    }, [props.deviceInfo]);

    const set = (obj: Partial<Model>) => {
        setDeviceInfo({
            ...deviceInfo,
            ...obj
        });
    };

    const handleSave = () => {
        props.saveDeviceInfo(deviceInfo);
    };

    return (
        <table className="table table-sm mb-1">
            <tbody>
                <TableRowInput
                    name="Serial Id"
                    inputType="number"
                    maxValue={4294967295 /* maximum value of uint32 */}
                    minValue={0}
                    onChange={newValue => set({ serialId: Number(newValue) })}
                    disabled={props.isThermostatConnected}
                    onSave={handleSave}
                    currentValue={deviceInfo.serialId}
                    originalValue={props.deviceInfo.serialId} />

                <TableRowInput
                    name="Thermostat name"
                    inputType="text"
                    onChange={newValue => set({ thermostatName: newValue as string })}
                    onSave={handleSave}
                    currentValue={deviceInfo.thermostatName}
                    originalValue={props.deviceInfo.thermostatName} />

                <TableRowInput
                    name="Distributor Id"
                    currentValue={deviceInfo.distributorId}
                    originalValue={props.deviceInfo.distributorId}
                    inputType="number"
                    onSave={handleSave}
                    onChange={newValue => set({ distributorId: Number(newValue) })} />

                <TableRowInput
                    name="Thermostat software version"
                    inputType="text"
                    onChange={newValue => set({ thermostatSoftware: newValue as string })}
                    onSave={handleSave}
                    currentValue={deviceInfo.thermostatSoftware}
                    originalValue={props.deviceInfo.thermostatSoftware} />

                <TableRowInput
                    name="Thermostat bootloader software version"
                    inputType="text"
                    onChange={newValue => set({ thermostatBootloaderSoftware: newValue as string })}
                    onSave={handleSave}
                    currentValue={deviceInfo.thermostatBootloaderSoftware}
                    originalValue={props.deviceInfo.thermostatBootloaderSoftware} />

                <TableRowInput
                    name="Ublox serial"
                    inputType="text"
                    onChange={newValue => set({ ubloxSerial: newValue as string })}
                    onSave={handleSave}
                    currentValue={deviceInfo.ubloxSerial}
                    originalValue={props.deviceInfo.ubloxSerial} />

                <TableRowInput
                    name="Ublox software version"
                    inputType="text"
                    onChange={newValue => set({ ubloxSoftware: newValue as string })}
                    onSave={handleSave}
                    currentValue={deviceInfo.ubloxSoftware}
                    originalValue={props.deviceInfo.ubloxSoftware} />

                <TableRowInput
                    name="Hardware version identifier"
                    inputType="text"
                    onChange={newValue => set({ hardwareVersionIdentifier: newValue as string })}
                    onSave={handleSave}
                    currentValue={deviceInfo.hardwareVersionIdentifier}
                    originalValue={props.deviceInfo.hardwareVersionIdentifier} />

                <TableRowInput
                    name="Serial Flash Type"
                    inputType="text"
                    onChange={newValue => set({ serialFlashType: newValue as string })}
                    onSave={handleSave}
                    currentValue={deviceInfo.serialFlashType}
                    originalValue={props.deviceInfo.serialFlashType} />

                <TableRowInput
                    name="MCU UUID"
                    inputType="text"
                    readOnly
                    onSave={handleSave}
                    currentValue={String(deviceInfo.mcuUuid)}
                    originalValue={String(props.deviceInfo.mcuUuid)} />

                <TableRowInput
                    name="Protocol Version"
                    inputType="text"
                    readOnly
                    onSave={handleSave}
                    currentValue={String(deviceInfo.protocolVersion)}
                    originalValue={String(props.deviceInfo.protocolVersion)} />
            </tbody>
        </table>
    );
};
