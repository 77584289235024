// Shared

export interface ThermostatType {
    thermostatType: LegacyThermostatType
}

export enum LegacyThermostatType {
    UWG4,
    NuHeat,
    Senz,
    OWD5
}

export interface Thermostat {
    ID: number,
    thermostatID: string,
    internalStorage: InternalStorage,
    currentState: string,
    workspaceID: number;
}

export interface InternalStorage {
    name: string,
    serverIp: string,
    serverPort: number,
    setPointInCelsius: number,
    currentTemperatureInCelsius: number,
    serialNumber: string,
    email: string,
    softwareVersion: string,
    minSetPointInCelsius: number,
    maxSetPointInCelsius: number,
    isLoggedOn: boolean,
    useDaylightSavingsTime: boolean,
    isConnectedToServer: boolean,
    isConnectingToServer: boolean,
    isConfirmed: boolean
}

export interface CreateLegacyState {
    serverIp: string,
    serialNumber: string,
    email: string
}

export interface ThermostatSlice {
    NuHeat: Thermostats,
    UWG4: Thermostats,
    Senz: Thermostats,
    OWD5: Thermostats
}

export interface Thermostats {
    thermostats: Thermostat[],
    loadable: Thermostat[];
}