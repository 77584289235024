import { Action, Reducer } from "redux";
import { Thermostats, ThermostatSlice, LegacyThermostatType } from "./models";
import { KnownAction } from "./actionTypes";

const slice: ThermostatSlice = {
    NuHeat: { thermostats: [], loadable: [] },
    UWG4: { thermostats: [], loadable: [] },
    Senz: { thermostats: [], loadable: [] },
    OWD5: { thermostats: [], loadable: [] }
};

const unloadedThermostats: Thermostats = {
    thermostats: [],
    loadable: []
};

function logTypeReducer(state: Thermostats, knownAction: KnownAction): Thermostats {
    if(state === undefined){
        return unloadedThermostats;
    }

    switch (knownAction.type) {
        case "RECEIVE_ALL_TYPE_THERMOSTATS":
            return { ...state, thermostats: knownAction.thermostats };
        case "RECEIVE_NEW_TYPE_THERMOSTAT":
            return { ...state, thermostats: [...state.thermostats, knownAction.thermostat] };
        case "UPDATE_TYPE_THERMOSTAT_STATE":
            return { ...state, thermostats: state.thermostats.map(thermostat => (thermostat.thermostatID === knownAction.thermostat.thermostatID ? knownAction.thermostat : thermostat)) };
        case "REMOVE_TYPE_THERMOSTAT":
            return { ...state, thermostats: state.thermostats.filter(thermostat => thermostat.thermostatID !== knownAction.id) };
        case "RECEIVE_LOADABLE_TYPE_THERMOSTATS":
            return { ...state, loadable: knownAction.thermostats };
        case "ADD_LOADABLE_TYPE_THERMOSTAT":
            return { ...state, loadable: [...state.loadable, knownAction.thermostat] };
        case "REMOVE_LOADABLE_TYPE_THERMOSTAT":
            return { ...state, loadable: state.loadable.filter(thermostat => thermostat.thermostatID !== knownAction.id) };
        case "UPDATE_LOADABLE_TYPE_THERMOSTAT":
            return { ...state, loadable: state.loadable.map(thermostat => (thermostat.thermostatID === knownAction.thermostat.thermostatID ? knownAction.thermostat : thermostat)) };
        default:
            return state;
    }
}

export const thermostatReducer: Reducer<ThermostatSlice> = (state: ThermostatSlice | undefined, incomingAction: Action): ThermostatSlice => {
    if (state === undefined) {
        return slice;
    }

    const action = incomingAction as KnownAction;
    const thermostatType: LegacyThermostatType = action.thermostatType;
    const thermostatKey: keyof typeof LegacyThermostatType = LegacyThermostatType[thermostatType] as keyof typeof LegacyThermostatType;

    return {
        ...state,
        [thermostatKey]: logTypeReducer(state[thermostatKey], action)
    };
};

