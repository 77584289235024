export enum NuheatOperatingMode {
    NotSpecified = 0,
    Auto = 1,
    Manual = 2
}

export enum NuheatScheduleMode {
    NotSpecified = 0,
    Auto = 1,
    Hold = 2,
    PermanentHold = 3
}

export interface NuheatInternalStorage {
    name: string,
    serverIp: string,
    serverPort: number,
    setPointInCelsius: number,
    currentTemperatureInCelsius: number,
    serialNumber: string,
    email: string,
    softwareVersion: string,
    minSetPointInCelsius: number,
    maxSetPointInCelsius: number,
    scheduleMode: NuheatScheduleMode,
    operatingMode: NuheatOperatingMode,
    holdUntilUtc?: Date,
    zipCode: string,
    city: string,
    isLoggedOn: boolean,
    useDaylightSavingsTime: boolean,
    distributorId: number,
    isConnectedToServer: boolean,
    isConnectingToServer: boolean,
    isConfirmed: boolean,
    isEmailChangeRequested: boolean,
    newEmail?: string;
}

export interface NuheatThermostat {
    ID: number,
    thermostatID: string,
    internalStorage: NuheatInternalStorage,
    currentState: string,
    workspaceID: number;
}