import { AppThunkAction } from "../..";
import { KnownAction } from "../actionTypes";
import { CreateLegacyState } from "../models";
import { HubConnection } from "@microsoft/signalr";

export enum Action {
    CreateNew = "CreateNew",
    Remove = "RemoveThermostat",
    Connect = "Connect",
    Disconnect = "Disconnect",
    IncreaseSetpoint = "IncreaseSetpoint",
    DecreaseSetpoint = "DecreaseSetpoint",
    Save = "SaveThermostat",
    SetAuto = "SetAuto"
}

export enum StateToUpdate {
    ServerURL = "UpdateServerUrl",
    SerialNumber = "UpdateSerialNumber",
    Email = "UpdateEmail",
    Name = "UpdateName",
    ZipCode = "UpdateZipCode",
    SetPoint = "UpdateSetPoint"
}

export interface GenericActionCreator {
    createNew: (workspace: number, settings?: CreateLegacyState | null, amount?: number) => void;
    invoke: (id: string, action: Action) => void;
    updateState: (id: string, value: any, state: StateToUpdate) => void;
    setServerUrl: (serverUrl: string, port: number) => void;
    fetchLoadableThermostats: () => void;
    loadThermostats: (ids: string[]) => void;
    deleteThermostats: (ids: string[]) => void;
}

export function genericActionCreator(connection: HubConnection) {
    return {
        createNew: (workspace: number, settings: CreateLegacyState | null = null, amount: number = 1): AppThunkAction<KnownAction> => (_dispatch, _getState) => {
            connection.send("CreateNewThermostat", workspace, amount, settings).catch(alert);
        },
        invoke: (id: string, action: Action): AppThunkAction<KnownAction> => (_dispatch, _getState) => {
            connection.send(action, id).catch(alert);
        },
        updateState: (id: string, value: any, state: StateToUpdate): AppThunkAction<KnownAction> => (_dispatch, _getState) => {
            connection.send(state, id, value).catch(alert);
        },
        setServerUrl: (serverUrl: string, port: number): AppThunkAction<KnownAction> => (_dispatch, _getState) => {
            connection.send("SetServerUrl", serverUrl, port).catch(alert);
        },
        fetchLoadableThermostats: (): AppThunkAction<KnownAction> => (_dispatch, _getState) => {
            connection.send("FetchLoadableThermostats").catch(alert);
        },
        loadThermostats: (ids: string[]): AppThunkAction<KnownAction> => (_dispatch, _getState) => {
            connection.send("LoadThermostats", ids).catch(alert);
        },
        deleteThermostats: (ids: string[]): AppThunkAction<KnownAction> => (_dispatch, _getState) => {
            connection.send("DeleteThermostats", ids).catch(alert);
        },
    };
}

export function mergeActionCreators(genericActionCreator: object, concreteActionCreator: object){
    return { ...genericActionCreator, ...concreteActionCreator };
}
