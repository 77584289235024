import * as React from "react";
import { getColor } from "../Utility";

export const SwimlaneColourDot = (thermostatId: string | number) => (
    <svg width="1em" height="1em" viewBox="0 0 16 16" className="drop-shadow-sm bi bi-circle-fill mr-1 mt-1 w-50" fill="currentColor" style={{ color: getColor(thermostatId) }}>
        <circle cx="8" cy="8" r="8" />
    </svg>
);

export const LogBoxColourDot = (thermostatId: string | number) => (
    <svg width="1em" height="1em" viewBox="0 0 16 16" className="drop-shadow-sm bi bi-circle-fill mr-1" fill="currentColor" style={{ color: getColor(thermostatId) }}>
        <circle cx="8" cy="8" r="8" />
    </svg>
);
