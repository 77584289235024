import * as React from "react";
import { connect, useDispatch } from "react-redux";
import { AlertType } from "../../store/AlertStore/models";
import { ApplicationState } from "../../store";
import { actionCreator } from "../../store/SimpleWifiStore/actions";
import { SimpleWifiThermostat } from "../../store/SimpleWifiStore/models";
import { normalizeString, popupAlert } from "../../Utility";
import { requiresAuthentication } from "../Common";

type Props =
    ApplicationState &
    typeof actionCreator;

const SimpleWifiLoadWizard = (props: Props) => {
    // Component state
    const [searchTerm, setSearchTerm] = React.useState("");
    const [selectedThermostats, setSelectedThermostats] = React.useState<string[]>([]);
    const checkbox = React.useRef(null);
    const dispatch = useDispatch();

    //Update when the workspaces slice changes (ie. new selected workspace)
    React.useEffect(() => {
        //Ensuring the SignalR hub is indeed connected before fetching
        setTimeout(() => props.fetchLoadableThermostats, 1000);
    }, [props.workspace, props.fetchLoadableThermostats]);

    /**
    * Handle searching for simulated thermostat(s).
    */
    const search = () => {
        const normalizedSearchTerm = normalizeString(searchTerm);

        if (normalizedSearchTerm === "") {
            return props.simpleWifi.loadable
                .filter(thermostat => thermostat.workspaceID === props.workspace.focused.id);
        }

        return props.simpleWifi.loadable
            .filter(thermostat => thermostat.workspaceID === props.workspace.focused.id)
            .filter(thermostat =>
                normalizeString(thermostat.deviceInfo.thermostatName).includes(normalizedSearchTerm) ||
                normalizeString(thermostat.deviceInfo.serialId).includes(normalizedSearchTerm) ||
                normalizeString(thermostat.settings.userId).includes(normalizedSearchTerm));
    };

    /**
    * Load selected thermostats.
    */
    const loadThermostats = () => {
        props.loadThermostats(selectedThermostats);
        popupAlert(dispatch, "Thermostats loaded!", "You have successfully loaded the selected thermostats!", AlertType.SUCCESS);
    };

    /**
     * Delete selected thermostats.
     */
    const deleteThermostats = () => {
        props.deleteThermostats(selectedThermostats);
        setSelectedThermostats([]);
        popupAlert(dispatch, "Thermostats deleted!", "You have successfully deleted the selected thermostats.", AlertType.SUCCESS);
    };

    const toggleCheckedAll = () => {
        const items = search();
        if (selectedThermostats.length === items.length) {
            setSelectedThermostats([]);
        } else {
            setSelectedThermostats(items.map(x => x.thermostatID));
        }
    };

    const checkboxClicked = (thermostat: SimpleWifiThermostat): void => {
        const index = selectedThermostats.findIndex(t => t === thermostat.thermostatID);

        if (index > -1) {
            //Remove
            if (selectedThermostats.length === 1) {
                setSelectedThermostats([]);
            } else {
                const items = selectedThermostats.slice();
                items.splice(index, 1);
                setSelectedThermostats(items);
            }
        } else {
            //Add
            setSelectedThermostats(previous => [...previous, thermostat.thermostatID]);
        }
    };

    const list = search()
        .map((thermostat, index) =>
            <div className={`d-flex p-3 ${index === 0 ? "" : "border-top"}`} key={thermostat.thermostatID} >
                <div className="d-flex justify-content-center align-items-center mr-4" style={{ width: 110, borderRight: "1px solid #e3e8ee" }}>
                    <input style={{ height: 30, width: 30 }} type="checkbox" checked={selectedThermostats.indexOf(thermostat.thermostatID) > -1} onChange={() => checkboxClicked(thermostat)} ref={checkbox} />
                </div>
                <div>
                    <b>{thermostat.deviceInfo.thermostatName === "" ? <i>No Name</i> : thermostat.deviceInfo.thermostatName}</b> <br />
                    <span style={{ fontSize: 14 }}><b>Serial number:</b> {thermostat.deviceInfo.serialId} </span><br />
                    <span style={{ fontSize: 14 }}><b>User ID:</b> {thermostat.settings.userId} </span><br />
                </div>
            </div>
        );

    // Render
    return (
        <div className="bg-white rounded-lg shadow border p-3">
            <h3 className="font-weight-light mb-3">
                <svg width="1em" height="1em" viewBox="0 0 16 16" className="bi bi-plus-circle mt-n2 mr-1" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                    <path fillRule="evenodd" d="M8 3.5a.5.5 0 0 1 .5.5v4a.5.5 0 0 1-.5.5H4a.5.5 0 0 1 0-1h3.5V4a.5.5 0 0 1 .5-.5z" />
                    <path fillRule="evenodd" d="M7.5 8a.5.5 0 0 1 .5-.5h4a.5.5 0 0 1 0 1H8.5V12a.5.5 0 0 1-1 0V8z" />
                    <path fillRule="evenodd" d="M8 15A7 7 0 1 0 8 1a7 7 0 0 0 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                </svg><b>UWG5</b> <span className="text-muted">Load Wizard</span>
            </h3>

            <div className="form-inline">
                <input type="text"
                    placeholder="Search..."
                    className="form-control"
                    autoFocus
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)} />
                <button
                    className="btn btn-primary ml-2"
                    disabled={selectedThermostats.length === 0}
                    onClick={() => loadThermostats()}>Load ({selectedThermostats.length})</button>
                <button
                    className="btn btn-danger ml-2"
                    disabled={selectedThermostats.length === 0}
                    onClick={() => deleteThermostats()}>Delete ({selectedThermostats.length})</button>
                <button
                    className="btn btn-success ml-2"
                    onClick={() => toggleCheckedAll()}>Check/uncheck all</button>
            </div>

            {search().length > 0 ?
                list
                :
                <p className="text-center">There are no saved UWG5 thermostats in the current workspace.</p>
            }
        </div>
    );
};

export default requiresAuthentication(connect(
    (state: ApplicationState) => state,
    actionCreator
)(SimpleWifiLoadWizard as any));