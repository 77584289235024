import * as React from "react";
import { ScheduleEvent } from "../../store/UWG5NventStore/models";

type Props = {
    day: number,
    events: ScheduleEvent[],
    refresh: boolean,
    setCurrentScheduleEvent(day: number, index: number): void
}

const getTime = (minutes: number) => {
    const m = minutes % 60;
    const h = (minutes-m)/60;

    return (h < 10 ? "0" : "") + h.toString() + ":" + (m < 10 ? "0" : "") + m.toString();
};

const isActive = (event: ScheduleEvent) => {
    if (!event.enabled) {
        return false;
    }
    const now = new Date();
    const minutesSinceMidnight = now.getHours() * 60 + now.getMinutes();
    return minutesSinceMidnight >= event.startTimeM && minutesSinceMidnight <= event.endTimeM;
};

export const UWG5NventScheduleDay = (props: Props) => {
    const onEventSelected = (index: number) => {
        props.setCurrentScheduleEvent(props.day, index);
    };

    return (
        <div className="mr-2">
            <table className="table table-hover table-sm mb-0">
                <thead>
                    <tr>
                        <td><b>Start Time</b></td>
                        <td><b>End Time</b></td>
                        <td><b>Setpoint Temp</b></td>
                        <td><b>Room Temp</b></td>
                        <td><b>Enabled</b></td>
                    </tr>
                </thead>
                <tbody>
                    {props.events
                        .map((event, index) =>
                            <tr onClick={() => onEventSelected(index)} key={event.index} className={event.isActive || isActive(event) ? "bg-success" : ""}>
                                <td>{getTime(event.startTimeM)}</td>
                                <td>{getTime(event.endTimeM)}</td>
                                <td>{event.setpointTemp / 100}</td>
                                <td>{event.roomTemperature / 100}</td>
                                <td>{event.enabled ? "On" : "Off"}</td>
                            </tr>
                        )}
                </tbody>
            </table>
        </div>
    );
};
