import React, { useState } from "react";
import { DistributorListItem } from "../../../store/SimpleWifiStore/models";
import { Dropdown } from "./Dropdown";
import { SearchField } from "./SearchField";

export interface DistributorSearchFieldProps {
    distributors: DistributorListItem[],
    selectedDistributor: DistributorListItem | null;
    setSelectedDistributor: (distributor: DistributorListItem | null) => void;
    searchDistributors: (query: string) => void;
}

export const DistributorSearchField = (props: DistributorSearchFieldProps) => {
    const [show, setShow] = useState<boolean>(false);

    const toggle = () => {
        if (props.distributors.length === 0) {
            props.searchDistributors("");
        }
        setShow(!show);
    };

    const select = (distributor: DistributorListItem) => {
        props.setSelectedDistributor(distributor);
        setShow(!show);
    };

    return (
        <Dropdown
            display={props.selectedDistributor !== null
                ? `${props.selectedDistributor.privateLabelName} with distributor id ${props.selectedDistributor.distributorId}`
                : "Select a distributor"}
            show={show}
            toggleDropdown={() => toggle()}>

            <div className="d-flex flex-column w-100 align-items-center">
                <SearchField display="Search Distributors" search={(query) => props.searchDistributors(query)} />

                <div className="d-flex w-100 justify-content-center p-3">
                    <table className="table table-sm table-hover">
                        <thead>
                            <tr>
                                <th scope="col">PL</th>
                                <th scope="col">DID</th>
                                <th scope="col">Desc.</th>
                            </tr>
                        </thead>
                        <tbody>
                            {props.distributors.map(d =>
                                <tr role="button" onClick={() => select(d)}>
                                    <td className="text-truncate" style={{ maxWidth: "75px" }}>{d.privateLabelName}</td>
                                    <td className="text-truncate" style={{ maxWidth: "75px" }}>{d.distributorId}</td>
                                    <td className="text-truncate" style={{ maxWidth: "150px" }}>{d.description}</td>
                                </tr>
                            )}
                        </tbody>
                    </table>
                </div>
            </div>
        </Dropdown>
    );
};

