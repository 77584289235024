import * as React from "react";
import { connect, useDispatch } from "react-redux";
import { ApplicationState } from "../store";

type Props = ApplicationState;

const AlertsComponent = (props: Props) => {
    const dispatch = useDispatch();

    const style: React.CSSProperties = {
        position: "fixed",
        bottom: 50,
        right: 50,
        zIndex: 1,
        width: "356px"
    };

    return (
        <div style={style}>
            {props.alerts.alerts.sort((a, b) => a.epoch - b.epoch).map(alert => (
                <button
                    onClick={() => dispatch({ type: "REMOVE_ALERT", epoch: alert.epoch })}
                    key={alert.epoch}
                    className={"btn btn-block mb-3 p-3 rounded-lg shadow-lg text-left alert " + alert.type.toString()}
                >
                    <h6 className="alert-heading">{alert.header}</h6>
                    <hr className="m-2" />
                    <p className="mb-0">{alert.body}</p>
                </button>
            ))}
        </div>
    );
};

export default connect(
    (state: ApplicationState) => state
)(AlertsComponent as any);
