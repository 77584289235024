import * as React from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { ApplicationState } from "../store";
import SimpleWifiList from "./SimpleWifi/SimpleWifiList";
import UWG4List from "./UWG4/UWG4List";
import NuheatList from "./Nuheat/NuheatList";
import { Simulators } from "../store/WorkspaceStore/models";
import SenzList from "./Senz/SenzList";
import OWD5List from "./OWD5/OWD5List";
import { requiresAuthentication } from "./Common";
import UWG5NventList from "./UWG5Nvent/UWG5NventList";

type Props =
    ApplicationState;

const SimulationTable = (props: Props) => {
    return (
        <>
            {/* Nuheat */}
            {props.workspace.simulator === Simulators.NuHeat &&
                (props.legacy.NuHeat.thermostats.length > 0 ?
                    <NuheatList /> :
                    <p className="text-center">There are no Nuheat thermostats.
                        Go to <Link to={{
                        pathname: "/wizard/nuheat",
                        state: { workspace: props.workspace.focused.id }
                    }}>Create Wizard</Link> to create some 😊
                    </p>
                )
            }
            {/* SimpleWifi */}
            {props.workspace.simulator === Simulators.SimpleWifi &&
                (props.simpleWifi.thermostats.length > 0 ?
                    <SimpleWifiList /> :
                    <p className="text-center">There are no UWG5 thermostats.
                        Go to <Link to={{
                        pathname: "/wizard/simplewifi",
                        state: { workspace: props.workspace.focused.id }
                    }}>Create Wizard</Link> to create some 😊
                    </p>
                )
            }
            {/* UWG5 nVent */}
            {props.workspace.simulator === Simulators.UWG5Nvent &&
                (props.uwg5Nvent.thermostats.length > 0 ?
                    <UWG5NventList /> :
                    <p className="text-center">There are no UWG5 nVent thermostats.
                        Go to <Link to={{
                        pathname: "/wizard/uwg5nvent",
                        state: { workspace: props.workspace.focused.id }
                    }}>Create Wizard</Link> to create some 😊
                    </p>
                )
            }
            {/* UWG4 */}
            {props.workspace.simulator === Simulators.UWG4 &&
                (props.legacy.UWG4.thermostats.length > 0 ?
                    <UWG4List /> :
                    <p className="text-center">There are no UWG4 thermostats.
                        Go to <Link to={{
                        pathname: "/wizard/uwg4",
                        state: { workspace: props.workspace.focused.id }
                    }}>Create Wizard</Link> to create some 😊
                    </p>
                )
            }
            {/* Senz */}
            {props.workspace.simulator === Simulators.Senz &&
                (props.legacy.Senz.thermostats.length > 0 ?
                    <SenzList /> :
                    <p className="text-center">There are no Senz thermostats.
                        Go to <Link to={{
                        pathname: "/wizard/senz",
                        state: { workspace: props.workspace.focused.id }
                    }}>Create Wizard</Link> to create some 😊
                    </p>
                )
            }
            {/* OWD5 */}
            {props.workspace.simulator === Simulators.OWD5 &&
                (props.legacy.OWD5.thermostats.length > 0 ?
                    <OWD5List /> :
                    <p className="text-center">There are no OWD5 thermostats.
                        Go to <Link to={{
                        pathname: "/wizard/owd5",
                        state: { workspace: props.workspace.focused.id }
                    }}>Create Wizard</Link> to create some 😊
                    </p>
                )
            }
        </>
    );
};

export default requiresAuthentication(connect(
    (state: ApplicationState) => state
)(SimulationTable as any));
