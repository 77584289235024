import { simpleWifiReducer } from "./SimpleWifiStore/reducer";
import { DistributorListItem, SimpleWifiThermostat, UserAccountItem } from "./SimpleWifiStore/models";
import { LogEntry } from "./LogStore/models";
import { logReducer } from "./LogStore/reducer";
import { workspaceReducer } from "./WorkspaceStore/reducer";
import { thermostatReducer } from "./LegacyStore/reducer";
import { alertsReducer } from "./AlertStore/reducer";
import { Category, Workspace, Simulators } from "./WorkspaceStore/models";
import { AlertState } from "./AlertStore/models";
import { Thermostats } from "./LegacyStore/models";
import { reducer as oidcReducer, UserState } from "redux-oidc";
import { LoadableThermostat, UWG5NventThermostat } from "./UWG5NventStore/models";
import { uwg5NventReducer } from "./UWG5NventStore/reducer";

// The top-level state object
export interface ApplicationState {
    oidc?: UserState;
    alerts: AlertState,
    logs: {
        SimpleWifi: LogEntry[],
        UWG5Nvent: LogEntry[],
        NuHeat: LogEntry[],
        UWG4: LogEntry[],
        Senz: LogEntry[],
        OWD5: LogEntry[]
    },
    simpleWifi: {
        thermostats: SimpleWifiThermostat[],
        loadable: SimpleWifiThermostat[],
        distributors: DistributorListItem[],
        users: UserAccountItem[]
    },
    uwg5Nvent: {
        thermostats: UWG5NventThermostat[],
        loadable: LoadableThermostat[],
        distributors: DistributorListItem[],
        users: UserAccountItem[]
    },
    legacy: {
        UWG4: Thermostats,
        NuHeat: Thermostats,
        Senz: Thermostats,
        OWD5: Thermostats
    },
    workspace: {
        simulator: Simulators
        categories: Category[],
        focused: Workspace
    }
}

// Whenever an action is dispatched, Redux will update each top-level application state property using
// the reducer with the matching name. It's important that the names match exactly, and that the reducer
// acts on the corresponding ApplicationState property type.
export const reducers = {
    oidc: oidcReducer,
    alerts: alertsReducer,
    simpleWifi: simpleWifiReducer,
    uwg5Nvent: uwg5NventReducer,
    legacy: thermostatReducer,
    logs: logReducer,
    workspace: workspaceReducer
};

// This type can be used as a hint on action creators so that its 'dispatch' and 'getState' params are
// correctly typed to match your store.
export interface AppThunkAction<TAction> {
    (dispatch: (action: TAction) => void, getState: () => ApplicationState): void;
}
