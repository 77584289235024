import { ThermostatType } from "../../../store/LegacyStore/models";
import { Simulators } from "../../../store/WorkspaceStore/models";
import { withNuheat } from "./nuheat";
import { withOWD5 } from "./owd5";
import { withSenz } from "./senz";
import { withUWG4 } from "./uwg4";

type InjectedProps =
    ThermostatType

export const withRedux = <BaseProps extends InjectedProps>(
    BaseComponent: React.ComponentType<BaseProps>,
    simulator: Simulators
) => {
    switch(simulator){
        case Simulators.UWG4:
            return withUWG4(BaseComponent);
        case Simulators.NuHeat:
            return withNuheat(BaseComponent);
        case Simulators.OWD5:
            return withOWD5(BaseComponent);
        case Simulators.Senz:
            return withSenz(BaseComponent);
        default:
            return BaseComponent;
    }
};