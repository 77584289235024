import * as React from "react";

interface SpinnerProps {
    description?: string;
}

export const Spinner = (props: SpinnerProps) => {
    return (
        <div className="text-center">
            <div className="spinner-border text-primary" role="status">
                <span className="sr-only">Loading...</span>
            </div>
            {!props.description ? null : <p>{props.description}</p>}
        </div>
    );
};
