import * as React from "react";
import { connect, useDispatch } from "react-redux";
import { Errors, UWG5NventThermostat } from "../../store/UWG5NventStore/models";
import { ApplicationState } from "../../store";
import { actionCreator, UWG5NventAction } from "../../store/UWG5NventStore/actions";
import { UWG5NventSwimlane } from "./UWG5NventSwimlane";
import { AlertType } from "../../store/AlertStore/models";
import { normalizeString, popupAlert } from "../../Utility";

type Props =
    ApplicationState &
    typeof actionCreator;

const UWG5NventList = (props: Props) => {
    // Component state
    const [searchText, setSearchText] = React.useState("");
    const dispatch = useDispatch();

    /**
     * Search for thermostats matchnig the search text.
     */
    const thermostats = (() => {
        const normalizedSearchText = normalizeString(searchText);

        if (normalizedSearchText === "") {
            return props.uwg5Nvent.thermostats
                .filter(thermostat => thermostat.workspaceId === props.workspace.focused.id);
        }

        return props.uwg5Nvent.thermostats
            .filter(thermostat => thermostat.workspaceId === props.workspace.focused.id)
            .filter(thermostat => normalizeString(thermostat.deviceInfo.thermostatName).includes(normalizedSearchText));
    })();

    const isThermostatLinked = (thermostat: UWG5NventThermostat) => {
        const hasAccessCode: boolean = !!thermostat.settings.accessCode;
        const hasOtpCode: boolean = !!thermostat.settings.otpCode;

        return hasAccessCode || hasOtpCode;
    };

    /**
     * Links all unlinked thermostats in the selected workspace.
     */
    const linkAll = () => {
        popupAlert(dispatch, "Linking thermostats!", "Attempting to link all thermostats.", AlertType.SUCCESS);

        props.uwg5Nvent.thermostats
            .filter(t => t.workspaceId === props.workspace.focused.id && !isThermostatLinked(t))
            .forEach(t => props.invoke(t.thermostatId, UWG5NventAction.Link));
    };

    /**
     * Disconnects all connected thermostats in the selected workspace.
     */
    const unlinkAll = () => {
        popupAlert(dispatch, "Unlinking thermostats!", "Attempting to unlink all thermostats.", AlertType.SUCCESS);

        props.uwg5Nvent.thermostats
            .filter(t => t.workspaceId === props.workspace.focused.id)
            .forEach(t => props.invoke(t.thermostatId, UWG5NventAction.Unlink));
    };

    /**
     * Connects all disconnected thermostats in the selected workspace.
     */
    const connectAll = () => {
        popupAlert(dispatch, "Connecting thermostats!", "Attempting to connect all thermostats.", AlertType.SUCCESS);

        props.uwg5Nvent.thermostats
            .filter(t => t.workspaceId === props.workspace.focused.id && !t.isConnected)
            .forEach(t => props.invoke(t.thermostatId, UWG5NventAction.Connect));
    };

    /**
     * Disconnects all connected thermostats in the selected workspace.
     */
    const disconnectAll = () => {
        popupAlert(dispatch, "Disconnecting thermostats!", "Attempting to disconnect all thermostats.", AlertType.SUCCESS);

        props.uwg5Nvent.thermostats
            .filter(t => t.workspaceId === props.workspace.focused.id && t.isConnected)
            .forEach(t => props.invoke(t.thermostatId, UWG5NventAction.Disconnect));
    };

    /**
     * Remove all thermostats in the selected workspace.
     */
    const removeAll = () => {
        if (window.confirm("Do you wish to delete all UWG5 nVent thermostats?")) {
            props.uwg5Nvent.thermostats
                .filter(t => t.workspaceId === props.workspace.focused.id)
                .forEach(t => props.invoke(t.thermostatId, UWG5NventAction.Remove));

            popupAlert(dispatch, "Thermostats removed!", "You have successfully removed all the thermostats.", AlertType.SUCCESS);
        }
    };

    /**
     * Saves all thermostats to database in the selected workspace.
     */
    const saveAll = () => {
        props.uwg5Nvent.thermostats
            .filter(t => t.workspaceId === props.workspace.focused.id)
            .forEach(t => props.invoke(t.thermostatId, UWG5NventAction.Save));

        popupAlert(dispatch, "Thermostats saved!", "You have successfully saved all the thermostats.", AlertType.SUCCESS);
    };

    // Render
    return (
        <>
            {/*Search Bar*/}
            <div className="row">
                <label className="col-3">Search by thermostat name:</label>
                <input
                    type="text"
                    autoFocus
                    className="form-control form-control-sm col-3"
                    style={{ outline: "none" }}
                    value={searchText}
                    onChange={(e) => setSearchText(e.target.value)} />
            </div>

            {/* UWG5 nVent thermostats */}
            <div>
                <p className="my-2">
                    <span className="badge badge-primary badge-pill align-middle mt-n1">{thermostats.length}</span> total,&nbsp;
                    <span className="badge badge-success badge-pill align-middle mt-n1">{thermostats.filter(t => t.isConnected).length}</span> connected,&nbsp;
                    <span className="badge badge-danger badge-pill align-middle mt-n1">{thermostats.filter(t => !t.isConnected).length}</span> disconnected,&nbsp;
                    <span className="badge badge-secondary badge-pill align-middle mt-n1">{thermostats.filter(t => !!t.settings.otpCode).length}</span> otp,&nbsp;
                    <span className="badge badge-secondary badge-pill align-middle mt-n1">{thermostats.filter(t => !!t.settings.accessCode).length}</span> access code
                </p>
                <div className="row">
                    <div className="col-auto ml-2">
                        <div className="row">
                            <button className="btn btn-sm btn-link text-primary mt-n1" onClick={() => linkAll()}>Link all</button>
                        </div>
                        <div className="row">
                            <button className="btn btn-sm btn-link text-danger mt-n1" onClick={() => unlinkAll()}>Unlink all</button>
                        </div>
                    </div>

                    <div className="col-auto border-left">
                        <div className="row">
                            <button className="btn btn-sm btn-link text-primary mt-n1" onClick={() => connectAll()}>Connect all</button>
                        </div>
                        <div className="row">
                            <button className="btn btn-sm btn-link text-danger mt-n1" onClick={() => disconnectAll()}>Disconnect all</button>
                        </div>
                    </div>

                    <div className="col-auto border-left">
                        <div className="row">
                            <button className="btn btn-sm btn-link text-primary mt-n1" onClick={() => saveAll()}>Save all</button>
                        </div>
                        <div className="row">
                            <button className="btn btn-sm btn-link text-danger mt-n1" onClick={() => removeAll()}>Remove all</button>
                        </div>
                    </div>
                </div>
            </div>
            {thermostats.length > 0 ?
                <div>
                    {thermostats.map(thermostat =>
                        <UWG5NventSwimlane
                            key={thermostat.thermostatId}
                            thermostat={thermostat}
                            invokeAction={(action: UWG5NventAction) => props.invoke(thermostat.thermostatId, action)}
                            setSetpoint={(setpoint) => props.setSetpoint(thermostat.thermostatId, setpoint)}
                            setAutoMode={() => props.setAutoMode(thermostat.thermostatId)}
                            setManualMode={(setpoint) => props.setManualMode(thermostat.thermostatId, setpoint)}
                            setHoldMode={(setpoint, endTime, isPermanent) => props.setHoldMode(thermostat.thermostatId, setpoint, endTime, isPermanent)}
                            cancelHoldMode={() => props.cancelHoldMode(thermostat.thermostatId)}
                            updateSettings={(settings) => props.updateSettings(thermostat.thermostatId, settings)}
                            updateDeviceInfo={deviceInfo => props.updateDeviceInfo(thermostat.thermostatId, deviceInfo)}
                            updateDisplaySettings={(settings) => props.updateDisplaySettings(thermostat.thermostatId, settings)}
                            updateRegulationSettings={(settings) => props.updateRegulationSettings(thermostat.thermostatId, settings)}
                            updateHeatingSettings={(settings) => props.updateHeatingSettings(thermostat.thermostatId, settings)}
                            updateFloorSensorSettings={(settings) => props.updateFloorSensorSettings(thermostat.thermostatId, settings)}
                            updateAwayModeSettings={(settings) => props.updateAwayModeSettings(thermostat.thermostatId, settings)}
                            updateDateTimeSettings={(settings) => props.updateDateTimeSettings(thermostat.thermostatId, settings)}
                            updateWifiSettings={(settings) => props.updateWifiSettings(thermostat.thermostatId, settings)}
                            updateWarrantyInfo={(info) => props.updateWarrantyInfo(thermostat.thermostatId, info)}
                            setErrors={(errors: Errors) => props.setErrors(thermostat.thermostatId, errors)}
                            setStandbyState={(isInStandby: boolean) => props.setStandbyState(thermostat.thermostatId, isInStandby)}
                            setCurrentScheduleEvent={(day, eventId) => props.setCurrentScheduleEvent(thermostat.thermostatId, day, eventId)}
                            setCurrentTemp={(newCurrentTemp) => props.setCurrentTemp(thermostat.thermostatId, newCurrentTemp)}
                        />
                    )}
                </div>
                :
                <p className="text-center">There are no UWG5 nVent thermostats in the selected workspace! Try a different workspace.</p>
            }
        </>
    );
};

export default connect(
    (state: ApplicationState) => state,
    actionCreator
)(UWG5NventList as any);
