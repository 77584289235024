import { AppThunkAction } from "..";
import { KnownAction } from "./actionTypes";
import { Category, Simulators, Workspace } from "./models";
import { workspaceHub } from "./signalRHub";

export const actionCreator = {
    selectWorkspace: (id: number): AppThunkAction<KnownAction> => (_dispatch, _getState) => {
        workspaceHub.send("SelectWorkspace", id).catch(alert);
    },
    addWorkspace: (name: string, category: number, author: string): AppThunkAction<KnownAction> => (_dispatch, _getState) => {
        workspaceHub.send("AddWorkspace", name, category, author).catch(alert);
    },
    removeWorkspace: (workspace: Workspace): AppThunkAction<KnownAction> => (_dispatch, _getState) => {
        workspaceHub.send("RemoveWorkspace", workspace).catch(alert);
    },
    updateWorkspace: (workspace: Workspace): AppThunkAction<KnownAction> => (_dispatch, _getState) => {
        workspaceHub.send("UpdateWorkspace", workspace).catch(alert);
    },
    cloneWorkspace: (workspace: Workspace): AppThunkAction<KnownAction> => (_dispatch, _getState) => {
        workspaceHub.send("CloneWorkspace", workspace).catch(alert);
    },
    addCategory: (name: string, simulator: Simulators): AppThunkAction<KnownAction> => (_dispatch, _getState) => {
        workspaceHub.send("AddCategory", name, simulator).catch(alert);
    },
    removeCategory: (category: Category): AppThunkAction<KnownAction> => (_dispatch, _getState) => {
        workspaceHub.send("RemoveCategory", category).catch(alert);
    },
    updateCategory: (category: Category): AppThunkAction<KnownAction> => (_dispatch, _getState) => {
        workspaceHub.send("UpdateCategory", category).catch(alert);
    }
};
