import { Dispatch } from "redux";
import { Alert, AlertType } from "./store/AlertStore/models";
import { LogEntry } from "./store/LogStore/models";

export const getColor = (thermostatSerialNumber: string | number): string => {
    let hue = parseInt(String(thermostatSerialNumber));

    if (isNaN(hue)) {
        hue = 0;
    }

    return `hsl(${hue % 360}, 100%, 50%)`;
};

export function popupAlert(dispatch: Dispatch<any>, header: string, body: string, type: AlertType): void {
    const alert = {
        epoch: new Date().getTime(),
        header: header,
        body: body,
        type: type
    } as Alert;

    dispatch({ type: "RECEIVE_ALERT", alert: alert });
    setTimeout(() => dispatch({ type: "REMOVE_ALERT", epoch: alert.epoch }), 6900);
}

export function formatFullDates(datetime: Date | string, defaultLocale: string) {
    const today = new Date();
    const date = new Date(datetime);
    const isToday = date.getDate() === today.getDate() &&
        date.getMonth() === today.getMonth() &&
        date.getFullYear() === today.getFullYear();
    const customFormat = isToday ?
        new Intl.DateTimeFormat(defaultLocale, {
            hour: "2-digit",
            minute: "2-digit"
        }) :
        new Intl.DateTimeFormat(defaultLocale, {
            year: "numeric",
            month: "long",
            day: "2-digit",
            hour: "2-digit",
            minute: "2-digit"
        });

    return customFormat.format(date);
}

export function orderLogEntries(a: LogEntry, b: LogEntry): number {
    // order by timestamp descending and then by ID descending so events are ordered if timestamps match
    return a.timestamp > b.timestamp ? -1 : a.entryId > b.entryId ? -1 : 1;
}

export const normalizeString = (value: any): string => (String(value || "")).trim().toUpperCase();

export const base64ToByteArray = (base64: string): Uint8Array => {
    const binaryString = window.atob(base64);
    const bytes = new Uint8Array(binaryString.length);
    for (let i = 0; i < binaryString.length; ++i) {
        bytes[i] = binaryString.charCodeAt(i);
    }

    return bytes;
};

export const toBase64 = (buffer: Uint8Array): string => {
    let binary = "";
    const bytes = new Uint8Array(buffer);
    const len = bytes.byteLength;
    for (let i = 0; i < len; i++) {
        binary += String.fromCharCode(bytes[i]);
    }

    return window.btoa(binary);
};

export const isUrlAbsolute = (url: string) => !!url && (url.indexOf("://") > 0 || url.indexOf("//") === 0);
export const trimSlashes = (url: string) => (!!url ? url.replace(/^\/|\/$/g, "") : url);
export const makeUrlAbsolute = (baseUrl: string, url: string) => (isUrlAbsolute(url) ? url : baseUrl + (trimSlashes(url) || ""));
