import { AppThunkAction } from "../..";
import { KnownAction } from "../actionTypes";
import { HubConnection } from "@microsoft/signalr";

export interface NuheatActionCreator {
    scheduleHoldUntil: (id: string, hours: number, setpoint: number) => AppThunkAction<KnownAction>;
    scheduleHoldPermanent: (id: string, setpoint: number) => AppThunkAction<KnownAction>;
}

export function nuheatActionCreator(connection: HubConnection) {
    return {
        scheduleHoldUntil: (id: string, hours: number, setpoint: number): AppThunkAction<KnownAction> => (_dispatch, _getState) => {
            connection.send("ScheduleHoldUntil", id, hours, setpoint).catch(alert);
        },
        scheduleHoldPermanent: (id: string, setpoint: number): AppThunkAction<KnownAction> => (_dispatch, _getState) => {
            connection.send("ScheduleHoldPermanent", id, setpoint).catch(alert);
        }
    };
}