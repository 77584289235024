import React, { FC } from "react";
import { NumberInput } from "./NumberInput";
import { DateTimePicker } from "@mui/lab";
import { TextField } from "@mui/material";

export interface TableRowInputProps {
    name: string
    originalValue?: string | number | Date | null
    currentValue: string | number | Date | null
    onChange?: (newValue: string | number | Date | null) => void
    onSave?: () => void
    inputType: "text" | "number" | "date"
    readOnly?: boolean
    disabled?: boolean

    /**
     * Sets the maximum value. This is only used if the input type is "number".
     */
    maxValue?: number

    /**
     * Sets the minimum value. This is only used if the input type is "number".
     */
    minValue?: number
}

export const TableRowInput: FC<TableRowInputProps> = (props) => {
    let saveButton: JSX.Element | undefined;

    if (!props.readOnly && props.onSave) {
        saveButton = (
            <button
                disabled={props.disabled || (props.originalValue !== undefined && props.currentValue === props.originalValue)}
                className="btn btn-sm btn-outline-primary btn-block"
                onClick={() => props.onSave && props.onSave()}>
                Save
            </button>
        );
    }

    return (
        <tr>
            <th className="align-middle">{props.name}</th>
            <td>
                {props.inputType === "number" ? (
                    <NumberInput
                        value={Number(props.currentValue)}
                        minValue={props.minValue}
                        maxValue={props.maxValue}
                        className="form-control-sm w-100"
                        onChange={(newValue) => {
                            if (props.onChange) {
                                props.onChange(newValue);
                            }
                        }}
                    />
                ) : props.inputType === "date" ? (
                    <DateTimePicker
                        ampm={false}
                        onError={console.error}
                        clearable
                        renderInput={(props) => <TextField {...props} />}
                        inputFormat="yyyy/MM/dd HH:mm"
                        mask={"____/__/__ __:__"}
                        value={props.currentValue}
                        label={props.name}
                        onChange={(newValue) => {
                            if (props.onChange) {
                                props.onChange(newValue);
                            }
                        }} />
                )
                    :
                    (
                        <input
                            readOnly={props.readOnly}
                            disabled={props.disabled}
                            value={props.currentValue as string || ""}
                            type="text"
                            className="form-control form-control-sm w-100"
                            onChange={event => {
                                if (props.onChange) {
                                    props.onChange(event.target.value);
                                }
                            }} />
                    )}
            </td>
            <td>{saveButton}</td>
        </tr>
    );
};