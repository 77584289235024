import React from "react";
import { connect } from "react-redux";
import { ApplicationState } from "../../../store";
import { GenericActionCreator, genericActionCreator, mergeActionCreators } from "../../../store/LegacyStore/actions";
import { Diff } from "utility-types";
import { nuheatHub } from "../../../store/LegacyStore/hubs";
import { NuheatActionCreator, nuheatActionCreator } from "../../../store/LegacyStore/actions/nuheat";
import { LegacyThermostatType, ThermostatType } from "../../../store/LegacyStore/models";
import { requiresAuthentication } from "../../Common";

// Props to inject into the base component
type InjectedProps =
    ThermostatType

// The wrapper function
export const withNuheat = <BaseProps extends InjectedProps>(
    BaseComponent: React.ComponentType<BaseProps>
) => {
    // Redux state functions
    const mapStateToProps = (state: ApplicationState) => ({
        thermostatType: LegacyThermostatType.NuHeat,
        ...state
    });
    const dispatchProps = mergeActionCreators(
        genericActionCreator(nuheatHub),
        nuheatActionCreator(nuheatHub)
    );

    // HOC component props
    type HocProps =
    ReturnType<typeof mapStateToProps> &
    GenericActionCreator &
    NuheatActionCreator &
    ThermostatType

    class Hoc extends React.Component<HocProps> {
        // reference to original wrapped component
        static readonly WrappedComponent = BaseComponent;

        // Return the base component with the Redux state props
        render() {
            const { thermostatType, ...restProps } = this.props;

            return (
                <BaseComponent
                    {...(restProps as unknown as BaseProps)}
                    thermostatType = {thermostatType}
                />
            );
        }
    }

    // The connection to Redux
    const ConnectedHoc = connect<
    ReturnType<typeof mapStateToProps>,
    typeof dispatchProps,
    Diff<BaseProps, InjectedProps>,
    ApplicationState
    >(
        mapStateToProps,
        dispatchProps
    )(Hoc);

    return requiresAuthentication(ConnectedHoc);
};
