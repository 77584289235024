import { AppThunkAction } from "../..";
import { KnownAction } from "../actionTypes";
import { HubConnection } from "@microsoft/signalr";

export interface UWG4ActionCreator {
    setComfortMode: (id: string, days: number, hours: number, setpoint: number) => AppThunkAction<KnownAction>;
    setVacationMode: (id: string, days: number, hours: number, setpoint: number) => AppThunkAction<KnownAction>;
}

export function uwg4ActionCreator(connection: HubConnection) {
    return {
        setComfortMode: (id: string, days: number, hours: number, setpoint: number): AppThunkAction<KnownAction> => (_dispatch, _getState) => {
            connection.send("SetComfortMode", id, days, hours, setpoint).catch(alert);
        },
        setVacationMode: (id: string, days: number, hours: number, setpoint: number): AppThunkAction<KnownAction> => (_dispatch, _getState) => {
            connection.send("SetVacationMode", id, days, hours, setpoint).catch(alert);
        }
    };
}