import * as React from "react";

interface PageProps {
    title: string;
    children?: React.ReactNode;
    id?: string
}

export const Page = (props: PageProps) => {
    return (
        <div id={props.id} className="p-3 bg-white rounded-lg shadow">
            <h4>{props.title}</h4>
            <hr />
            {props.children}
        </div>
    );
};
