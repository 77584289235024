import * as React from "react";
import { connect, ConnectedComponent } from "react-redux";
import { ApplicationState } from "../../store";
import { extractAuthProps, IAuthProps } from "../../store/authTypes";
import { Page } from ".";
import { RouteComponentProps } from "react-router";
import { signIn } from "../../oidcUtilities";

export const mapAuthContextStateToProps = (state: ApplicationState): IAuthProps => ({
    ...extractAuthProps(state.oidc)
});

export const withAuthContext = <TComponentProps extends IAuthProps & RouteComponentProps>(AuthContextComponent: React.ComponentType<TComponentProps>) => {
    return connect(mapAuthContextStateToProps)(AuthContextComponent as any);
};

export const requiresAuthentication = (SecuredComponent: ConnectedComponent<any, any>) => {
    const createWrapper = () => {
        return (
            class WrappedSecuredComponent extends React.Component<ReturnType<typeof mapAuthContextStateToProps>, ApplicationState> {
                render() {
                    const childProps = this.props as IAuthProps;

                    // Ask user to sign in, if they aren't authenticated
                    if (!childProps.isAuthenticated) {
                        return (
                            <Page title="Unauthenticated">
                                <p className="mb-0">You need to be signed in to view this page. <button
                                    className="btn btn-link p-0 mt-n1"
                                    onClick={signIn}
                                >Click here to sign in</button>.</p>
                            </Page>
                        );
                    }

                    return <SecuredComponent {...childProps} />;
                }
            }
        );
    };

    return connect(mapAuthContextStateToProps)(createWrapper());
};
