import * as React from "react";
import { connect, useDispatch } from "react-redux";
import { ApplicationState } from "../../store";
import { genericActionCreator, Action, StateToUpdate, mergeActionCreators, GenericActionCreator } from "../../store/LegacyStore/actions";
import { SenzSwimlane } from "./SenzSwimlane";
import { AlertType } from "../../store/AlertStore/models";
import { popupAlert } from "../../Utility";
import { senzHub } from "../../store/LegacyStore/hubs";
import { SenzActionCreator, senzActionCreator } from "../../store/LegacyStore/actions/senz";
import { SenzThermostat } from "../../store/LegacyStore/models/senz";

type Props =
    ApplicationState &
    GenericActionCreator &
    SenzActionCreator;

const SenzList = (props: Props) => {
    // Component state
    const [searchText, setSearchText] = React.useState("");
    const dispatch = useDispatch();

    /**
     * Normalize string.
     */
    const normalizeString = (stringToNormalize: string): string => (stringToNormalize || "").trim().toUpperCase();

    /**
     * Search for thermostats matchnig the search text.
     */
    const searchThermostats = () => {
        const normalizedSearchText = normalizeString(searchText);

        if (normalizedSearchText === "") {
            return props.legacy.Senz.thermostats
                .filter(thermostat => thermostat.workspaceID === props.workspace.focused.id)
                .map(thermostat => thermostat as unknown as SenzThermostat);
        }

        return props.legacy.Senz.thermostats
            .filter(thermostat => thermostat.workspaceID === props.workspace.focused.id)
            .filter(thermostat => normalizeString(thermostat.internalStorage.name).includes(normalizedSearchText))
            .map(thermostat => thermostat as unknown as SenzThermostat);
    };

    /**
     * Remove all thermostats in the selected workspace.
     */
    const removeAll = () => {
        if (window.confirm("Do you wish to remove all Senz thermostats?")) {
            props.legacy.Senz.thermostats
                .filter(t => t.workspaceID === props.workspace.focused.id)
                .forEach(t => props.invoke(t.thermostatID, Action.Remove));

            popupAlert(dispatch, "Thermostats removed!", "You have successfully removed all the thermostats.", AlertType.SUCCESS);
        }
    };

    /**
     * Saves all thermostats to database in the selected workspace.
     */
    const saveAll = () => {
        props.legacy.Senz.thermostats
            .filter(t => t.workspaceID === props.workspace.focused.id)
            .forEach(t => props.invoke(t.thermostatID, Action.Save));

        popupAlert(dispatch, "Thermostats saved!", "You have successfully saved all the thermostats.", AlertType.SUCCESS);
    };

    // Render
    return (
        <>
            {/*Search Bar*/}
            <p className="row">
                <label className="col-2">Search by Name:</label>
                <input
                    type="text"
                    className="form-control form-control-sm col-3"
                    style={{ outline: "none" }}
                    value={searchText}
                    onChange={(e) => setSearchText(e.target.value)} />
            </p>

            {/* Senz thermostats */}
            <p>
                <span className="badge badge-secondary badge-pill align-middle mt-n1">{searchThermostats().length}</span> Senz thermostats
                <button className="btn btn-sm btn-link text-primary mt-n1" onClick={() => saveAll()}>Save all</button>
                <button className="btn btn-sm btn-link text-danger mt-n1" onClick={() => removeAll()}>Remove all</button>
            </p>
            {searchThermostats().length > 0 ?
                <div>
                    {searchThermostats().map(thermostat =>
                        <SenzSwimlane
                            key={thermostat.thermostatID}
                            thermostat={thermostat}
                            invokeAction={(action: Action) => props.invoke(thermostat.thermostatID, action)}
                            updateState={(value: any, toUpdate: StateToUpdate) => props.updateState(thermostat.thermostatID, value, toUpdate)}
                            SetComfortMode={(days: number, hours: number, setpoint: number) => props.setComfortMode(thermostat.thermostatID, days, hours, setpoint)}
                            SetVacationMode={(days: number, hours: number, setpoint: number) => props.setVacationMode(thermostat.thermostatID, days, hours, setpoint)}
                            SetFirstWarming={(days: number) => props.setFirstWarming(thermostat.thermostatID, days)}
                            SetFrostProtectionMode={(setpoint: number) => props.setFrostProtectionMode(thermostat.thermostatID, setpoint)}
                            SetManualMode={(setpoint: number) => props.setManualMode(thermostat.thermostatID, setpoint)}
                            SetRegulationOffMode={() => props.setRegulationOffMode(thermostat.thermostatID)}
                        />
                    )}
                </div>
                :
                <p className="text-center">There are no Senz thermostats in the selected workspace! Try a different workspace. {searchThermostats().length}</p>
            }
        </>
    );
};

export default connect(
    (state: ApplicationState) => state,
    mergeActionCreators(genericActionCreator(senzHub), senzActionCreator(senzHub))
)(SenzList as any);
