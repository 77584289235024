import { Store } from "redux";
import { DistributorListItem, SimpleWifiThermostat, UserAccountItem } from "./models";
import {
    ReceiveAllSimpleWifiThermostats,
    ReceiveNewSimpleWifiThermostat,
    RemoveSimpleWifiThermostat,
    UpdateSimpleWifiThermostatState,
    ReceiveLoadableThermostats,
    AddLoadableThermostat,
    UpdateLoadableThermostat,
    RemoveLoadableThermostat,
    ReceiveDistributorSearchResult,
    ReceiveUserSearchResult
} from "./actionTypes";
import { buildHub } from "../../ApiGateway";
import { HubConnectionState } from "@microsoft/signalr";


// Create SimpleWifi hub
export const simpleWifiHub = buildHub("SimpleWifiHub");

const mapStringToDate = (t: SimpleWifiThermostat) => {
    if (!!t.mode.holdUntilEndTime && typeof (t.mode.holdUntilEndTime) === "string") {
        t.mode.holdUntilEndTime = new Date(t.mode.holdUntilEndTime);
    }
};

/**
 * Connect to SimpleWifi SignalR hub and register handlers.
 */
export function connectToSimpleWifiHub(store: Store) {
    if (simpleWifiHub.state !== HubConnectionState.Disconnected) {
        return;
    }

    simpleWifiHub.start()
        .catch(err => console.error("Failed to start SimpleWifiHub", err));

    simpleWifiHub.on("ReceiveAllThermostats", (thermostats: SimpleWifiThermostat[]) => {
        thermostats.forEach(mapStringToDate);
        store.dispatch({ type: "RECEIVE_ALL_SIMPLE_WIFI_THERMOSTATS", thermostats } as ReceiveAllSimpleWifiThermostats);
    });

    simpleWifiHub.on("ReceiveNewThermostat", (thermostat: SimpleWifiThermostat) => {
        mapStringToDate(thermostat);
        store.dispatch({ type: "RECEIVE_NEW_SIMPLE_WIFI_THERMOSTAT", thermostat } as ReceiveNewSimpleWifiThermostat);
    });

    simpleWifiHub.on("UpdateThermostatState", (thermostat: SimpleWifiThermostat) => {
        mapStringToDate(thermostat);
        store.dispatch({ type: "UPDATE_SIMPLE_WIFI_THERMOSTAT_STATE", thermostat } as UpdateSimpleWifiThermostatState);
    });

    simpleWifiHub.on("RemoveThermostat", (id: string) => {
        store.dispatch({ type: "REMOVE_SIMPLE_WIFI_THERMOSTAT", id } as RemoveSimpleWifiThermostat);
    });

    simpleWifiHub.on("ReceiveLoadableThermostats", (thermostats: SimpleWifiThermostat[]) => {
        thermostats.forEach(mapStringToDate);
        store.dispatch({ type: "RECEIVE_LOADABLE_SIMPLE_WIFI_THERMOSTATS", thermostats } as ReceiveLoadableThermostats);
    });

    simpleWifiHub.on("RemoveLoadableThermostat", (id: string) => {
        store.dispatch({ type: "REMOVE_LOADABLE_SIMPLE_WIFI_THERMOSTAT", id } as RemoveLoadableThermostat);
    });

    simpleWifiHub.on("AddLoadableThermostat", (thermostat: SimpleWifiThermostat) => {
        mapStringToDate(thermostat);
        store.dispatch({ type: "ADD_LOADABLE_SIMPLE_WIFI_THERMOSTAT", thermostat } as AddLoadableThermostat);
    });

    simpleWifiHub.on("UpdateLoadableThermostat", (thermostat: SimpleWifiThermostat) => {
        mapStringToDate(thermostat);
        store.dispatch({ type: "UPDATE_LOADABLE_SIMPLE_WIFI_THERMOSTAT", thermostat } as UpdateLoadableThermostat);
    });

    simpleWifiHub.on("ReceiveDistributorSearchResult", (distributors: DistributorListItem[]) => {
        store.dispatch({ type: "RECEIVE_DISTRIBUTOR_SEARCH_RESULT", distributors } as ReceiveDistributorSearchResult);
    });

    simpleWifiHub.on("ReceiveUserSearchResult", (users: UserAccountItem[]) => {
        store.dispatch({ type: "RECEIVE_USER_SEARCH_RESULT", users } as ReceiveUserSearchResult);
    });
}
