import { Action, Reducer } from "redux";
import { SimpleWifiThermostat, SimpleWifiThermostats } from "./models";
import { KnownAction } from "./actionTypes";
import { base64ToByteArray } from "../../Utility";

const unloadedThermostats: SimpleWifiThermostats = {
    thermostats: [],
    loadable: [],
    distributors: [],
    users: []
};

const mapThermostat = (thermostat: SimpleWifiThermostat): SimpleWifiThermostat => {
    return {
        ...thermostat,
        deviceInfo: {
            ...thermostat.deviceInfo,
            mcuUuid: base64ToByteArray(thermostat.deviceInfo.mcuUuid as unknown as string),
            protocolVersion: base64ToByteArray(thermostat.deviceInfo.protocolVersion as unknown as string)
        }
    };
};

const mapThermostats = (thermostats: SimpleWifiThermostat[]): SimpleWifiThermostat[] => {
    return thermostats.map(mapThermostat);
};

export const simpleWifiReducer: Reducer<SimpleWifiThermostats> = (state: SimpleWifiThermostats | undefined, incomingAction: Action): SimpleWifiThermostats => {
    if (state === undefined) {
        return unloadedThermostats;
    }

    const action = incomingAction as KnownAction;
    switch (action.type) {
        case "RECEIVE_ALL_SIMPLE_WIFI_THERMOSTATS":
            return { ...state, thermostats: mapThermostats(action.thermostats) };
        case "RECEIVE_NEW_SIMPLE_WIFI_THERMOSTAT":
            return { ...state, thermostats: [...state.thermostats, mapThermostat(action.thermostat)] };
        case "UPDATE_SIMPLE_WIFI_THERMOSTAT_STATE":
            return { ...state, thermostats: state.thermostats.map(thermostat => (thermostat.thermostatID === action.thermostat.thermostatID ? mapThermostat(action.thermostat) : thermostat)) };
        case "REMOVE_SIMPLE_WIFI_THERMOSTAT":
            return { ...state, thermostats: state.thermostats.filter(thermostat => thermostat.thermostatID !== action.id) };
        case "RECEIVE_LOADABLE_SIMPLE_WIFI_THERMOSTATS":
            return { ...state, loadable: mapThermostats(action.thermostats) };
        case "ADD_LOADABLE_SIMPLE_WIFI_THERMOSTAT":
            return { ...state, loadable: [...state.loadable, mapThermostat(action.thermostat)] };
        case "REMOVE_LOADABLE_SIMPLE_WIFI_THERMOSTAT":
            return { ...state, loadable: state.loadable.filter(thermostat => thermostat.thermostatID !== action.id) };
        case "UPDATE_LOADABLE_SIMPLE_WIFI_THERMOSTAT":
            return { ...state, loadable: state.loadable.map(thermostat => (thermostat.thermostatID === action.thermostat.thermostatID ? mapThermostat(action.thermostat) : thermostat)) };
        case "RECEIVE_DISTRIBUTOR_SEARCH_RESULT":
            return { ...state, distributors: action.distributors };
        case "RECEIVE_USER_SEARCH_RESULT":
            return { ...state, users: action.users };
        default:
            return state;
    }
};

