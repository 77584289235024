import * as React from "react";
import { Collapse } from "reactstrap";
import { Schedule, ScheduleDay, DaysOfWeek } from "../../store/SimpleWifiStore/models";
import { SimpleWifiScheduleDay } from "./SimpleWifiScheduleDay";
import { useState } from "react";

type Props = {
    schedule: Schedule,
    setCurrentScheduleEvent(day: number, eventId: number): void
}

type ScheduleRowProps = {
    dayOfWeek: string | number, day: ScheduleDay, refresh: boolean,
    setCurrentScheduleEvent(day: number, eventId: number): void
};

const ScheduleRow = (scheduleRowProps: ScheduleRowProps) => {
    const [isOpen, setIsOpen] = React.useState(false);

    return (
        <tr>
            <td>
                <button
                    className="btn btn-sm btn-link border border-primary rounded ml-0 pl-0 col-2"
                    onClick={() => setIsOpen(!isOpen)}>{scheduleRowProps.dayOfWeek}</button>
                <Collapse isOpen={isOpen}>
                    <SimpleWifiScheduleDay refresh={scheduleRowProps.refresh} day={scheduleRowProps.day} setCurrentScheduleEvent={scheduleRowProps.setCurrentScheduleEvent} dayOfWeek={scheduleRowProps.dayOfWeek}/>
                </Collapse>
            </td>
        </tr>
    );
};

export const SimpleWifiSchedule = (props: Props) => {
    const [schedule, setSchedule] = useState(props.schedule);
    const [refresh, setRefresh] = useState(false);
    const onEventSelected = (day: number, event: number) => {
        schedule.days.forEach(d => d.events.forEach(e =>e.isActive = false));
        const currentEvent = props.schedule.days[day].events[event];
        props.schedule.days[day].events[event].isActive = true;
        setSchedule(props.schedule);
        setRefresh(!refresh);
        if(currentEvent.enabled) {
            props.setCurrentScheduleEvent(day, event);
        }else {
            props.setCurrentScheduleEvent(6, 6);
        }
    };

    return (
        <div className="ml-0 mr-0">
            <table className="table table-sm mb-1">
                <tbody>
                    <tr>
                        <td>
                            <label><b>Base SetPoint:&nbsp;&nbsp;</b>{props.schedule.baseSetPoint}</label>
                        </td>
                    </tr>
                    <ScheduleRow refresh={refresh} dayOfWeek="Monday" day={schedule.days[DaysOfWeek.Monday]} setCurrentScheduleEvent={onEventSelected} />
                    <ScheduleRow refresh={refresh} dayOfWeek="Tuesday" day={schedule.days[DaysOfWeek.Tuesday]} setCurrentScheduleEvent={onEventSelected}/>
                    <ScheduleRow refresh={refresh} dayOfWeek="Wednesday" day={schedule.days[DaysOfWeek.Wednesday]} setCurrentScheduleEvent={onEventSelected}/>
                    <ScheduleRow refresh={refresh} dayOfWeek="Thursday" day={schedule.days[DaysOfWeek.Thursday]} setCurrentScheduleEvent={onEventSelected}/>
                    <ScheduleRow refresh={refresh} dayOfWeek="Friday" day={schedule.days[DaysOfWeek.Friday]} setCurrentScheduleEvent={onEventSelected}/>
                    <ScheduleRow refresh={refresh} dayOfWeek="Saturday" day={schedule.days[DaysOfWeek.Saturday]} setCurrentScheduleEvent={onEventSelected}/>
                    <ScheduleRow refresh={refresh} dayOfWeek="Sunday" day={schedule.days[DaysOfWeek.Sunday]} setCurrentScheduleEvent={onEventSelected}/>
                </tbody>
            </table>
        </div>
    );
};
