import { Action, Reducer } from "redux";
import { AlertState } from "./models";
import { KnownAction } from "./actionTypes";

export const alertsReducer: Reducer<AlertState> = (state: AlertState | undefined, incomingAction: Action): AlertState => {
    if (state === undefined) {
        return { alerts: [] };
    }

    const action = incomingAction as KnownAction;

    switch (action.type) {
        case "RECEIVE_ALERT":
            return {
                alerts: [
                    ...state.alerts,
                    { ...action.alert }
                ]
            };
        case "REMOVE_ALERT":
            return {
                alerts: [
                    ...state.alerts.filter(alert => alert.epoch !== action.epoch)
                ]
            };
        default:
            return state;
    }
};
