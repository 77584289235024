import * as React from "react";
import { ScheduleDay, ScheduleEvent } from "../../store/SimpleWifiStore/models";

type Props = {
    day: ScheduleDay,
    dayOfWeek: string | number,
    refresh: boolean,
    setCurrentScheduleEvent(day: number, eventId: number): void
}

const getTime = (minutes: number) => {
    const m = minutes % 60;
    const h = (minutes-m)/60;

    return (h < 10 ? "0" : "") + h.toString() + ":" + (m < 10 ? "0" : "") + m.toString();
};

export const SimpleWifiScheduleDay = (props: Props) => {
    const onEventSelected = (event: ScheduleEvent) => {
        props.setCurrentScheduleEvent(props.day.dayOfWeek, event.eventID);
    };

    return (
        <div className="mr-2">
            <table className="table table-hover table-sm mb-0">
                <thead>
                    <tr>
                        <td><b>Start Time</b></td>
                        <td><b>End Time</b></td>
                        <td><b>Floor Temp</b></td>
                        <td><b>Room Temp</b></td>
                        <td><b>Enabled</b></td>
                    </tr>
                </thead>
                <tbody>
                    {props.day.events
                        .map(event =>
                            <tr onClick={
                                () => {
                                    onEventSelected(event);
                                }} key={event.eventID} className={event.isActive ? "bg-success" : ""
                            }>
                                <td>{getTime(event.startTimeMinutes)}</td>
                                <td>{getTime(event.endTimeMinutes)}</td>
                                <td>{event.floorTemperature / 100}</td>
                                <td>{event.roomTemperature / 100}</td>
                                <td>{event.enabled ? "On" : "Off"}</td>
                            </tr>
                        )}
                </tbody>
            </table>
        </div>
    );
};
