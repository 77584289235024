import * as React from "react";
import NavMenu from "./NavMenu";
import LogBox from "./LogBox";
import AlertComponent from "./Alert";
import { IAuthProps } from "../store/authTypes";
import { withAuthContext } from "./Common";

const Layout = (props: { children?: React.ReactNode } & IAuthProps) => (
    <>
        { props.isAuthenticated && <><NavMenu /><AlertComponent /></> }

        <div className="row no-gutters">
            <div className="p-2 col-12 col-xl-6">
                {props.children}
            </div>
            {
                props.isAuthenticated &&
                <div className="p-2 col-12 col-xl-6">
                    <LogBox />
                </div>
            }
        </div>
    </>
);

export default withAuthContext(Layout);
