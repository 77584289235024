import React, { FC, useState, useEffect } from "react";
import { TableRowInput, TableRowDropdown } from "../Common";
import { DateTimeSettings as Model, Dst } from "../../store/UWG5NventStore/models";

export interface DateTimeSettingsProps {
    dateTimeSettings: Model,
    saveDateTimeSettings: (newDateTimeSettings: Model) => void
}

export const DateTimeSettings: FC<DateTimeSettingsProps> = (props) => {
    const [dateTimeSettings, setDateTimeSettings] = useState(props.dateTimeSettings);
    const save = () => props.saveDateTimeSettings(dateTimeSettings);

    useEffect(() => {
        setDateTimeSettings(props.dateTimeSettings);
    }, [props.dateTimeSettings]);

    return (
        <table className="table table-sm mb-1">
            <tbody>
                <TableRowDropdown
                    name="Daylight Savings"
                    currentValue={dateTimeSettings.dst}
                    originalValue={props.dateTimeSettings.dst}
                    type={Dst}
                    onChange={newValue => setDateTimeSettings({ ...dateTimeSettings, dst: newValue })}
                    onSave={save} />
                <TableRowInput
                    name="Timezone offset in minutes"
                    currentValue={dateTimeSettings.timezoneMinutes}
                    originalValue={props.dateTimeSettings.timezoneMinutes}
                    inputType="number"
                    onSave={save}
                    onChange={newValue => setDateTimeSettings({ ...dateTimeSettings, timezoneMinutes: Number(newValue) })} />
            </tbody>
        </table>
    );
};
