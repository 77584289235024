import React, { FC, useState, useEffect } from "react";
import { TableRowInput } from "../Common";
import { WarrantyInfo as Model } from "../../store/UWG5NventStore/models";

export interface WarrantyInfoProps {
    warrantyInfo: Model,
    saveWarrantyInfo: (newWarrantyInfo: Model) => void
}

export const WarrantyInfo: FC<WarrantyInfoProps> = (props) => {
    const [warrantyInfo, setWarrantyInfo] = useState(props.warrantyInfo);

    const save = () => props.saveWarrantyInfo(warrantyInfo);
    useEffect(() => {
        setWarrantyInfo(props.warrantyInfo);
    }, [props.warrantyInfo]);

    return (
        <table className="table table-sm mb-1">
            <tbody>
                <TableRowInput
                    name="Total uptime(sec)"
                    currentValue={warrantyInfo.totalUptimeS}
                    originalValue={props.warrantyInfo.totalUptimeS}
                    inputType="number"
                    onSave={save}
                    onChange={newValue => setWarrantyInfo({ ...warrantyInfo, totalUptimeS: Number(newValue) })} />

                <TableRowInput
                    name="Relay active(sec)"
                    currentValue={warrantyInfo.relayActiveS}
                    originalValue={props.warrantyInfo.relayActiveS}
                    inputType="number"
                    onSave={save}
                    onChange={newValue => setWarrantyInfo({ ...warrantyInfo, relayActiveS: Number(newValue) })} />

                <TableRowInput
                    name="Relay clicks"
                    currentValue={warrantyInfo.relayClicks}
                    originalValue={props.warrantyInfo.relayClicks}
                    inputType="number"
                    onSave={save}
                    onChange={newValue => setWarrantyInfo({ ...warrantyInfo, relayClicks: Number(newValue) })} />

                <TableRowInput
                    name="Overheat time(sec)"
                    currentValue={warrantyInfo.overheatTimeS}
                    originalValue={props.warrantyInfo.overheatTimeS}
                    inputType="number"
                    onSave={save}
                    onChange={newValue => setWarrantyInfo({ ...warrantyInfo, overheatTimeS: Number(newValue) })} />

                <TableRowInput
                    name="Max internal temperature"
                    currentValue={warrantyInfo.maxInternalTemp}
                    originalValue={props.warrantyInfo.maxInternalTemp}
                    inputType="number"
                    onSave={save}
                    onChange={newValue => setWarrantyInfo({ ...warrantyInfo, maxInternalTemp: Number(newValue) })} />

                <TableRowInput
                    name="Min internal temperature"
                    currentValue={warrantyInfo.minInternalTemp}
                    originalValue={props.warrantyInfo.minInternalTemp}
                    inputType="number"
                    onSave={save}
                    onChange={newValue => setWarrantyInfo({ ...warrantyInfo, minInternalTemp: Number(newValue) })} />

                <TableRowInput
                    name="GFCI count"
                    currentValue={warrantyInfo.gfciCount}
                    originalValue={props.warrantyInfo.gfciCount}
                    inputType="number"
                    onSave={save}
                    onChange={newValue => setWarrantyInfo({ ...warrantyInfo, gfciCount: Number(newValue) })} />

                <TableRowInput
                    name="Time since last GFCI(sec)"
                    currentValue={warrantyInfo.secSinceLastGfci}
                    originalValue={props.warrantyInfo.secSinceLastGfci}
                    inputType="number"
                    onSave={save}
                    onChange={newValue => setWarrantyInfo({ ...warrantyInfo, secSinceLastGfci: Number(newValue) })} />
            </tbody>
        </table>
    );
};
