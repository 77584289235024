import { Action, Reducer } from "redux";
import { LogEntry, LogSlice } from "./models";
import { KnownAction } from "./actionTypes";
import { Simulators } from "../WorkspaceStore/models";
import { orderLogEntries } from "../../Utility";

const slice: LogSlice = {
    SimpleWifi: [],
    UWG5Nvent: [],
    NuHeat: [],
    UWG4: [],
    Senz: [],
    OWD5: []
};

function logTypeReducer(state: LogEntry[], knownAction: KnownAction): LogEntry[] {
    switch (knownAction.type) {
        case "RECEIVE_ALL_TYPE_LOGS":
            return knownAction.logEntries;
        case "RECEIVE_NEW_TYPE_LOGS":
            return [...state, ...knownAction.logEntries];
        case "CLEAR_LOGS":
            return state.filter(e =>
                e.simulator !== knownAction.simulator &&
                !knownAction.thermostatSerialNumbers.includes(e.thermostatSerialNumber));
        default:
            return state;
    }
}

export const logReducer: Reducer<LogSlice> = (state: LogSlice | undefined, incomingAction: Action): LogSlice => {
    if (state === undefined) {
        return slice;
    }

    const action = incomingAction as KnownAction;
    const simulator = action.simulator;
    const simulatorKey: keyof typeof Simulators = Simulators[simulator] as keyof typeof Simulators;

    // limiting log rows to display to avoid browser freeze
    // there is a limit on backend, but UI just appends logs to the state when new logs are coming,
    // so truncate the state accordingly:
    // - group by serial
    // - select last N logs per thermostat
    // - order thermostats by last logs
    // - select last M thermostats
    // - flatten list
    const logEntriesPerThermostatLimit = 100;
    const thermostatsLimit = 50;
    const logEntries = (logTypeReducer(state[simulatorKey], action) ?? [])
        .reduce((previous, currentItem) => {
            const serial = currentItem.thermostatSerialNumber;
            let group = previous.find(e => e.serial === serial);
            if (!group){
                group = { serial: serial, entries: [] };
                previous.push(group);
            }
            group.entries.push(currentItem);

            return previous;
        }, [] as { serial: string, entries: LogEntry[] }[])
        .sort((ga, gb) => (
            Math.max(...ga.entries.map(x => x.timestamp.getTime())) >
            Math.max(...gb.entries.map(x => x.timestamp.getTime())) ? -1 : 1
        ))
        .slice(0, thermostatsLimit)
        .map(g => ({ ...g, entries: g.entries.sort(orderLogEntries).slice(0, logEntriesPerThermostatLimit) }))
        .reduce((prev, curr) => ([...prev, ...curr.entries]), [] as LogEntry[])
        .sort(orderLogEntries);

    return {
        ...state,
        [simulatorKey]: logEntries
    };
};