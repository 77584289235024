import { AppThunkAction } from "../..";
import { KnownAction } from "../actionTypes";
import { HubConnection } from "@microsoft/signalr";

export interface SenzActionCreator {
    setComfortMode: (id: string, days: number, hours: number, setpoint: number) => AppThunkAction<KnownAction>;
    setVacationMode: (id: string, days: number, hours: number, setpoint: number) => AppThunkAction<KnownAction>;
    setFirstWarming: (id: string, days: number) => AppThunkAction<KnownAction>;
    setFrostProtectionMode: (id: string, setpoint: number) => AppThunkAction<KnownAction>;
    setManualMode: (id: string, setpoint: number) => AppThunkAction<KnownAction>;
    setRegulationOffMode: (id: string) => AppThunkAction<KnownAction>;
}

export function senzActionCreator(connection: HubConnection) {
    return {
        setComfortMode: (id: string, days: number, hours: number, setpoint: number): AppThunkAction<KnownAction> => (_dispatch, _getState) => {
            connection.send("SetComfortMode", id, days, hours, setpoint).catch(alert);
        },
        setVacationMode: (id: string, days: number, hours: number, setpoint: number): AppThunkAction<KnownAction> => (_dispatch, _getState) => {
            connection.send("SetVacationMode", id, days, hours, setpoint).catch(alert);
        },
        setFirstWarming: (id: string, days: number): AppThunkAction<KnownAction> => (_dispatch, _getState) => {
            connection.send("SetFirstWarming", id, days).catch(alert);
        },
        setFrostProtectionMode: (id: string, setpoint: number): AppThunkAction<KnownAction> => (_dispatch, _getState) => {
            connection.send("SetFrostProtectionMode", id, setpoint).catch(alert);
        },
        setManualMode: (id: string, setpoint: number): AppThunkAction<KnownAction> => (_dispatch, _getState) => {
            connection.send("SetManualMode", id, setpoint).catch(alert);
        },
        setRegulationOffMode: (id: string): AppThunkAction<KnownAction> => (_dispatch, _getState) => {
            connection.send("SetRegulationOffMode", id).catch(alert);
        }
    };
}