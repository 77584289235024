import React, { FC } from "react";

export interface TableRowToggleProps {
    name: string
    value: boolean
    onChange?: (newValue: boolean) => void
    ifTrue: string
    ifFalse: string
    readOnly?: boolean
}

export const TableRowToggle: FC<TableRowToggleProps> = (props) => {

    const handleClick = function (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) {
        if (props.readOnly || !props.onChange) {
            return;
        }

        props.onChange(!props.value);
    };

    return (
        <tr>
            <th className="align-middle">{props.name}</th>
            <td>
                <input
                    readOnly
                    value={props.value ? props.ifTrue : props.ifFalse}
                    className="form-control form-control-sm w-100" />
            </td>
            <td>
                { props.readOnly === true ? (<div />) :
                    (<button
                        className="btn btn-sm btn-outline-primary btn-block"
                        onClick={handleClick}>Toggle</button>
                    )
                }
            </td>
        </tr>
    );
};
