import { Store } from "redux";
import { DistributorListItem, LoadableThermostat, UWG5NventThermostat, UserAccountItem } from "./models";
import {
    ReceiveAllUWG5NventThermostats,
    ReceiveNewUWG5NventThermostat,
    RemoveUWG5NventThermostat,
    UpdateUWG5NventThermostatState,
    ReceiveLoadableThermostats,
    AddLoadableThermostat,
    UpdateLoadableThermostat,
    RemoveLoadableThermostat,
    ReceiveDistributorSearchResult,
    ReceiveUserSearchResult
} from "./actionTypes";
import { buildHub } from "../../ApiGateway";
import { HubConnectionState } from "@microsoft/signalr";


// Create UWG5 nVent hub
export const uwg5NventHub = buildHub("UWG5NventHub");

const mapStringToDate = (t: UWG5NventThermostat) => {
    if (!!t.mode.holdUntilEndTime && typeof (t.mode.holdUntilEndTime) === "string") {
        t.mode.holdUntilEndTime = new Date(t.mode.holdUntilEndTime);
    }
};

/**
 * Connect to UWG5 nVent SignalR hub and register handlers.
 */
export function connectToUWG5NventHub(store: Store) {
    if (uwg5NventHub.state !== HubConnectionState.Disconnected) {
        return;
    }

    uwg5NventHub.start()
        .catch(err => console.error("Failed to start UWG5NventHub", err));

    uwg5NventHub.on("ReceiveAllThermostats", (thermostats: UWG5NventThermostat[]) => {
        thermostats.forEach(mapStringToDate);
        store.dispatch({ type: "RECEIVE_ALL_UWG5_NVENT_THERMOSTATS", thermostats } as ReceiveAllUWG5NventThermostats);
    });

    uwg5NventHub.on("ReceiveNewThermostat", (thermostat: UWG5NventThermostat) => {
        mapStringToDate(thermostat);
        console.log(thermostat);
        store.dispatch({ type: "RECEIVE_NEW_UWG5_NVENT_THERMOSTAT", thermostat } as ReceiveNewUWG5NventThermostat);
    });

    uwg5NventHub.on("UpdateThermostatState", (thermostat: UWG5NventThermostat) => {
        mapStringToDate(thermostat);
        store.dispatch({ type: "UPDATE_UWG5_NVENT_THERMOSTAT_STATE", thermostat } as UpdateUWG5NventThermostatState);
    });

    uwg5NventHub.on("RemoveThermostat", (id: string) => {
        store.dispatch({ type: "REMOVE_UWG5_NVENT_THERMOSTAT", id } as RemoveUWG5NventThermostat);
    });

    uwg5NventHub.on("ReceiveLoadableThermostats", (thermostats: LoadableThermostat[]) => {
        store.dispatch({ type: "RECEIVE_LOADABLE_UWG5_NVENT_THERMOSTATS", thermostats } as ReceiveLoadableThermostats);
    });

    uwg5NventHub.on("RemoveLoadableThermostat", (id: string) => {
        store.dispatch({ type: "REMOVE_LOADABLE_UWG5_NVENT_THERMOSTAT", id } as RemoveLoadableThermostat);
    });

    uwg5NventHub.on("AddLoadableThermostat", (thermostat: LoadableThermostat) => {
        store.dispatch({ type: "ADD_LOADABLE_UWG5_NVENT_THERMOSTAT", thermostat } as AddLoadableThermostat);
    });

    uwg5NventHub.on("UpdateLoadableThermostat", (thermostat: LoadableThermostat) => {
        store.dispatch({ type: "UPDATE_LOADABLE_UWG5_NVENT_THERMOSTAT", thermostat } as UpdateLoadableThermostat);
    });

    uwg5NventHub.on("ReceiveDistributorSearchResult", (distributors: DistributorListItem[]) => {
        store.dispatch({ type: "RECEIVE_DISTRIBUTOR_SEARCH_RESULT", distributors } as ReceiveDistributorSearchResult);
    });

    uwg5NventHub.on("ReceiveUserSearchResult", (users: UserAccountItem[]) => {
        store.dispatch({ type: "RECEIVE_USER_SEARCH_RESULT", users } as ReceiveUserSearchResult);
    });
}
