import React, { FC, useState, useEffect } from "react";
import { TableRowInput } from "../Common";
import { AwayModeSettings as Model } from "../../store/SimpleWifiStore/models";

export interface AwayModeSettingsProps {
    awayModeSettings: Model,
    saveAwayModeSettings: (newAwayModeSettings: Model) => void
}

export const AwayModeSettings: FC<AwayModeSettingsProps> = (props) => {
    const [awayModeSettings, setAwayModeSettings] = useState(props.awayModeSettings);

    useEffect(() => {
        setAwayModeSettings(props.awayModeSettings);
    }, [props.awayModeSettings]);

    return (
        <table className="table table-sm mb-1">
            <tbody>
                <TableRowInput
                    name="Start Time"
                    currentValue={awayModeSettings.startTime}
                    originalValue={props.awayModeSettings.startTime}
                    inputType="date"
                    onChange={newValue => setAwayModeSettings({ ...awayModeSettings, startTime: newValue as Date })} />

                <TableRowInput
                    name="End Time"
                    currentValue={awayModeSettings.endTime}
                    originalValue={props.awayModeSettings.endTime}
                    inputType="date"
                    onChange={newValue => setAwayModeSettings({ ...awayModeSettings, endTime: newValue as Date })} />

                <TableRowInput
                    name="Max Temperature"
                    currentValue={awayModeSettings.temperatureLimitMax}
                    originalValue={props.awayModeSettings.temperatureLimitMax}
                    inputType="number"
                    onChange={newValue => setAwayModeSettings({ ...awayModeSettings, temperatureLimitMax: Number(newValue) })} />
            </tbody>
        </table>
    );
};
