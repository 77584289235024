import { AppThunkAction } from "..";
import { KnownAction } from "./actionTypes";
import { simpleWifiHub } from "./signalRHub";
import {
    SimpleWifiSettings,
    Mode,
    Errors,
    DisplaySettings,
    RegulationSettings,
    HeatingSettings,
    FloorSensorSettings,
    AwayModeSettings,
    DateTimeSettings,
    WifiSettings,
    WarrantyInfo,
    DeviceInfo,
    CreateSimpleWifiRequest
} from "./models";
import { toBase64 } from "../../Utility";

export enum SimpleWifiAction {
    CreateNew = "CreateNew",
    Remove = "RemoveThermostat",
    Connect = "Connect",
    Link = "Link",
    Unlink = "Unlink",
    Disconnect = "Disconnect",
    IncreaseSetpoint = "IncreaseSetpoint",
    DecreaseSetpoint = "DecreaseSetpoint",
    Save = "SaveThermostat",
    ToggleTimer = "ToggleTimer",
    ToggleHeatRelay = "ToggleHeatRelay",
    ToggleReconnect = "ToggleReconnect",
    FactoryReset = "RequestFactoryReset"
}

export const actionCreator = {
    createNew: (workspace: number, request: CreateSimpleWifiRequest | null = null, amount: number = 1): AppThunkAction<KnownAction> => (_dispatch, _getState) => {
        simpleWifiHub.send("CreateNewThermostat", workspace, amount, request).catch(alert);
    },
    addExisting: (workspace: number, email: string, pageNumber: number): AppThunkAction<KnownAction> => (_dispatch, _getState) => {
        simpleWifiHub.send("AddExisting", workspace, email, pageNumber).catch(alert);
    },
    invoke: (id: string, action: SimpleWifiAction): AppThunkAction<KnownAction> => (_dispatch, _getState) => {
        simpleWifiHub.send(action, id).catch(alert);
    },
    updateSettings: (id: string, settings: SimpleWifiSettings): AppThunkAction<KnownAction> => (_dispatch, _getState) => {
        simpleWifiHub.send("UpdateSettings", id, settings).catch(alert);
    },
    setSetpoint: (id: string, setpoint: number): AppThunkAction<KnownAction> => (_dispatch, _getState) => {
        simpleWifiHub.send("SetSetpoint", id, setpoint).catch(alert);
    },
    setAutoMode: (id: string): AppThunkAction<KnownAction> => (_dispatch, _getState) => {
        simpleWifiHub.send("SetAutoMode", id).catch(alert);
    },
    setManualMode: (id: string, setpoint: number): AppThunkAction<KnownAction> => (_dispatch, _getState) => {
        simpleWifiHub.send("SetManualMode", id, setpoint).catch(alert);
    },
    setHoldMode: (id: string, setpoint: number, endTime: Date | null, isPermanent: boolean | null): AppThunkAction<KnownAction> => (_dispatch, _getState) => {
        simpleWifiHub.send("SetHoldMode", id, setpoint, endTime, isPermanent).catch(alert);
    },
    cancelHoldMode: (id: string): AppThunkAction<KnownAction> => (_dispatch, _getState) => {
        simpleWifiHub.send("CancelHoldMode", id).catch(alert);
    },
    setServerUrl: (serverUrl: string): AppThunkAction<KnownAction> => (_dispatch, _getState) => {
        simpleWifiHub.send("SetServerUrl", serverUrl).catch(alert);
    },
    setErrors: (id: string, errors: Errors): AppThunkAction<KnownAction> => (_dispatch, _getState) => {
        simpleWifiHub.send("SetErrors", id, errors).catch(alert);
    },
    setStandbyState: (id: string, isInStandby: boolean): AppThunkAction<KnownAction> => (_dispatch, _getState) => {
        simpleWifiHub.send("SetStandbyState", id, isInStandby).catch(alert);
    },
    setCurrentScheduleEvent: (id: string, day: number, eventId: number): AppThunkAction<KnownAction> => (_dispatch, _getState) => {
        console.log("HubEventId: " + eventId);
        simpleWifiHub.send("SetCurrentScheduleEvent", id, day, eventId).catch(alert);
    },
    fetchLoadableThermostats: (): AppThunkAction<KnownAction> => (_dispatch, _getState) => {
        simpleWifiHub.send("FetchLoadableThermostats").catch(alert);
    },
    loadThermostats: (ids: string[]): AppThunkAction<KnownAction> => (_dispatch, _getState) => {
        simpleWifiHub.send("LoadThermostats", ids).catch(alert);
    },
    deleteThermostats: (ids: string[]): AppThunkAction<KnownAction> => (_dispatch, _getState) => {
        simpleWifiHub.send("DeleteThermostats", ids).catch(alert);
    },
    updateDeviceInfo: (id: string, deviceInfo: DeviceInfo): AppThunkAction<KnownAction> => (_dispatch, _getState) => {
        const obj = {
            ...deviceInfo,
            mcuUuid: toBase64(deviceInfo.mcuUuid),
            protocolVersion: toBase64(deviceInfo.protocolVersion)
        };
        simpleWifiHub.send("UpdateDeviceInfo", id, obj).catch(alert);
    },
    updateDisplaySettings: (id: string, settings: DisplaySettings): AppThunkAction<KnownAction> => (_dispatch, _getState) => {
        simpleWifiHub.send("UpdateDisplaySettings", id, settings).catch(alert);
    },
    updateWifiSettings: (id: string, settings: WifiSettings): AppThunkAction<KnownAction> => (_dispatch, _getState) => {
        simpleWifiHub.send("UpdateWifiSettings", id, settings).catch(alert);
    },
    updateRegulationSettings: (id: string, settings: RegulationSettings): AppThunkAction<KnownAction> => (_dispatch, _getState) => {
        simpleWifiHub.send("UpdateRegulationSettings", id, settings).catch(alert);
    },
    updateHeatingSettings: (id: string, settings: HeatingSettings): AppThunkAction<KnownAction> => (_dispatch, _getState) => {
        simpleWifiHub.send("UpdateHeatingSettings", id, settings).catch(alert);
    },
    updateFloorSensorSettings: (id: string, settings: FloorSensorSettings): AppThunkAction<KnownAction> => (_dispatch, _getState) => {
        simpleWifiHub.send("UpdateFloorSensorSettings", id, settings).catch(alert);
    },
    updateAwayModeSettings: (id: string, settings: AwayModeSettings): AppThunkAction<KnownAction> => (_dispatch, _getState) => {
        simpleWifiHub.send("UpdateAwayModeSettings", id, settings).catch(alert);
    },
    updateDateTimeSettings: (id: string, settings: DateTimeSettings): AppThunkAction<KnownAction> => (_dispatch, _getState) => {
        simpleWifiHub.send("UpdateDateTimeSettings", id, settings).catch(alert);
    },
    updateWarrantyInfo: (id: string, info: WarrantyInfo): AppThunkAction<KnownAction> => (_dispatch, _getState) => {
        simpleWifiHub.send("UpdateWarrantyInfo", id, info).catch(alert);
    },
    searchDistributors: (query: string): AppThunkAction<KnownAction> => (_dispatch, _getState) => {
        simpleWifiHub.send("SearchDistributors", query).catch(alert);
    },
    searchUsers: (query: string): AppThunkAction<KnownAction> => (_dispatch, _getState) => {
        simpleWifiHub.send("SearchUsers", query).catch(alert);
    }
};
