import React, { FC, useState, useEffect } from "react";
import { TableRowInput, TableRowDropdown } from "../Common";
import {
    RegulationSettings as Model,
    SensorApplication,
    FloorType,
    BoolType
} from "../../store/UWG5NventStore/models";

export interface RegulationSettingsProps {
    regulationSettings: Model,
    saveRegulationSettings: (newRegulationSettings: Model) => void
}

export const RegulationSettings: FC<RegulationSettingsProps> = (props) => {
    const [regulationSettings, setRegulationSettings] = useState(props.regulationSettings);

    const save = () => props.saveRegulationSettings(regulationSettings);

    useEffect(() => {
        setRegulationSettings(props.regulationSettings);
    }, [props.regulationSettings]);

    return (
        <table className="table table-sm mb-1">
            <tbody>
                <TableRowDropdown
                    name="Sensor Application"
                    currentValue={regulationSettings.sensorApplication}
                    originalValue={props.regulationSettings.sensorApplication}
                    type={SensorApplication}
                    onChange={newValue => setRegulationSettings({ ...regulationSettings, sensorApplication: newValue })}
                    onSave={save} />

                <TableRowInput
                    name="Temperature Limit Max"
                    currentValue={regulationSettings.temperatureLimitMax}
                    originalValue={props.regulationSettings.temperatureLimitMax}
                    inputType="number"
                    onSave={save}
                    onChange={newValue => setRegulationSettings({ ...regulationSettings, temperatureLimitMax: Number(newValue) })} />

                <TableRowInput
                    name="Temperature Limit Min"
                    currentValue={regulationSettings.temperatureLimitMin}
                    originalValue={props.regulationSettings.temperatureLimitMin}
                    inputType="number"
                    onSave={save}
                    onChange={newValue => setRegulationSettings({ ...regulationSettings, temperatureLimitMin: Number(newValue) })} />

                <TableRowDropdown
                    name="Floor Type"
                    currentValue={regulationSettings.floorType}
                    originalValue={props.regulationSettings.floorType}
                    type={FloorType}
                    onChange={newValue => setRegulationSettings({ ...regulationSettings, floorType: newValue })}
                    onSave={save} />

                <TableRowInput
                    name="Floor Prot Max"
                    currentValue={regulationSettings.floorProtMax}
                    originalValue={props.regulationSettings.floorProtMax}
                    inputType="number"
                    onSave={save}
                    onChange={newValue => setRegulationSettings({ ...regulationSettings, floorProtMax: Number(newValue) })} />

                <TableRowInput
                    name="Floor Prot Min"
                    currentValue={regulationSettings.floorProtMin}
                    originalValue={props.regulationSettings.floorProtMin}
                    inputType="number"
                    onSave={save}
                    onChange={newValue => setRegulationSettings({ ...regulationSettings, floorProtMin: Number(newValue) })} />

                <TableRowInput
                    name="Frost Prot Temperature"
                    currentValue={regulationSettings.frostProtTemp}
                    originalValue={props.regulationSettings.frostProtTemp}
                    inputType="number"
                    onSave={save}
                    onChange={newValue => setRegulationSettings({ ...regulationSettings, frostProtTemp: Number(newValue) })} />

                <TableRowDropdown
                    name="Frost Prot Enabled"
                    currentValue={Number(regulationSettings.frostProtEnabled)}
                    originalValue={Number(props.regulationSettings.frostProtEnabled)}
                    type={BoolType}
                    onChange={newValue => setRegulationSettings({ ...regulationSettings, frostProtEnabled: !!newValue })}
                    onSave={save} />
            </tbody>
        </table>
    );
};
