import React, { FC, useState, useEffect } from "react";
import { TableRowInput, TableRowDropdown } from "../Common";
import { WifiSettings as Model, Authentication } from "../../store/SimpleWifiStore/models";

export interface WifiSettingsProps {
    wifiSettings: Model,
    saveWifiSettings: (newWifiSettings: Model) => void
}

export const WifiSettings: FC<WifiSettingsProps> = (props) => {
    const [wifiSettings, setWifiSettings] = useState(props.wifiSettings);

    const save = () => props.saveWifiSettings(wifiSettings);
    useEffect(() => {
        setWifiSettings(props.wifiSettings);
    }, [props.wifiSettings]);

    return (
        <table className="table table-sm mb-1">
            <tbody>
                <TableRowInput
                    name="Ssid"
                    currentValue={wifiSettings.ssid}
                    originalValue={props.wifiSettings.ssid}
                    inputType="text"
                    onSave={save}
                    onChange={newValue => setWifiSettings({ ...wifiSettings, ssid: newValue as string })} />

                <TableRowDropdown
                    name="Authentication"
                    currentValue={Number(wifiSettings.authentication)}
                    originalValue={Number(props.wifiSettings.authentication)}
                    type={Authentication}
                    onChange={newValue => setWifiSettings({ ...wifiSettings, authentication: newValue })}
                    onSave={save} />

                <TableRowInput
                    name="Ipv4"
                    currentValue={wifiSettings.ipv4}
                    originalValue={props.wifiSettings.ipv4}
                    inputType="text"
                    onSave={save}
                    onChange={newValue => setWifiSettings({ ...wifiSettings, ipv4: newValue as string })} />

                <TableRowInput
                    name="Ipv6"
                    currentValue={wifiSettings.ipv6}
                    originalValue={props.wifiSettings.ipv6}
                    inputType="text"
                    onSave={save}
                    onChange={newValue => setWifiSettings({ ...wifiSettings, ipv6: newValue as string })} />

                <TableRowInput
                    name="Gateway"
                    currentValue={wifiSettings.defaultGateway}
                    originalValue={props.wifiSettings.defaultGateway}
                    inputType="text"
                    onSave={save}
                    onChange={newValue => setWifiSettings({ ...wifiSettings, defaultGateway: newValue as string })} />

                <TableRowInput
                    name="Mac address"
                    currentValue={wifiSettings.macWifi}
                    originalValue={props.wifiSettings.macWifi}
                    inputType="text"
                    onSave={save}
                    onChange={newValue => setWifiSettings({ ...wifiSettings, macWifi: newValue as string })} />
            </tbody>
        </table>
    );
};