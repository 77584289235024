import React from "react";

export interface DropdownProps {
    display: string;
    show: boolean;
    toggleDropdown: () => void;
    children: React.ReactNode
}

export const Dropdown = (props: DropdownProps) => {
    return (
        <div className="dropdown w-100">
            <button
                className="btn btn-outline-secondary dropdown-toggle w-100"
                onClick={() => props.toggleDropdown()}>
                <span>{props.display}</span>
            </button>
            <div className={`dropdown-menu bg-light overflow-auto ${props.show ? "d-block" : "d-none"}`} style={{ width: "95%" }}>
                {props.children}
            </div>
        </div>
    );
};