import * as React from "react";
import { FaExclamation, FaInfo, FaExclamationTriangle, FaDotCircle } from "react-icons/fa";
import { GrClose } from "react-icons/gr";

export enum MessageBoxType {
    Error,
    Warning,
    Information
}

interface SpinnerProps {
    title: string;
    description?: string;
    children?: React.ReactNode;
    className?: string;
    type?: MessageBoxType;
}

export const MessageBox = (props: SpinnerProps) => {
    // Component state
    const [isClosed, setIsClosed] = React.useState(false);

    // Border color based on MessageBoxType.
    const borderColor = (() => {
        switch (props.type) {
            case MessageBoxType.Error: return "danger";
            case MessageBoxType.Warning: return "warning";
            case MessageBoxType.Information: return "primary";
            default: return "secondary";
        }
    })();

    // Header icon based on MessageBoxType.
    const icon = (() => {
        const iconClassName = `mt-n1 text-${borderColor}`;

        switch (props.type) {
            case MessageBoxType.Error: return <FaExclamation className={iconClassName} />;
            case MessageBoxType.Warning: return <FaExclamationTriangle className={iconClassName} />;
            case MessageBoxType.Information: return <FaInfo className={iconClassName} />;
            default: return <FaDotCircle className={iconClassName} />;
        }
    })();

    // Render if message box is closed
    if (isClosed) {
        return <></>;
    }

    // Render
    return (
        <div className={props.className}>
            <div className={`border border-${borderColor} mx-auto p-3 rounded-lg shadow col-md-6 col-lg-5 col-xl-4`}>
                <button
                    onClick={() => setIsClosed(true)}
                    className="float-right btn btn-sm btn-default p-0">
                    <GrClose className="mt-n2" />
                </button>
                <h5>{icon} {props.title}</h5>
                {!props.description ? null : <p className="mb-0">{props.description}</p>}
                {props.children}
            </div>
        </div>
    );
};
