import React, { FC, useState, useEffect } from "react";
import { TableRowInput, TableRowDropdown } from "../Common";
import { HeatingSettings as Model, FloorSensorType, BoolType } from "./../../store/SimpleWifiStore/models";

export interface HeatingSettingsProps {
    heatingSettings: Model,
    saveHeatingSettings: (newHeatingSettings: Model) => void
}

export const HeatingSettings: FC<HeatingSettingsProps> = (props) => {
    const [heatingSettings, setHeatingSettings] = useState(props.heatingSettings);

    const save = () => props.saveHeatingSettings(heatingSettings);
    useEffect(() => {
        setHeatingSettings(props.heatingSettings);
    }, [props.heatingSettings]);

    return (
        <table className="table table-sm mb-1">
            <tbody>
                <TableRowInput
                    name="Floor Load"
                    currentValue={heatingSettings.floorLoad}
                    originalValue={props.heatingSettings.floorLoad}
                    inputType="number"
                    onSave={save}
                    onChange={newValue => setHeatingSettings({ ...heatingSettings, floorLoad: Number(newValue) })} />


                <TableRowDropdown
                    name="Floor Sensor Type"
                    currentValue={heatingSettings.floorSensorType}
                    originalValue={props.heatingSettings.floorSensorType}
                    type={FloorSensorType}
                    onChange={newValue => setHeatingSettings({ ...heatingSettings, floorSensorType: newValue })}
                    onSave={save} />

                <TableRowInput
                    name="Floor Sensor Offset"
                    currentValue={heatingSettings.floorSensorOffset}
                    originalValue={props.heatingSettings.floorSensorOffset}
                    inputType="number"
                    onSave={save}
                    onChange={newValue => setHeatingSettings({ ...heatingSettings, floorSensorOffset: Number(newValue) })} />

                <TableRowInput
                    name="Room Sensor Offset"
                    currentValue={heatingSettings.roomSensorOffset}
                    originalValue={props.heatingSettings.roomSensorOffset}
                    inputType="number"
                    onSave={save}
                    onChange={newValue => setHeatingSettings({ ...heatingSettings, roomSensorOffset: Number(newValue) })} />

                <TableRowDropdown
                    name="Is Adaptive Enabled"
                    currentValue={Number(heatingSettings.adaptiveEnabled)}
                    originalValue={Number(props.heatingSettings.adaptiveEnabled)}
                    type={BoolType}
                    onChange={newValue => setHeatingSettings({ ...heatingSettings, adaptiveEnabled: !!newValue })}
                    onSave={save} />

                <TableRowDropdown
                    name="Is Open Window Enabled"
                    currentValue={Number(heatingSettings.openWindowEnabled)}
                    originalValue={Number(props.heatingSettings.openWindowEnabled)}
                    type={BoolType}
                    onChange={newValue => setHeatingSettings({ ...heatingSettings, openWindowEnabled: !!newValue })}
                    onSave={save} />
            </tbody>
        </table>
    );
};
