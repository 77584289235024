import React, { FC } from "react";

export interface TableRowDropdownProps {
    name: string
    currentValue: number
    originalValue: number
    onChange: (newValue: any) => void
    onSave: () => void
    type: any
}

export const TableRowDropdown: FC<TableRowDropdownProps> = (props) => {
    const type = props.type;
    const options = Object.keys(type).filter(key => isNaN(Number(key)) && typeof key !== "boolean");

    return (
        <tr>
            <th className="align-middle">{props.name}</th>
            <td>
                <div className="dropdown mr-1">
                    <button type="button" className="btn btn-sm btn-secondary w-100 dropdown-toggle" data-toggle="dropdown" aria-expanded="false">
                        {type[props.currentValue]}
                    </button>
                    <div className="dropdown-menu">
                        {options.map((option, index) => (
                            <button
                                disabled={type[option] === props.currentValue}
                                onClick={() => props.onChange(type[option])}
                                key={`${index}-${option}`}
                                className="dropdown-item">
                                {option}
                            </button>
                        ))}
                    </div>
                </div>
            </td>
            <td>
                <button
                    disabled={props.currentValue === props.originalValue}
                    className="btn btn-sm btn-outline-primary btn-block"
                    onClick={() => props.onSave()}>Save</button></td>
        </tr>
    );
};