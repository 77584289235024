import * as React from "react";
import { useDispatch } from "react-redux";
import { AlertType } from "../../store/AlertStore/models";
import { Action, StateToUpdate } from "../../store/LegacyStore/actions";
import { OWD5InternalStorage } from "../../store/LegacyStore/models/owd5";
import { popupAlert } from "../../Utility";

type Props = {
    isConnected: boolean,
    state: OWD5InternalStorage,
    invokeAction(action: Action): void,
    updateState(value: any, toUpdate: StateToUpdate): void
}

export const OWD5StateComponent = (props: Props) => {
    // Component state mapped from props
    const [serverIp, setServerIp] = React.useState(props.state.serverIp + ":" + props.state.serverPort);
    const [serialNumber, setSerialNumber] = React.useState(props.state.serialNumber || "");
    const [name, setName] = React.useState(props.state.name || "");
    const dispatch = useDispatch();

    React.useEffect(() => {
        setSerialNumber(props.state.serialNumber);
        setName(props.state.name);
    }, [props.state, setSerialNumber, setName]);

    const update = (value: any, toUpdate: StateToUpdate): void => {
        props.updateState(value, toUpdate);
        popupAlert(dispatch, "State saved!", "You have successfully saved the thermostat state.", AlertType.SUCCESS);
    };

    // Render
    return (
        <div className="ml-2 mr-2">
            <table className="table table-sm mt-2 mb-0">
                <tbody>
                    <tr>
                        <th>Server url</th>
                        <td><input
                            className="form-control form-control-sm"
                            value={serverIp || ""}
                            readOnly={props.isConnected}
                            onChange={(e) => setServerIp(e.target.value)}
                            style={{ fontSize: "1rem" }} /></td>
                        <td><button
                            className="btn btn-sm btn-outline-primary ml-2"
                            disabled={props.isConnected}
                            onClick={() => update(serverIp, StateToUpdate.ServerURL)}>
                            Save</button></td>
                    </tr>
                    <tr>
                        <th>Serial number</th>
                        <td><input
                            className="form-control form-control-sm"
                            value={serialNumber || ""}
                            disabled={props.isConnected}
                            onChange={(e) => setSerialNumber(e.target.value)} style={{ fontSize: "1rem" }} /></td>
                        <td><button
                            className="btn btn-sm btn-outline-primary ml-2"
                            disabled={props.isConnected}
                            onClick={() => update(serialNumber, StateToUpdate.SerialNumber)}>
                            Save</button></td>
                    </tr>
                    <tr>
                        <th>Name</th>
                        <td><input
                            className="form-control form-control-sm"
                            value={name || ""}
                            disabled={!props.isConnected}
                            onChange={(e) => setName(e.target.value)} style={{ fontSize: "1rem" }} /></td>
                        <td><button
                            className="btn btn-sm btn-outline-primary ml-2"
                            disabled={!props.isConnected}
                            onClick={() => update(name, StateToUpdate.Name)}>
                            Save</button></td>
                    </tr>

                    {/*Text fields that are not editable*/}
                    <tr>
                        <th>Software Version</th>
                        <td><input
                            className="form-control form-control-sm"
                            value={props.state.softwareVersion || ""}
                            readOnly={true}
                            style={{ fontSize: "1rem" }} /></td>
                    </tr>
                    {<tr>
                        <th>Distributor Id</th>
                        <td><input
                            className="form-control form-control-sm"
                            value={props.state.distributerId || ""}
                            readOnly={true}
                            style={{ fontSize: "1rem" }} /></td>
                    </tr>}

                    {/*Toggles*/}
                    <tr>
                        <th>Daylight Savings</th>
                        <td>{props.state.useDaylightSavingsTime ? "Using daylight savings" : "Not using daylight savings"}</td>
                    </tr>
                </tbody>
            </table>
        </div>
    );
};