import { Simulators } from "../WorkspaceStore/models";

export interface LogEntry {
    entryId: number,
    thermostatSerialNumber: string,
    thermostatName: string,
    logMessage: string,
    logLevel: LogLevel,
    eventData: string,
    timestamp: Date,
    simulator: Simulators
}

export interface LogSlice {
    SimpleWifi: LogEntry[],
    UWG5Nvent: LogEntry[],
    NuHeat: LogEntry[],
    UWG4: LogEntry[],
    Senz: LogEntry[],
    OWD5: LogEntry[]
}

export enum LogLevel {
    /// <summary>
    /// Debug.
    /// </summary>
    Debug = 1,

    /// <summary>
    /// Information.
    /// </summary>
    Info = 2,

    /// <summary>
    /// Warning.
    /// </summary>
    Warning = 3,

    /// <summary>
    /// Error.
    /// </summary>
    Error = 4,

    /// <summary>
    /// Error.
    /// </summary>
    Critical = 5,
}