import * as React from "react";
import { ModalStatus } from "./WorkspaceDropdown";
import { Category } from "../../store/WorkspaceStore/models";
import { AlertType } from "../../store/AlertStore/models";
import { useDispatch } from "react-redux";
import { popupAlert } from "../../Utility";

interface Props {
    category: Category,
    status: ModalStatus,
    parentProps: any
}

const CategoryModal = (props: Props) => {
    const [categoryName, setCategoryName] = React.useState("");
    const dispatch = useDispatch();

    React.useEffect(() => {
        setCategoryName(props.category.name);
    }, [props.category, setCategoryName]);

    /**
    * Maps the modal state to the right handler
    */
    const clickCategory = (): void => {
        switch (props.status) {
            case ModalStatus.Add:
                addCategory();

                break;
            case ModalStatus.Edit:
                editCategory();

                break;
            default:
        }
    };

    /**
     * Handles creating a new category through the modal
     */
    const addCategory = (): void => {
        if (categoryName.length === 0) {
            popupAlert(dispatch, "Enter in name!", "You will have to enter a valid name for the category.", AlertType.DANGER);

            return;
        }

        props.parentProps.addCategory(categoryName, props.parentProps.workspace.simulator);
        popupAlert(dispatch, "Category added!", "You have successfully added a new category.", AlertType.SUCCESS);
    };

    /**
     * Handles editing a category through the modal
     */
    const editCategory = (): void => {
        if (categoryName.length === 0) {
            popupAlert(dispatch, "Enter in name!", "You will have to enter a valid name for the category.", AlertType.DANGER);

            return;
        }

        props.category.name = categoryName;
        props.parentProps.updateCategory(props.category);
        popupAlert(dispatch, "Category updated!", "You have successfully updated the category.", AlertType.SUCCESS);
    };

    return (
        <>
            <div className="modal fade" id="categoryModal" tabIndex={-1} aria-labelledby="categoryModalLabel" aria-hidden="true">
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="categoryModalLabel">{props.status.toString()} Category</h5>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <form>
                                <div className="form-group">
                                    <label className="col-form-label">Name:</label>
                                    <input type="text" className="form-control" value={categoryName || ""} onChange={e => setCategoryName(e.target.value)} />
                                </div>
                            </form>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
                            <button type="button" className="btn btn-primary" data-dismiss="modal" onClick={() => clickCategory()}>{props.status.toString()} category</button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default CategoryModal;