import * as React from "react";
import { useDispatch } from "react-redux";
import { RouteComponentProps } from "react-router";
import { AlertType } from "../../store/AlertStore/models";
import { ApplicationState } from "../../store/index";
import { GenericActionCreator } from "../../store/LegacyStore/actions";
import { LegacyThermostatType, Thermostat, ThermostatType } from "../../store/LegacyStore/models";
import { popupAlert } from "../../Utility";
import { getThermostatKey } from "./utility/enumKey";

type Props =
    ApplicationState &
    RouteComponentProps &
    GenericActionCreator &
    ThermostatType

export function LegacyLoadWizard(props: Props) {
    // Component state
    const [searchTerm, setSearchTerm] = React.useState("");
    const [selectedThermostats, setSelectedThermostats] = React.useState<string[]>([]);
    const thermostatKey = getThermostatKey(props.thermostatType);
    const checkbox = React.useRef(null);
    const dispatch = useDispatch();

    //Update when the workspaces slice changes (ie. new selected workspace)
    React.useEffect(() => {
        //Ensuring the SignalR hub is indeed connected before fetching
        setTimeout(() => props.fetchLoadableThermostats, 1000);
    }, [props.workspace, props.fetchLoadableThermostats]);

    /**
     * Normalize string.
     */
    const normalizeString = (stringToNormalize: string): string => (stringToNormalize || "").trim().toUpperCase();

    /**
    * Handle searching for simulated thermostat(s).
    */
    const search = () => {
        const normalizedSearchTerm = normalizeString(searchTerm);

        if (normalizedSearchTerm === "") {
            return props.legacy[thermostatKey].loadable
                .filter(thermostat => thermostat.workspaceID === props.workspace.focused.id);
        }

        return props.legacy[thermostatKey].loadable
            .filter(thermostat => thermostat.workspaceID === props.workspace.focused.id)
            .filter(thermostat =>
                normalizeString(thermostat.internalStorage.name).includes(normalizedSearchTerm) ||
                normalizeString(thermostat.internalStorage.serialNumber).includes(normalizedSearchTerm) ||
                normalizeString(thermostat.internalStorage.email).includes(normalizedSearchTerm));
    };

    /**
    * Load selected thermostats.
    */
    const loadThermostats = () => {
        if (selectedThermostats.length === 0) {
            popupAlert(dispatch, "Loading failed!", "You have to select at least one thermostat to load!", AlertType.DANGER);

            return;
        }

        props.loadThermostats(selectedThermostats);
        props.history.push("/");
        popupAlert(dispatch, "Thermostats loaded!", "You have successfully loaded the selected thermostats!", AlertType.SUCCESS);
    };

    /**
     * Delete selected thermostats.
     */
    const deleteThermostats = () => {
        if (selectedThermostats.length === 0) {
            popupAlert(dispatch, "Delete failed!", "You have to select at least one thermostat to delete!", AlertType.DANGER);

            return;
        }

        props.deleteThermostats(selectedThermostats);
        setSelectedThermostats([]);
        popupAlert(dispatch, "Thermostats deleted!", "You have successfully deleted the selected thermostats.", AlertType.SUCCESS);
    };

    const checkboxClicked = (thermostat: Thermostat): void => {
        const index = selectedThermostats.findIndex(t => t === thermostat.thermostatID);

        if (index > -1) {
            //Remove
            if (selectedThermostats.length === 1) {
                setSelectedThermostats([]);
            } else {
                setSelectedThermostats(previous => previous.splice(index, 1));
            }
        } else {
            //Add
            setSelectedThermostats(previous => [...previous, thermostat.thermostatID]);
        }
    };

    const list = search()
        .map(thermostat =>
            <div className="d-flex p-4" style={{ borderBottom: "1px solid #e3e8ee" }} key={thermostat.thermostatID} >
                <div className="d-flex justify-content-center align-items-center mr-4" style={{ width: 110, borderRight: "1px solid #e3e8ee" }}>
                    <input style={{ height: 30, width: 30 }} type="checkbox" onClick={() => checkboxClicked(thermostat)} ref={checkbox} />
                </div>
                <div>
                    {thermostat.internalStorage.name === "" ? <i>No Name</i> : thermostat.internalStorage.name} <br />
                    <span style={{ fontSize: 14 }}>Serial number: {thermostat.internalStorage.serialNumber} </span><br />
                    <span style={{ fontSize: 14 }}>Email: {thermostat.internalStorage.email} </span><br />
                </div>
            </div>
        );

    // Render
    return (
        <div className="bg-white rounded-lg shadow p-3 pull-right-div">
            <div className="pull-right">
                <input type="text"
                    placeholder="Search"
                    className="pull-right"
                    style={{ height: 38, fontSize: 14 }}
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)} />
                <button
                    className="btn btn-primary mr-2 pull-right"
                    onClick={() => loadThermostats()}>Load</button>
                <button
                    className="btn btn-danger mr-2 pull-right"
                    onClick={() => deleteThermostats()}>Delete</button>
            </div>
            <h3 className="font-weight-light mb-3">
                <svg width="1em" height="1em" viewBox="0 0 16 16" className="bi bi-plus-circle mt-n2 mr-1" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                    <path fillRule="evenodd" d="M8 3.5a.5.5 0 0 1 .5.5v4a.5.5 0 0 1-.5.5H4a.5.5 0 0 1 0-1h3.5V4a.5.5 0 0 1 .5-.5z" />
                    <path fillRule="evenodd" d="M7.5 8a.5.5 0 0 1 .5-.5h4a.5.5 0 0 1 0 1H8.5V12a.5.5 0 0 1-1 0V8z" />
                    <path fillRule="evenodd" d="M8 15A7 7 0 1 0 8 1a7 7 0 0 0 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                </svg><b>{LegacyThermostatType[props.thermostatType]}</b> <span className="text-muted">Load Wizard</span></h3>
            <hr />

            {search().length > 0 ?
                list
                :
                <p className="text-center">There are no saved {LegacyThermostatType[props.thermostatType]} thermostats in the current workspace.</p>
            }
        </div>
    );
}