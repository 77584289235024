import React, { useState } from "react";
import { FaSearch } from "react-icons/fa";

export interface SearchFieldProps {
    display: string;
    search: (query: string) => void;
}

export const SearchField = (props: SearchFieldProps) => {
    const [query, setQuery] = useState<string>("");

    return (
        <div className="d-flex w-75">
            {/* Search field */}
            <input
                type="text"
                name="query"
                value={query}
                onChange={(e) => setQuery(e.target.value)}
                className="form-control"
                placeholder={props.display} />

            <div
                onClick={() => props.search(query)}>
                {/* Search button */}
                <button
                    id="search-btn"
                    className="btn btn-primary">
                    <FaSearch className="mt-n1" />
                </button>
            </div>
        </div>
    );
};