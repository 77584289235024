import * as React from "react";
import { connect } from "react-redux";
import { ApplicationState } from "../store";
import { LegacyThermostatType } from "../store/LegacyStore/models";
import { LogLevel } from "../store/LogStore/models";
import { Simulators } from "../store/WorkspaceStore/models";
import { LogBoxColourDot } from "./ColourDot";
import { orderLogEntries } from "../Utility";

const LogBox = (props: ApplicationState) => {
    // Component state
    const [visibleSerialNumbers, setVisibleSerialNumbers] = React.useState(new Array<string>());
    const simulatorKey = Simulators[props.workspace.simulator] as keyof typeof LegacyThermostatType;

    // Get thermostats in the current workspace
    const getFocusedThermostats = (): { serialNumber: string, name: string }[] => {
        if (props.workspace.simulator === Simulators.SimpleWifi) {
            return props.simpleWifi.thermostats
                .filter(t => t.workspaceID === props.workspace.focused.id)
                .map(t => ({ serialNumber: String(t.deviceInfo.serialId), name: t.deviceInfo.thermostatName }));
        }

        if (props.workspace.simulator === Simulators.UWG5Nvent) {
            return props.uwg5Nvent.thermostats
                .filter(t => t.workspaceId === props.workspace.focused.id)
                .map(t => ({ serialNumber: String(t.deviceInfo.serialId), name: t.deviceInfo.thermostatName }));
        }

        const simulatorName: string = Simulators[props.workspace.simulator];
        const legacyType: LegacyThermostatType = LegacyThermostatType[simulatorName as keyof typeof LegacyThermostatType];
        const legacyKey = LegacyThermostatType[legacyType] as keyof typeof LegacyThermostatType;

        return props.legacy[legacyKey].thermostats
            .filter(t => t.workspaceID === props.workspace.focused.id)
            .map(t => ({ serialNumber: t.internalStorage.serialNumber, name: t.internalStorage.name }));
    };

    /**
     * Toggle visibility of logs with the provided serial number.
     */
    const toggleThermostat = (serialNumber: string) => {
        if (visibleSerialNumbers.includes(serialNumber)) {
            setVisibleSerialNumbers([...visibleSerialNumbers.filter(s => s !== serialNumber)]);
        } else {
            setVisibleSerialNumbers([...visibleSerialNumbers, serialNumber]);
        }
    };

    const getLogLevelColor = (loglevel: LogLevel) => {
        switch (loglevel) {
            case LogLevel.Critical:
                return "#ff4758";
            case LogLevel.Error:
                return "#ffbdc3";
            case LogLevel.Warning:
                return "#ffdf81";
            case LogLevel.Info:
                return "#a9d3ff";
            case LogLevel.Debug:
            default:
                return "#e3e3e3";
        }
    };

    /**
     * Get thermostats that has atleast 1 log entry.
     */
    const getThermostatsWithLogs = () => {
        return getFocusedThermostats()
            .filter(s => !!props.logs[simulatorKey].find(l => l.thermostatSerialNumber === s.serialNumber));
    };

    /**
     * Get logs that should be displayed.
     */
    const getLogsToDisplay = () => {
        // limiting log rows to display to avoid browser freeze
        // there is a limit of last thermostats and events per thermostat in reducer
        // but also truncate amount of DOM elements visible on page
        const displayLimit = 300;

        return props.logs[simulatorKey]
            .filter(l => visibleSerialNumbers.includes(l.thermostatSerialNumber)
                && getFocusedThermostats().map(t => t.serialNumber).includes(l.thermostatSerialNumber))
            .sort(orderLogEntries)
            .slice(0, displayLimit);
    };

    // Render if there are 0 logs
    if (getThermostatsWithLogs().length === 0) {
        return <p className="text-center">There are no logs yet in your selected workspace<span role="img" aria-label="smiley">😊</span></p>;
    }

    // Render if there are logs
    return (
        <div className="bg-white rounded-lg shadow-sm border p-3">
            <h4>Logs</h4>
            <table className="table table-sm mb-4">
                <thead>
                    <tr>
                        <td>
                            <b>Show</b>
                        </td>
                        <td>
                            <b>Thermostat</b>
                        </td>
                    </tr>
                </thead>
                <tbody>
                    {getThermostatsWithLogs().map(thermostat =>
                        <tr className="" key={thermostat.serialNumber}>
                            <td style={{ width: "5.5em" }}>
                                <input type="checkbox" checked={visibleSerialNumbers.indexOf(thermostat.serialNumber) >= 0} onChange={() => toggleThermostat(thermostat.serialNumber)} />
                            </td>
                            <td>
                                {LogBoxColourDot(thermostat.serialNumber)}
                                <b>{thermostat.serialNumber}</b>&nbsp;{thermostat.name}
                            </td>
                        </tr>
                    )}
                </tbody>
            </table>
            <table className="table table-sm mb-0">
                <thead>
                    <tr>
                        <td><b>Timestamp</b></td>
                        <td><b>Thermostat</b></td>
                        <td><b>Message</b></td>
                        <td><b>Event Data</b></td>
                    </tr>
                </thead>
                <tbody>
                    {getLogsToDisplay().map((t, index) =>
                        <tr key={index}>
                            <td style={{ width: "11em" }} className="align-middle">
                                <span style={{ background: getLogLevelColor(t.logLevel) }} className="badge text-dark p-2">
                                    {t.timestamp.toLocaleString()}
                                </span>
                            </td>
                            <td style={{ width: "11em" }} className="align-middle">
                                {LogBoxColourDot(t.thermostatSerialNumber)}
                                {t.thermostatName}
                            </td>
                            <td className="align-middle">
                                {t.logMessage}
                            </td>
                            <td className="align-middle">
                                {t.eventData}
                            </td>
                        </tr>
                    )}
                </tbody>
            </table>
        </div>
    );
};

export default connect(
    (state: ApplicationState) => state
)(LogBox as any);
