import { Store } from "redux";
import { Simulators } from "../WorkspaceStore/models";
import {
    ClearLogs,
    ReceiveAllTypeLogs,
    ReceiveNewTypeLogs
} from "./actionTypes";
import { LogEntry } from "./models";
import { buildHub } from "../../ApiGateway";
import { HubConnectionState } from "@microsoft/signalr";

// Create SimpleWifi hub
export const logHub = buildHub("LogHub");

export function connectToLogHub(store: Store) {
    if (logHub.state !== HubConnectionState.Disconnected) {
        return;
    }

    function convertDateTime(timestamp: any): Date {
        if (typeof timestamp === "string") {
            return new Date(timestamp);
        }

        return timestamp;
    }

    logHub.start()
        .catch(err => console.error("Failed to start LogHub", err));

    logHub.on("AddNewTypeLogs", (logEntries: LogEntry[], simulator: Simulators) => {
        logEntries.forEach(e => e.timestamp = convertDateTime(e.timestamp));
        store.dispatch({ type: "RECEIVE_NEW_TYPE_LOGS", logEntries, simulator } as ReceiveNewTypeLogs);
    });

    logHub.on("ReceiveAllTypeLogs", (logEntries: LogEntry[], simulator: Simulators) => {
        logEntries.forEach(e => e.timestamp = convertDateTime(e.timestamp));
        store.dispatch({ type: "RECEIVE_ALL_TYPE_LOGS", logEntries, simulator } as ReceiveAllTypeLogs);
    });

    logHub.on("ClearLogs", (thermostatSerialNumbers: string[], simulator: Simulators) => {
        store.dispatch({ type: "CLEAR_LOGS", thermostatSerialNumbers, simulator } as ClearLogs);
    });
}
