import { Simulators, Workspace, Workspaces } from "./store/WorkspaceStore/models";

export const LocalStorage = (() => {
    const workspaceKey = "simulator:workspace";

    const getWorkspace = (): Workspaces => {
        try {
            return JSON.parse(localStorage.getItem(workspaceKey) || "") as Workspaces;
        } catch (error) {
            return {
                simulator: Simulators.SimpleWifi,
                categories: [],
                focused: {
                    id: 1,
                    name: "Common",
                    categoryID: 1,
                    author: ""
                } as Workspace
            };
        }
    };

    const setWorkspace = (workspace: Workspaces) => {
        localStorage.setItem(workspaceKey, JSON.stringify(workspace));
    };

    return {
        getWorkspace,
        setWorkspace
    };
})();
