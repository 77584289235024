import { AppThunkAction } from "../..";
import { KnownAction } from "../actionTypes";
import { HubConnection } from "@microsoft/signalr";

export interface OWD5ActionCreator {
    setComfortMode: (id: string, days: number, hours: number, setpoint: number) => AppThunkAction<KnownAction>;
    setVacationMode: (id: string, days: number, hours: number, setpoint: number) => AppThunkAction<KnownAction>;
    setBoostMode: (id: string, days: number, hours: number) => AppThunkAction<KnownAction>;
    setEcoMode: (id: string) => AppThunkAction<KnownAction>;
    setFrostProtectionMode: (id: string, setpoint: number) => AppThunkAction<KnownAction>;
}

export function owd5ActionCreator(connection: HubConnection) {
    return {
        setComfortMode: (id: string, days: number, hours: number, setpoint: number): AppThunkAction<KnownAction> => (_dispatch, _getState) => {
            connection.send("SetComfortMode", id, days, hours, setpoint).catch(alert);
        },
        setVacationMode: (id: string, days: number, hours: number, setpoint: number): AppThunkAction<KnownAction> => (_dispatch, _getState) => {
            connection.send("SetVacationMode", id, days, hours, setpoint).catch(alert);
        },
        setBoostMode: (id: string, days: number, hours: number): AppThunkAction<KnownAction> => (_dispatch, _getState) => {
            connection.send("SetBoostMode", id, days, hours).catch(alert);
        },
        setEcoMode: (id: string): AppThunkAction<KnownAction> => (_dispatch, _getState) => {
            connection.send("SetEcoMode", id).catch(alert);
        },
        setFrostProtectionMode: (id: string, setpoint: number): AppThunkAction<KnownAction> => (_dispatch, _getState) => {
            connection.send("SetFrostProtectionMode", id, setpoint).catch(alert);
        }
    };
}