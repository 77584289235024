import * as React from "react";
import { connect, useDispatch } from "react-redux";
import { RouteComponentProps } from "react-router";
import { AlertType } from "../../store/AlertStore/models";
import { ApplicationState } from "../../store";
import { actionCreator } from "../../store/UWG5NventStore/actions";
import { CreateUWG5NventRequest, DistributorListItem, TlsVersion, TlsVersionsLookup, UserAccountItem } from "../../store/UWG5NventStore/models";
import { popupAlert } from "../../Utility";
import { NumberInput } from "../Common/NumberInput";
import { DistributorSearchField } from "./CreateWizard/DistributorSearchField";
import { UserSearchField } from "./CreateWizard/UserSearchField";
import { requiresAuthentication } from "../Common";

type Props =
    ApplicationState &
    RouteComponentProps &
    typeof actionCreator;

const UWG5NventCreateWizard = (props: Props) => {
    // Component state
    const [showCheckmark, setShowCheckmark] = React.useState(false);
    const [redirectToHome, setRedirectToHome] = React.useState(true);
    const [useRandomSerialNumber, setUseRandomSerialNumber] = React.useState(true);
    const [name, setName] = React.useState("");
    const [otpCode, setOtpCode] = React.useState("");
    const [accessCode, setAccessCode] = React.useState("");
    const [serialNumber, setSerialNumber] = React.useState("");
    const [useSsl, setUseSsl] = React.useState<boolean | null>(null);
    const [minTlsVersion, setMinTlsVersion] = React.useState(TlsVersion.Default);
    const [maxTlsVersion, setMaxTlsVersion] = React.useState(TlsVersion.Default);
    const [serverUrl, setServerUrl] = React.useState("");
    const [distributor, setDistributor] = React.useState<DistributorListItem | null>(null);
    const [user, setUser] = React.useState<UserAccountItem | null>(null);
    const dispatch = useDispatch();

    /**
     * Handle created new simulated thermostat(s).
     */
    const onCreated = () => {
        popupAlert(dispatch, "Thermostats created!", "You have successfully created the thermostats", AlertType.SUCCESS);

        if (redirectToHome) {
            props.history.push("/");
        } else {
            setName("");
            setOtpCode("");
            setAccessCode("");
            setSerialNumber("");
            setShowCheckmark(true);

            setTimeout(() => {
                setShowCheckmark(false);
            }, 3000);
        }
    };

    /**
     * Map component state to thermostat settings.
     */
    const createRequest = (): CreateUWG5NventRequest => ({
        name: name,
        otpCode: otpCode,
        accessCode: accessCode,
        serialId: useRandomSerialNumber ? null : parseInt(serialNumber),
        serverUrl: serverUrl,
        useSsl: useSsl,
        minTlsVersion: minTlsVersion,
        maxTlsVersion: maxTlsVersion,
        userId: user ? user.id : "00000000-0000-0000-0000-000000000000",
        distributorId: distributor ? distributor.distributorId : null
    });

    /**
     * Create one simulated thermostat.
     */
    const createOne = () => {
        props.createNew(props.workspace.focused.id, createRequest());
        onCreated();
    };

    /**
     * Create multiple simulated thermostats.
     */
    const createMultiple = () => {
        const maximum = 100;
        const amountToCreateStr = prompt(`How many thermostats do you want to create? (Max ${maximum})`);

        if (amountToCreateStr === null) {
            return;
        }

        const amountToCreate = parseInt(amountToCreateStr);

        if (isNaN(amountToCreate) || amountToCreate < 1 || amountToCreate > maximum) {
            popupAlert(dispatch, "Creation failed!", `Value has to be a number (1-${maximum})!`, AlertType.DANGER);
            createMultiple();
        } else {
            props.createNew(props.workspace.focused.id, createRequest(), amountToCreate);
            onCreated();
        }
    };

    const createMultipleFromExisting = () => {
        const userEmail = prompt("User email");

        if (userEmail === null) {
            return;
        }

        const pageNumber = prompt("Result page number(100  items per request)");

        if (pageNumber === null || Number(pageNumber) < 1) {
            return;
        }

        props.addExisting(props.workspace.focused.id, userEmail, Number(pageNumber));
        onCreated();
    };

    const useSslCheckboxRef = React.createRef<HTMLInputElement>();

    React.useEffect(() => {
        const cb = useSslCheckboxRef.current;
        if (cb === null) {
            return;
        }

        if (useSsl === true) {
            cb.checked = true;
            cb.indeterminate = false;
        } else if (useSsl === false) {
            cb.checked = false;
            cb.indeterminate = false;
        } else if (useSsl === null) {
            cb.checked = false;
            cb.indeterminate = true;
        }
    }, [useSsl]);

    const handleUseSslChange = function (e: React.ChangeEvent<HTMLInputElement>) {
        if (e.target.indeterminate) {
            setUseSsl(null);
        } else {
            setUseSsl(e.target.checked);
        }
    };

    // Render
    return (
        <div className="bg-white rounded-lg shadow p-3">
            <h3 className="font-weight-light mb-3">
                <svg width="1em" height="1em" viewBox="0 0 16 16" className="bi bi-plus-circle mt-n2 mr-1" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                    <path fillRule="evenodd" d="M8 3.5a.5.5 0 0 1 .5.5v4a.5.5 0 0 1-.5.5H4a.5.5 0 0 1 0-1h3.5V4a.5.5 0 0 1 .5-.5z" />
                    <path fillRule="evenodd" d="M7.5 8a.5.5 0 0 1 .5-.5h4a.5.5 0 0 1 0 1H8.5V12a.5.5 0 0 1-1 0V8z" />
                    <path fillRule="evenodd" d="M8 15A7 7 0 1 0 8 1a7 7 0 0 0 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                </svg>
                <b>UWG5 nVent</b> <span className="text-muted">Create Wizard</span></h3>
            <hr />

            <div className="form-group row">
                <label className="col-md-2 col-form-label">Serial Number</label>
                <div className="col-md-4 mt-2">
                    <input
                        checked={useRandomSerialNumber}
                        onChange={() => setUseRandomSerialNumber(!useRandomSerialNumber)} type="checkbox"
                        className="form-check-input ml-2" />
                    <label className="form-check-label ml-4">Auto-generate</label>
                </div>
                <div className="col-md-6">
                    <NumberInput
                        value={Number(serialNumber)}
                        minValue={0}
                        maxValue={4294967295 /* maximum value of uint32 */}
                        onChange={newValue => setSerialNumber(String(newValue))}
                        disabled={useRandomSerialNumber}
                    />
                </div>
            </div>
            <div className="form-group row">
                <label className="col-md-2 col-form-label">Distributor Id</label>
                <div className="col-md-10">
                    <DistributorSearchField
                        distributors={props.uwg5Nvent.distributors}
                        selectedDistributor={distributor}
                        setSelectedDistributor={(distributor) => setDistributor(distributor)}
                        searchDistributors={(query) => props.searchDistributors(query)} />
                </div>
            </div>
            <div className="form-group row">
                <label className="col-md-2 col-form-label">User Id</label>
                <div className="col-md-10">
                    <UserSearchField
                        users={props.uwg5Nvent.users}
                        selectedUser={user}
                        setSelectedUser={(user) => setUser(user)}
                        searchUsers={(query) => props.searchUsers(query)} />
                </div>
            </div>
            <div className="form-group row">
                <label className="col-md-2 col-form-label">Server Url</label>
                <div className="col-md-10">
                    <input className="form-control" value={serverUrl} onChange={(e) => setServerUrl(e.target.value)} />
                </div>
            </div>
            <div className="form-group row">
                <label className="col-md-2 col-form-label">SSL</label>
                <div className="col-md-2 mt-2">
                    <input
                        type="checkbox"
                        ref={useSslCheckboxRef}
                        className="form-check-input ml-2"
                        checked={useSsl === true}
                        onChange={handleUseSslChange} />
                    <label className="form-check-label ml-4">Use SSL</label>
                </div>
                <div className="col-md-4">
                    <label className="form-check-label ml-4">Min Version</label>
                    <select
                        value={minTlsVersion}
                        onChange={e => setMinTlsVersion(parseInt(e.target.value) as TlsVersion)}
                        defaultValue={minTlsVersion}>
                        {TlsVersionsLookup.map(tls => (
                            <option key={tls.value} value={tls.value} disabled={maxTlsVersion < tls.value}>
                                {tls.name}
                            </option>
                        ))}
                    </select>
                </div>
                <div className="col-md-4">
                    <label className="form-check-label ml-4">Max Version</label>
                    <select
                        value={maxTlsVersion}
                        onChange={e => setMaxTlsVersion(parseInt(e.target.value) as TlsVersion)}
                        defaultValue={minTlsVersion}>
                        {TlsVersionsLookup.map(tls => (
                            <option key={tls.value} value={tls.value} disabled={minTlsVersion > tls.value}>
                                {tls.name}
                            </option>
                        ))}
                    </select>
                </div>
            </div>
            <div className="form-group row">
                <label className="col-md-2 col-form-label">Name</label>
                <div className="col-md-10">
                    <input className="form-control" value={name} onChange={(e) => setName(e.target.value)} />
                </div>
            </div>
            <div className="form-group row">
                <label className="col-md-2 col-form-label">OTP Code</label>
                <div className="col-md-10">
                    <input className="form-control" value={otpCode} onChange={(e) => setOtpCode(e.target.value)} />
                </div>
            </div>
            <div className="form-group row">
                <label className="col-md-2 col-form-label">Access Code</label>
                <div className="col-md-10">
                    <input className="form-control" value={accessCode} onChange={(e) => setAccessCode(e.target.value)} />
                </div>
            </div>

            <hr />

            <button
                type="button"
                className="btn btn-primary mr-2"
                onClick={() => createOne()}>Create one</button>
            <button
                disabled={!useRandomSerialNumber}
                type="button"
                className="btn btn-primary mr-2"
                onClick={() => createMultiple()}>
                Create multiple
            </button>
            <small hidden={useRandomSerialNumber} className="text-muted">Auto-generate serial number to enable</small>
            <div style={{ height: "10px" }} />
            <button type="button" className="btn btn-primary mr-2" onClick={() => createMultipleFromExisting()}>
                Create multiple from existing
            </button>
            <span hidden={!showCheckmark} className="text-success mr-2">
                <svg width="1.5em" height="1.5em" viewBox="0 0 16 16" className="bi bi-check-circle mr-2" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                    <path fillRule="evenodd" d="M8 15A7 7 0 1 0 8 1a7 7 0 0 0 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                    <path fillRule="evenodd" d="M10.97 4.97a.75.75 0 0 1 1.071 1.05l-3.992 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425a.236.236 0 0 1 .02-.022z" />
                </svg>
                Success!
            </span>
            <p className="mt-2 mb-0">
                <input type="checkbox" checked={redirectToHome} onChange={() => setRedirectToHome(!redirectToHome)} />
                Redirect to home
            </p>
        </div>
    );
};

export default requiresAuthentication(connect(
    (state: ApplicationState) => state,
    actionCreator
)(UWG5NventCreateWizard as any));
