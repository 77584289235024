import { Action, Reducer } from "redux";
import { UWG5NventThermostat, UWG5NventThermostats } from "./models";
import { KnownAction } from "./actionTypes";
import { base64ToByteArray } from "../../Utility";

const unloadedThermostats: UWG5NventThermostats = {
    thermostats: [],
    loadable: [],
    distributors: [],
    users: []
};

const mapThermostat = (thermostat: UWG5NventThermostat): UWG5NventThermostat => {
    return {
        ...thermostat,
        deviceInfo: {
            ...thermostat.deviceInfo,
            mcuUuid: base64ToByteArray(thermostat.deviceInfo.mcuUuid as unknown as string),
            protocolVersion: base64ToByteArray(thermostat.deviceInfo.protocolVersion as unknown as string)
        }
    };
};

const mapThermostats = (thermostats: UWG5NventThermostat[]): UWG5NventThermostat[] => {
    return thermostats.map(mapThermostat);
};

export const uwg5NventReducer: Reducer<UWG5NventThermostats> = (state: UWG5NventThermostats | undefined, incomingAction: Action): UWG5NventThermostats => {
    if (state === undefined) {
        return unloadedThermostats;
    }

    const action = incomingAction as KnownAction;
    switch (action.type) {
        case "RECEIVE_ALL_UWG5_NVENT_THERMOSTATS":
            return { ...state, thermostats: mapThermostats(action.thermostats) };
        case "RECEIVE_NEW_UWG5_NVENT_THERMOSTAT":
            return { ...state, thermostats: [...state.thermostats, mapThermostat(action.thermostat)] };
        case "UPDATE_UWG5_NVENT_THERMOSTAT_STATE":
            return { ...state, thermostats: state.thermostats.map(thermostat => (thermostat.thermostatId === action.thermostat.thermostatId ? mapThermostat(action.thermostat) : thermostat)) };
        case "REMOVE_UWG5_NVENT_THERMOSTAT":
            return { ...state, thermostats: state.thermostats.filter(thermostat => thermostat.thermostatId !== action.id) };
        case "RECEIVE_LOADABLE_UWG5_NVENT_THERMOSTATS":
            return { ...state, loadable: action.thermostats };
        case "ADD_LOADABLE_UWG5_NVENT_THERMOSTAT":
            return { ...state, loadable: [...state.loadable, action.thermostat] };
        case "REMOVE_LOADABLE_UWG5_NVENT_THERMOSTAT":
            return { ...state, loadable: state.loadable.filter(thermostat => thermostat.thermostatId !== action.id) };
        case "UPDATE_LOADABLE_UWG5_NVENT_THERMOSTAT":
            return { ...state, loadable: state.loadable.map(thermostat => thermostat.thermostatId === action.thermostat.thermostatId ? action.thermostat : thermostat) };
        case "RECEIVE_DISTRIBUTOR_SEARCH_RESULT":
            return { ...state, distributors: action.distributors };
        case "RECEIVE_USER_SEARCH_RESULT":
            return { ...state, users: action.users };
        default:
            return state;
    }
};

