import React, { useState } from "react";
import { UserAccountItem } from "../../../store/SimpleWifiStore/models";
import { Dropdown } from "./Dropdown";
import { SearchField } from "./SearchField";

export interface UserSearchFieldProps {
    users: UserAccountItem[],
    selectedUser: UserAccountItem | null;
    setSelectedUser: (user: UserAccountItem | null) => void;
    searchUsers: (query: string) => void;
}

export const UserSearchField = (props: UserSearchFieldProps) => {
    const [show, setShow] = useState<boolean>(false);

    const toggle = () => {
        setShow(!show);
    };

    const select = (user: UserAccountItem) => {
        props.setSelectedUser(user);
        setShow(!show);
    };

    return (
        <Dropdown
            display={props.selectedUser !== null
                ? `${props.selectedUser.email}`
                : "Select a user"}
            show={show}
            toggleDropdown={() => toggle()}>

            <div className="d-flex flex-column w-100 align-items-center">
                <SearchField display="Search Users" search={(query) => props.searchUsers(query)} />

                <div className="d-flex w-100 justify-content-center p-3">
                    <table className="table table-sm table-hover">
                        <thead>
                            <tr>
                                <th scope="col">Email</th>
                            </tr>
                        </thead>
                        <tbody>
                            {props.users.map(u =>
                                <tr role="button" onClick={() => select(u)}>
                                    <td className="text-truncate" style={{ maxWidth: "75px" }}>{u.email}</td>
                                </tr>
                            )}
                        </tbody>
                    </table>
                </div>
            </div>
        </Dropdown>
    );
};

