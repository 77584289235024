import React, { FC, useState, useEffect } from "react";
import { TableRowInput } from "../Common";
import { FloorSensorSettings as Model } from "../../store/UWG5NventStore/models";

export interface FloorSensorSettingsProps {
    floorSensorSettings: Model,
    saveFloorSensorSettings: (newFloorSensorSettings: Model) => void
}

export const FloorSensorSettings: FC<FloorSensorSettingsProps> = (props) => {
    const [floorSensorSettings, setFloorSensorSettings] = useState(props.floorSensorSettings);
    const save = () => props.saveFloorSensorSettings(floorSensorSettings);

    useEffect(() => {
        setFloorSensorSettings(props.floorSensorSettings);
    }, [props.floorSensorSettings]);

    return (
        <table className="table table-sm mb-1">
            <tbody>
                <TableRowInput
                    name="Value 15 deg"
                    currentValue={floorSensorSettings.value15Deg}
                    originalValue={props.floorSensorSettings.value15Deg}
                    inputType="number"
                    onSave={save}
                    onChange={newValue => setFloorSensorSettings({ ...floorSensorSettings, value15Deg: Number(newValue) })} />

                <TableRowInput
                    name="Value 20 deg"
                    currentValue={floorSensorSettings.value20Deg}
                    originalValue={props.floorSensorSettings.value20Deg}
                    inputType="number"
                    onSave={save}
                    onChange={newValue => setFloorSensorSettings({ ...floorSensorSettings, value20Deg: Number(newValue) })} />

                <TableRowInput
                    name="Value 25 deg"
                    currentValue={floorSensorSettings.value25Deg}
                    originalValue={props.floorSensorSettings.value25Deg}
                    inputType="number"
                    onSave={save}
                    onChange={newValue => setFloorSensorSettings({ ...floorSensorSettings, value25Deg: Number(newValue) })} />

                <TableRowInput
                    name="Value 30 deg"
                    currentValue={floorSensorSettings.value30Deg}
                    originalValue={props.floorSensorSettings.value30Deg}
                    inputType="number"
                    onSave={save}
                    onChange={newValue => setFloorSensorSettings({ ...floorSensorSettings, value30Deg: Number(newValue) })} />
            </tbody>
        </table>
    );
};
