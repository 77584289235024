import * as React from "react";
import { connect, useDispatch } from "react-redux";
import { Errors, SimpleWifiThermostat } from "../../store/SimpleWifiStore/models";
import { ApplicationState } from "../../store";
import { actionCreator, SimpleWifiAction } from "../../store/SimpleWifiStore/actions";
import { SimpleWifiSwimlane } from "./SimpleWifiSwimlane";
import { AlertType } from "../../store/AlertStore/models";
import { normalizeString, popupAlert } from "../../Utility";

type Props =
    ApplicationState &
    typeof actionCreator;

const SimpleWifiList = (props: Props) => {
    // Component state
    const [searchText, setSearchText] = React.useState("");
    const dispatch = useDispatch();

    /**
     * Search for thermostats matchnig the search text.
     */
    const thermostats = (() => {
        const normalizedSearchText = normalizeString(searchText);

        if (normalizedSearchText === "") {
            return props.simpleWifi.thermostats
                .filter(thermostat => thermostat.workspaceID === props.workspace.focused.id);
        }

        return props.simpleWifi.thermostats
            .filter(thermostat => thermostat.workspaceID === props.workspace.focused.id)
            .filter(thermostat => normalizeString(thermostat.deviceInfo.thermostatName).includes(normalizedSearchText));
    })();

    const isThermostatLinked = (thermostat: SimpleWifiThermostat) => {
        const hasAccessCode: boolean = !!thermostat.settings.accessCode;
        const hasOtpCode: boolean = !!thermostat.settings.otpCode;

        return hasAccessCode || hasOtpCode;
    };

    /**
     * Links all unlinked thermostats in the selected workspace.
     */
    const linkAll = () => {
        popupAlert(dispatch, "Linking thermostats!", "Attempting to link all thermostats.", AlertType.SUCCESS);

        props.simpleWifi.thermostats
            .filter(t => t.workspaceID === props.workspace.focused.id && !isThermostatLinked(t))
            .forEach(t => props.invoke(t.thermostatID, SimpleWifiAction.Link));
    };

    /**
     * Disconnects all connected thermostats in the selected workspace.
     */
    const unlinkAll = () => {
        popupAlert(dispatch, "Unlinking thermostats!", "Attempting to unlink all thermostats.", AlertType.SUCCESS);

        props.simpleWifi.thermostats
            .filter(t => t.workspaceID === props.workspace.focused.id)
            .forEach(t => props.invoke(t.thermostatID, SimpleWifiAction.Unlink));
    };

    /**
     * Connects all disconnected thermostats in the selected workspace.
     */
    const connectAll = () => {
        popupAlert(dispatch, "Connecting thermostats!", "Attempting to connect all thermostats.", AlertType.SUCCESS);

        props.simpleWifi.thermostats
            .filter(t => t.workspaceID === props.workspace.focused.id && !t.isConnected)
            .forEach(t => props.invoke(t.thermostatID, SimpleWifiAction.Connect));
    };

    /**
     * Disconnects all connected thermostats in the selected workspace.
     */
    const disconnectAll = () => {
        popupAlert(dispatch, "Disconnecting thermostats!", "Attempting to disconnect all thermostats.", AlertType.SUCCESS);

        props.simpleWifi.thermostats
            .filter(t => t.workspaceID === props.workspace.focused.id && t.isConnected)
            .forEach(t => props.invoke(t.thermostatID, SimpleWifiAction.Disconnect));
    };

    /**
     * Remove all thermostats in the selected workspace.
     */
    const removeAll = () => {
        if (window.confirm("Do you wish to delete all SimpleWifi thermostats?")) {
            props.simpleWifi.thermostats
                .filter(t => t.workspaceID === props.workspace.focused.id)
                .forEach(t => props.invoke(t.thermostatID, SimpleWifiAction.Remove));

            popupAlert(dispatch, "Thermostats removed!", "You have successfully removed all the thermostats.", AlertType.SUCCESS);
        }
    };

    /**
     * Saves all thermostats to database in the selected workspace.
     */
    const saveAll = () => {
        props.simpleWifi.thermostats
            .filter(t => t.workspaceID === props.workspace.focused.id)
            .forEach(t => props.invoke(t.thermostatID, SimpleWifiAction.Save));

        popupAlert(dispatch, "Thermostats saved!", "You have successfully saved all the thermostats.", AlertType.SUCCESS);
    };

    // Render
    return (
        <>
            {/*Search Bar*/}
            <div className="row">
                <label className="col-3">Search by thermostat name:</label>
                <input
                    type="text"
                    autoFocus
                    className="form-control form-control-sm col-3"
                    style={{ outline: "none" }}
                    value={searchText}
                    onChange={(e) => setSearchText(e.target.value)} />
            </div>

            {/* SimpleWifi thermostats */}
            <div>
                <p className="my-2">
                    <span className="badge badge-primary badge-pill align-middle mt-n1">{thermostats.length}</span> total,&nbsp;
                    <span className="badge badge-success badge-pill align-middle mt-n1">{thermostats.filter(t => t.isConnected).length}</span> connected,&nbsp;
                    <span className="badge badge-danger badge-pill align-middle mt-n1">{thermostats.filter(t => !t.isConnected).length}</span> disconnected,&nbsp;
                    <span className="badge badge-secondary badge-pill align-middle mt-n1">{thermostats.filter(t => !!t.settings.otpCode).length}</span> otp,&nbsp;
                    <span className="badge badge-secondary badge-pill align-middle mt-n1">{thermostats.filter(t => !!t.settings.accessCode).length}</span> access code
                </p>
                <div className="row">
                    <div className="col-auto ml-2">
                        <div className="row">
                            <button className="btn btn-sm btn-link text-primary mt-n1" onClick={() => linkAll()}>Link all</button>
                        </div>
                        <div className="row">
                            <button className="btn btn-sm btn-link text-danger mt-n1" onClick={() => unlinkAll()}>Unlink all</button>
                        </div>
                    </div>

                    <div className="col-auto border-left">
                        <div className="row">
                            <button className="btn btn-sm btn-link text-primary mt-n1" onClick={() => connectAll()}>Connect all</button>
                        </div>
                        <div className="row">
                            <button className="btn btn-sm btn-link text-danger mt-n1" onClick={() => disconnectAll()}>Disconnect all</button>
                        </div>
                    </div>

                    <div className="col-auto border-left">
                        <div className="row">
                            <button className="btn btn-sm btn-link text-primary mt-n1" onClick={() => saveAll()}>Save all</button>
                        </div>
                        <div className="row">
                            <button className="btn btn-sm btn-link text-danger mt-n1" onClick={() => removeAll()}>Remove all</button>
                        </div>
                    </div>
                </div>
            </div>
            {thermostats.length > 0 ?
                <div>
                    {thermostats.map(thermostat =>
                        <SimpleWifiSwimlane
                            key={thermostat.thermostatID}
                            thermostat={thermostat}
                            invokeAction={(action: SimpleWifiAction) => props.invoke(thermostat.thermostatID, action)}
                            setSetpoint={(setpoint) => props.setSetpoint(thermostat.thermostatID, setpoint)}
                            setAutoMode={() => props.setAutoMode(thermostat.thermostatID)}
                            setManualMode={(setpoint) => props.setManualMode(thermostat.thermostatID, setpoint)}
                            setHoldMode={(setpoint, endTime, isPermanent) => props.setHoldMode(thermostat.thermostatID, setpoint, endTime, isPermanent)}
                            cancelHoldMode={() => props.cancelHoldMode(thermostat.thermostatID)}
                            updateSettings={(settings) => props.updateSettings(thermostat.thermostatID, settings)}
                            updateDeviceInfo={deviceInfo => props.updateDeviceInfo(thermostat.thermostatID, deviceInfo)}
                            updateDisplaySettings={(settings) => props.updateDisplaySettings(thermostat.thermostatID, settings)}
                            updateRegulationSettings={(settings) => props.updateRegulationSettings(thermostat.thermostatID, settings)}
                            updateHeatingSettings={(settings) => props.updateHeatingSettings(thermostat.thermostatID, settings)}
                            updateFloorSensorSettings={(settings) => props.updateFloorSensorSettings(thermostat.thermostatID, settings)}
                            updateAwayModeSettings={(settings) => props.updateAwayModeSettings(thermostat.thermostatID, settings)}
                            updateDateTimeSettings={(settings) => props.updateDateTimeSettings(thermostat.thermostatID, settings)}
                            updateWifiSettings={(settings) => props.updateWifiSettings(thermostat.thermostatID, settings)}
                            updateWarrantyInfo={(info) => props.updateWarrantyInfo(thermostat.thermostatID, info)}
                            setErrors={(errors: Errors) => props.setErrors(thermostat.thermostatID, errors)}
                            setStandbyState={(isInStandby: boolean) => props.setStandbyState(thermostat.thermostatID, isInStandby)}
                            setCurrentScheduleEvent={(day, eventId) => props.setCurrentScheduleEvent(thermostat.thermostatID, day, eventId)}
                        />
                    )}
                </div>
                :
                <p className="text-center">There are no UWG5 thermostats in the selected workspace! Try a different workspace.</p>
            }
        </>
    );
};

export default connect(
    (state: ApplicationState) => state,
    actionCreator
)(SimpleWifiList as any);
