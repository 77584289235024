import React, { FC, useState, useEffect } from "react";
import { TableRowInput, TableRowDropdown } from "../Common";
import { WifiSettings as Model, Authentication } from "../../store/UWG5NventStore/models";

export interface WifiSettingsProps {
    wifiSettings: Model,
    saveWifiSettings: (newWifiSettings: Model) => void
}

function encodedBytesToText(encoded: string): string {
    return Array.from(atob(encoded), char => char.charCodeAt(0)).join(".");
}

function textToEncodedBytes(text: string): string {
    return btoa(text.split(".").map(Number).map(byte => String.fromCharCode(byte)).join(""));
}

function encodedBytesHexToText(encoded: string): string {
    return Array.from(atob(encoded), char => char.charCodeAt(0).toString(16).padStart(2, "0")).join(".");
}

function textToEncodedBytesHex(text: string): string {
    return btoa(text.split(".").map(x => Number(`0x${x}`)).map(byte => String.fromCharCode(byte)).join(""));
}

export const WifiSettings: FC<WifiSettingsProps> = (props) => {
    const [wifiSettings, setWifiSettings] = useState(props.wifiSettings);
    const save = () => props.saveWifiSettings(wifiSettings);
    useEffect(() => {
        setWifiSettings(props.wifiSettings);
    }, [props.wifiSettings]);

    return (
        <table className="table table-sm mb-1">
            <tbody>
                <TableRowInput
                    name="Ssid"
                    currentValue={wifiSettings.ssid}
                    originalValue={props.wifiSettings.ssid}
                    inputType="text"
                    onSave={save}
                    onChange={newValue => setWifiSettings({ ...wifiSettings, ssid: newValue as string })} />

                <TableRowDropdown
                    name="Authentication"
                    currentValue={Number(wifiSettings.authentication)}
                    originalValue={Number(props.wifiSettings.authentication)}
                    type={Authentication}
                    onChange={newValue => setWifiSettings({ ...wifiSettings, authentication: newValue })}
                    onSave={save} />

                <TableRowInput
                    name="Ipv4"
                    currentValue={encodedBytesToText(wifiSettings.ipv4)}
                    originalValue={encodedBytesToText(props.wifiSettings.ipv4)}
                    inputType="text"
                    onSave={save}
                    onChange={newValue => setWifiSettings({ ...wifiSettings, ipv4: textToEncodedBytes(newValue as string) })} />

                <TableRowInput
                    name="Ipv6"
                    currentValue={encodedBytesToText(wifiSettings.ipv6)}
                    originalValue={encodedBytesToText(props.wifiSettings.ipv6)}
                    inputType="text"
                    onSave={save}
                    onChange={newValue => setWifiSettings({ ...wifiSettings, ipv6: textToEncodedBytes(newValue as string) })} />

                <TableRowInput
                    name="Gateway"
                    currentValue={encodedBytesToText(wifiSettings.defaultGateway)}
                    originalValue={encodedBytesToText(props.wifiSettings.defaultGateway)}
                    inputType="text"
                    onSave={save}
                    onChange={newValue => setWifiSettings({ ...wifiSettings, defaultGateway: textToEncodedBytes(newValue as string) })} />

                <TableRowInput
                    name="Mac address"
                    currentValue={encodedBytesHexToText(wifiSettings.macWifi)}
                    originalValue={encodedBytesHexToText(props.wifiSettings.macWifi)}
                    inputType="text"
                    onSave={save}
                    onChange={newValue => setWifiSettings({ ...wifiSettings, macWifi: textToEncodedBytesHex(newValue as string) })} />
            </tbody>
        </table>
    );
};