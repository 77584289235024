import * as React from "react";
import { connect, useDispatch } from "react-redux";
import { ApplicationState } from "../../store";
import { genericActionCreator, Action, StateToUpdate, mergeActionCreators, GenericActionCreator } from "../../store/LegacyStore/actions";
import { UWG4Swimlane } from "./UWG4Swimlane";
import { AlertType } from "../../store/AlertStore/models";
import { popupAlert } from "../../Utility";
import { UWG4Thermostat } from "../../store/LegacyStore/models/uwg4";
import { uwg4ActionCreator, UWG4ActionCreator } from "../../store/LegacyStore/actions/uwg4";
import { uwg4Hub } from "../../store/LegacyStore/hubs";

type Props =
    ApplicationState &
    GenericActionCreator &
    UWG4ActionCreator;

const UWG4List = (props: Props) => {
    // Component state
    const [searchText, setSearchText] = React.useState("");
    const dispatch = useDispatch();

    /**
     * Normalize string.
     */
    const normalizeString = (stringToNormalize: string): string => (stringToNormalize || "").trim().toUpperCase();

    /**
     * Search for thermostats matchnig the search text.
     */
    const searchThermostats = () => {
        const normalizedSearchText = normalizeString(searchText);

        if (normalizedSearchText === "") {
            return props.legacy.UWG4.thermostats
                .filter(thermostat => thermostat.workspaceID === props.workspace.focused.id)
                .map(thermostat => thermostat as UWG4Thermostat);
        }

        return props.legacy.UWG4.thermostats
            .filter(thermostat => thermostat.workspaceID === props.workspace.focused.id)
            .filter(thermostat => normalizeString(thermostat.internalStorage.name).includes(normalizedSearchText))
            .map(thermostat => thermostat as UWG4Thermostat);
    };

    /**
     * Remove all thermostats in the selected workspace.
     */
    const removeAll = () => {
        if (window.confirm("Do you wish to remove all UWG4 thermostats?")) {
            props.legacy.UWG4.thermostats
                .filter(t => t.workspaceID === props.workspace.focused.id)
                .forEach(t => props.invoke(t.thermostatID, Action.Remove));

            popupAlert(dispatch, "Thermostats removed!", "You have successfully removed all the thermostats.", AlertType.SUCCESS);
        }
    };

    /**
     * Saves all thermostats to database in the selected workspace.
     */
    const saveAll = () => {
        props.legacy.UWG4.thermostats
            .filter(t => t.workspaceID === props.workspace.focused.id)
            .forEach(t => props.invoke(t.thermostatID, Action.Save));

        popupAlert(dispatch, "Thermostats saved!", "You have successfully saved all the thermostats.", AlertType.SUCCESS);
    };

    // Render
    return (
        <>
            {/*Search Bar*/}
            <p className="row">
                <label className="col-2">Search by Name:</label>
                <input
                    type="text"
                    className="form-control form-control-sm col-3"
                    style={{ outline: "none" }}
                    value={searchText}
                    onChange={(e) => setSearchText(e.target.value)} />
            </p>

            {/* UWG4 thermostats */}
            <p>
                <span className="badge badge-secondary badge-pill align-middle mt-n1">{searchThermostats().length}</span> UWG4 thermostats
                <button className="btn btn-sm btn-link text-primary mt-n1" onClick={() => saveAll()}>Save all</button>
                <button className="btn btn-sm btn-link text-danger mt-n1" onClick={() => removeAll()}>Remove all</button>
            </p>
            {searchThermostats().length > 0 ?
                <div>
                    {searchThermostats().map(thermostat =>
                        <UWG4Swimlane
                            key={thermostat.thermostatID}
                            thermostat={thermostat}
                            invokeAction={(action: Action) => props.invoke(thermostat.thermostatID, action)}
                            updateState={(value: any, toUpdate: StateToUpdate) => props.updateState(thermostat.thermostatID, value, toUpdate)}
                            SetComfortMode={(days: number, hours: number, setpoint: number) => props.setComfortMode(thermostat.thermostatID, days, hours, setpoint)}
                            SetVacationMode={(days: number, hours: number, setpoint: number) => props.setVacationMode(thermostat.thermostatID, days, hours, setpoint)}
                        />
                    )}
                </div>
                :
                <p className="text-center">There are no UWG4 thermostats in the selected workspace! Try a different workspace. {searchThermostats().length}</p>
            }
        </>
    );
};

export default connect(
    (state: ApplicationState) => state,
    mergeActionCreators(genericActionCreator(uwg4Hub), uwg4ActionCreator(uwg4Hub))
)(UWG4List as any);
