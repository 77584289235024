import * as React from "react";
import { connect, useDispatch } from "react-redux";
import { AlertType } from "../store/AlertStore/models";
import { ApplicationState } from "../store";
import { actionCreator } from "../store/WorkspaceStore/actions";
import { Simulators } from "../store/WorkspaceStore/models";
import { popupAlert } from "../Utility";

type Props =
    ApplicationState &
    typeof actionCreator;

const SimulatorDropdown = (props: Props) => {
    const [selectedSimulator, setSelectedSimulator] = React.useState<Simulators>(props.workspace.simulator);
    const dispatch = useDispatch();

    React.useEffect(() => {
        setSelectedSimulator(props.workspace.simulator);
    }, [props.workspace.simulator, setSelectedSimulator]);

    const setSimulator = (simulator: Simulators): void => {
        if (window.location.href.includes("wizard") || window.location.href.includes("load")) {
            popupAlert(dispatch, "Exit the wizard!", "You will have to exit the create wizard to switch to another simulator.", AlertType.DANGER);

            return;
        }

        dispatch({ type: "SET_SIMULATOR", simulator: simulator });
        props.selectWorkspace(1);
        popupAlert(dispatch, "Simulator selected!", "You have successfully selected a simulator.", AlertType.SUCCESS);
    };

    return (
        <div className="dropdown">
            <button className="btn dropdown-toggle overflow-hidden text-light font-weight-bold" style={{ minWidth: 260, fontSize: 20 }} type="button" id="simulatorMenu" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                {Simulators[selectedSimulator] + " Simulator"}
            </button>
            <div className="dropdown-menu overflow-auto" aria-labelledby="simulatorMenu">
                <button className="dropdown-item dropdown-header font-weight-bold text-wrap overflow-hidden" onClick={() => setSimulator(Simulators.SimpleWifi)}>
                    UWG5 Simulator
                </button>
                <button className="dropdown-item dropdown-header font-weight-bold text-wrap overflow-hidden" onClick={() => setSimulator(Simulators.UWG5Nvent)}>
                    UWG5 nVent Simulator
                </button>
                <button className="dropdown-item dropdown-header font-weight-bold text-wrap overflow-hidden" onClick={() => setSimulator(Simulators.NuHeat)}>
                    NuHeat Simulator
                </button>
                <button className="dropdown-item dropdown-header font-weight-bold text-wrap overflow-hidden" onClick={() => setSimulator(Simulators.UWG4)}>
                    UWG4 Simulator
                </button>
                <button className="dropdown-item dropdown-header font-weight-bold text-wrap overflow-hidden" onClick={() => setSimulator(Simulators.Senz)}>
                    Senz Simulator
                </button>
                <button className="dropdown-item dropdown-header font-weight-bold text-wrap overflow-hidden" onClick={() => setSimulator(Simulators.OWD5)}>
                    OWD5 Simulator
                </button>
            </div>
        </div>
    );
};

export default connect(
    (state: ApplicationState) => state,
    actionCreator,
)(SimulatorDropdown as any);
