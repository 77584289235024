import * as React from "react";
import { useDispatch } from "react-redux";
import { RouteComponentProps } from "react-router";
import { AlertType } from "../../store/AlertStore/models";
import { GenericActionCreator } from "../../store/LegacyStore/actions";
import { CreateLegacyState, LegacyThermostatType, ThermostatType } from "../../store/LegacyStore/models";
import { popupAlert } from "../../Utility";

type Props =
    RouteComponentProps &
    GenericActionCreator &
    ThermostatType

export function LegacyCreateWizard(props: Props) {
    // Component state
    const [showCheckmark, setShowCheckmark] = React.useState(false);
    const [redirectToHome, setRedirectToHome] = React.useState(true);
    const [serialNumber, setSerialNumber] = React.useState("");
    const [email, setEmail] = React.useState("");
    const [serverIp, setServerIp] = React.useState("");
    const dispatch = useDispatch();

    /**
    * Handle created new simulated thermostat(s).
    */
    const onCreated = () => {
        popupAlert(dispatch, "Thermostat created!", "You have successfully created the thermostat", AlertType.SUCCESS);

        if (redirectToHome) {
            props.history.push("/");
        } else {
            setEmail("");
            setSerialNumber("");
            setShowCheckmark(true);

            setTimeout(() => {
                setShowCheckmark(false);
            }, 3000);
        }
    };

    /**
    * Map component state to thermostat state slice.
    */
    const mapState = (): CreateLegacyState => ({
        serverIp: serverIp,
        serialNumber: serialNumber,
        email: email
    });

    /**
    * Create one simulated thermostat.
    */
    const createOne = () => {
        const thermostatState = mapState();
        props.createNew(1, thermostatState);
        onCreated();
    };

    // Render
    return (
        <div className="bg-white rounded-lg shadow p-3">
            <h3 className="font-weight-light mb-3">
                <svg width="1em" height="1em" viewBox="0 0 16 16" className="bi bi-plus-circle mt-n2 mr-1" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                    <path fillRule="evenodd" d="M8 3.5a.5.5 0 0 1 .5.5v4a.5.5 0 0 1-.5.5H4a.5.5 0 0 1 0-1h3.5V4a.5.5 0 0 1 .5-.5z" />
                    <path fillRule="evenodd" d="M7.5 8a.5.5 0 0 1 .5-.5h4a.5.5 0 0 1 0 1H8.5V12a.5.5 0 0 1-1 0V8z" />
                    <path fillRule="evenodd" d="M8 15A7 7 0 1 0 8 1a7 7 0 0 0 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                </svg><b>{LegacyThermostatType[props.thermostatType]}</b> <span className="text-muted">Create Wizard</span></h3>
            <hr />

            <div className="form-group row">
                <label className="col-md-2 col-form-label">Serial Number</label>
                <div className="col-md-8">
                    <input className="form-control" value={serialNumber} onChange={(e) => setSerialNumber(e.target.value)} />
                </div>
            </div>
            <div className="form-group row">
                <label className="col-md-2 col-form-label">Server Ip</label>
                <div className="col-md-10">
                    <input className="form-control" value={serverIp} onChange={(e) => setServerIp(e.target.value)} />
                </div>
            </div>
            <div className="form-group row">
                <label className="col-md-2 col-form-label">Email</label>
                <div className="col-md-10">
                    <input className="form-control" value={email} onChange={(e) => setEmail(e.target.value)} />
                </div>
            </div>
            <hr />
            <button type="button" className="btn btn-primary mr-2" onClick={() => createOne()}>Create one</button>
            <span hidden={!showCheckmark} className="text-success mr-2">
                <svg width="1.5em" height="1.5em" viewBox="0 0 16 16" className="bi bi-check-circle mr-2" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                    <path fillRule="evenodd" d="M8 15A7 7 0 1 0 8 1a7 7 0 0 0 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                    <path fillRule="evenodd" d="M10.97 4.97a.75.75 0 0 1 1.071 1.05l-3.992 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425a.236.236 0 0 1 .02-.022z" />
                </svg>
                Success!
            </span>
            <p className="mt-2 mb-0"><input type="checkbox" checked={redirectToHome} onChange={() => setRedirectToHome(!redirectToHome)} /> Redirect to home</p>
        </div>
    );
}