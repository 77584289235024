import React, { FC, useState, useEffect } from "react";
import { TableRowInput } from "../Common";
import { FloorSensorSettings as Model } from "./../../store/SimpleWifiStore/models";

export interface FloorSensorSettingsProps {
    floorSensorSettings: Model,
    saveFloorSensorSettings: (newFloorSensorSettings: Model) => void
}

export const FloorSensorSettings: FC<FloorSensorSettingsProps> = (props) => {
    const [floorSensorSettings, setFloorSensorSettings] = useState(props.floorSensorSettings);
    const save = () => props.saveFloorSensorSettings(floorSensorSettings);

    useEffect(() => {
        setFloorSensorSettings(props.floorSensorSettings);
    }, [props.floorSensorSettings]);

    return (
        <table className="table table-sm mb-1">
            <tbody>
                <TableRowInput
                    name="Value 15 deg"
                    currentValue={floorSensorSettings.value15deg}
                    originalValue={props.floorSensorSettings.value15deg}
                    inputType="number"
                    onSave={save}
                    onChange={newValue => setFloorSensorSettings({ ...floorSensorSettings, value15deg: Number(newValue) })} />

                <TableRowInput
                    name="Value 20 deg"
                    currentValue={floorSensorSettings.value20deg}
                    originalValue={props.floorSensorSettings.value20deg}
                    inputType="number"
                    onSave={save}
                    onChange={newValue => setFloorSensorSettings({ ...floorSensorSettings, value20deg: Number(newValue) })} />

                <TableRowInput
                    name="Value 25 deg"
                    currentValue={floorSensorSettings.value25deg}
                    originalValue={props.floorSensorSettings.value25deg}
                    inputType="number"
                    onSave={save}
                    onChange={newValue => setFloorSensorSettings({ ...floorSensorSettings, value25deg: Number(newValue) })} />

                <TableRowInput
                    name="Value 30 deg"
                    currentValue={floorSensorSettings.value30deg}
                    originalValue={props.floorSensorSettings.value30deg}
                    inputType="number"
                    onSave={save}
                    onChange={newValue => setFloorSensorSettings({ ...floorSensorSettings, value30deg: Number(newValue) })} />
            </tbody>
        </table>
    );
};
