export enum SenzRegulationMode {
    NotSpecified = 0,
    Auto = 1,
    Comfort = 2,
    Manual = 3,
    Vacation = 4,
    Off = 5,
    FrostProtection = 6,
    FirstWarming = 7,
    RegulatorMode = 8
}

export interface SenzInternalStorage {
    name: string,
    serverIp: string,
    serverPort: number,
    setPointInCelsius: number,
    currentTemperatureInCelsius: number,
    serialNumber: string,
    email: string,
    softwareVersion: string,
    minSetPointInCelsius: number,
    maxSetPointInCelsius: number,
    regulationMode: SenzRegulationMode,
    vacationEndDate: Date,
    comfortEndDate: Date,
    firstWarmingDays: number
    isLoggedOn: boolean,
    useDaylightSavingsTime: boolean,
    isConnectedToServer: boolean,
    isConnectingToServer: boolean,
    isConfirmed: boolean
}

export interface SenzThermostat {
    ID: number,
    thermostatID: string,
    internalStorage: SenzInternalStorage,
    currentState: string,
    workspaceID: number;
}