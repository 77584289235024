import { getUserAccessToken } from "./oidcUtilities";
import {
    HubConnection,
    HubConnectionBuilder,
    IHttpConnectionOptions,
    LogLevel
} from "@microsoft/signalr";
import { trimSlashes } from "./Utility";

export const appBaseUrl = document.getElementsByTagName("base")[0].getAttribute("href") as string;
export const appAbsoluteBaseUrl = document.location.origin + appBaseUrl;
export const signalrAbsoluteBaseUrl = document.location.origin + appBaseUrl;
export const signalrHubsPrefix = "hubs";

export interface ApiConfig {
    userApiUri: string,
    adminApiUri: string
}

let apiConfig: ApiConfig;

export function setApiConfig(config: ApiConfig): void {
    apiConfig = { ...config };
}

export const defaultHubOptions : IHttpConnectionOptions = {
    accessTokenFactory(): string | Promise<string> {
        return getUserAccessToken().then(token => token || "");
    },
    skipNegotiation: false,
    //transport: HttpTransportType.WebSockets,
    logMessageContent: true,
    logger: LogLevel.Information
};

export const buildHub = (path: string, baseUrl?: string, noPrefix?: boolean): HubConnection => {
    path = trimSlashes(path);
    baseUrl = !!baseUrl ? baseUrl : signalrAbsoluteBaseUrl;
    baseUrl = trimSlashes(baseUrl);
    path = !!noPrefix ? path : `${signalrHubsPrefix}/${path}`;
    const hubUrl = `${baseUrl}/${path}`;

    return new HubConnectionBuilder()
        .withAutomaticReconnect()
        .withUrl(hubUrl, defaultHubOptions)
        .build();
};
