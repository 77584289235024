import * as React from "react";
import { Collapse } from "reactstrap";
import { Schedule, DaysOfWeek, ScheduleEvent } from "../../store/UWG5NventStore/models";
import { UWG5NventScheduleDay } from "./UWG5NventScheduleDay";
import { useState } from "react";

type Props = {
    schedule: Schedule,
    setCurrentScheduleEvent(day: number, eventId: number): void
}

type ScheduleRowProps = {
    day: number, events: ScheduleEvent[], refresh: boolean,
    setCurrentScheduleEvent(day: number, eventId: number): void
};

const ScheduleRow = (scheduleRowProps: ScheduleRowProps) => {
    const [isOpen, setIsOpen] = React.useState(false);

    return (
        <tr>
            <td>
                <button
                    className="btn btn-sm btn-link border border-primary rounded ml-0 pl-0 col-2"
                    onClick={() => setIsOpen(!isOpen)}>{DaysOfWeek[scheduleRowProps.day]}</button>
                <Collapse isOpen={isOpen}>
                    <UWG5NventScheduleDay refresh={scheduleRowProps.refresh} day={scheduleRowProps.day} events={scheduleRowProps.events} setCurrentScheduleEvent={scheduleRowProps.setCurrentScheduleEvent} />
                </Collapse>
            </td>
        </tr>
    );
};

export const UWG5NventSchedule = (props: Props) => {
    const [schedule, setSchedule] = useState(props.schedule);
    const [refresh, setRefresh] = useState(false);
    const onEventSelected = (day: number, event: number) => {
        schedule.scheduleTable.forEach(e => e.isActive = false);
        const index = (day * 6) + event;
        const requestedEvent = props.schedule.scheduleTable[index];
        requestedEvent.isActive = true;
        setSchedule(props.schedule);
        setRefresh(!refresh);
        if(requestedEvent.enabled) {
            props.setCurrentScheduleEvent(day, event);
        }else {
            props.setCurrentScheduleEvent(6, 6);
        }
    };

    return (
        <div className="ml-0 mr-0">
            <table className="table table-sm mb-1">
                <tbody>
                    <tr>
                        <td>
                            <label><b>Base SetPoint:&nbsp;&nbsp;</b>{props.schedule.baseSetpoint}</label>
                        </td>
                    </tr>
                    <ScheduleRow refresh={refresh} day={1} events={schedule.scheduleTable.slice(1 * 6, 1 * 6 + 6)} setCurrentScheduleEvent={onEventSelected} />
                    <ScheduleRow refresh={refresh} day={2} events={schedule.scheduleTable.slice(2 * 6, 2 * 6 + 6)} setCurrentScheduleEvent={onEventSelected}/>
                    <ScheduleRow refresh={refresh} day={3} events={schedule.scheduleTable.slice(3 * 6, 3 * 6 + 6)} setCurrentScheduleEvent={onEventSelected}/>
                    <ScheduleRow refresh={refresh} day={4} events={schedule.scheduleTable.slice(4 * 6, 4 * 6 + 6)} setCurrentScheduleEvent={onEventSelected}/>
                    <ScheduleRow refresh={refresh} day={5} events={schedule.scheduleTable.slice(5 * 6, 5 * 6 + 6)} setCurrentScheduleEvent={onEventSelected}/>
                    <ScheduleRow refresh={refresh} day={6} events={schedule.scheduleTable.slice(6 * 6, 6 * 6 + 6)} setCurrentScheduleEvent={onEventSelected}/>
                    <ScheduleRow refresh={refresh} day={0} events={schedule.scheduleTable.slice(0 * 6, 0 * 6 + 6)} setCurrentScheduleEvent={onEventSelected}/>
                </tbody>
            </table>
        </div>
    );
};
