import { Action, Reducer } from "redux";
import { Workspaces } from "./models";
import { KnownAction } from "./actionTypes";
import { LocalStorage } from "../../LocalStorage";

export const workspaceReducer: Reducer<Workspaces> = (state: Workspaces | undefined, incomingAction: Action): Workspaces => {
    if (state === undefined) {
        return LocalStorage.getWorkspace();
    }

    const action = incomingAction as KnownAction;
    switch (action.type) {
        case "RECEIVE_ALL_USER_CATEGORIES":
            return { ...state, categories: action.categories };
        case "NEW_FOCUSED_WORKSPACE": {
            const newState = { ...state, focused: action.selected };
            LocalStorage.setWorkspace(newState);

            return newState;
        }
        case "NEW_CATEGORY":
            return { ...state, categories: [...state.categories, action.category] };
        case "REMOVE_CATEGORY":
            return { ...state, categories: state.categories.filter(category => category.id !== action.category.id) };
        case "UPDATE_CATEGORY":
            return { ...state, categories: state.categories.map(category => (category.id === action.category.id ? action.category : category)) };
        case "SET_SIMULATOR":
            return { ...state, simulator: action.simulator };
        default:
            return state;
    }
};

