// libs
import * as React from "react";
import { connect } from "react-redux";
import { Route, Switch } from "react-router";
import { UserState } from "redux-oidc";
import { useEffect } from "react";

// components
import Layout from "./components/Layout";
import ThermostatSimulator from "./components/SimulationTable";
import SimpleWifiCreateWizard from "./components/SimpleWifi/CreateWizard";
import SimpleWifiLoadWizard from "./components/SimpleWifi/LoadWizard";
import UWG5NventCreateWizard from "./components/UWG5Nvent/CreateWizard";
import UWG5NventLoadWizard from "./components/UWG5Nvent/LoadWizard";
import { LegacyCreateWizard } from "./components/Legacy/CreateWizard";
import { LegacyLoadWizard } from "./components/Legacy/LoadWizard";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import { withUWG4 } from "./components/Legacy/hoc/uwg4";
import { withNuheat } from "./components/Legacy/hoc/nuheat";
import { withSenz } from "./components/Legacy/hoc/senz";
import { withOWD5 } from "./components/Legacy/hoc/owd5";
import {
    CustomCallback,
    NotFound,
    UnauthorizedPage,
    Spinner,
    mapAuthContextStateToProps
} from "./components/Common";
import { startHubs } from "./index";

// state
import { ApplicationState } from "./store";
import { IAuthProps } from "./store/authTypes";

// style
import "./custom.css";

type AppProps = {
    location: Location;
    oidc?: UserState;
} & IAuthProps;

const App = (props: AppProps) => {
    // Render if loading user or location is unknown
    if (!props.oidc || props.oidc.isLoadingUser || !props.location) {
        return <Spinner description="Loading..." />;
    }

    // Render if NOT admin
    if (props.isAuthenticated && !props.isAdmin) {
        return <UnauthorizedPage
            description="You have to be an administrator to access this site." />;
    }

    useEffect(() => {
        console.debug("User changed: ", props.user);
        startHubs(props.user);
    }, [props.user]);

    // Render if admin or supporter
    return (
        <LocalizationProvider dateAdapter={AdapterDateFns}>
            <Layout>
                <Switch>
                    <Route exact path='/' component={ThermostatSimulator} />
                    <Route path='/wizard/simplewifi' component={SimpleWifiCreateWizard} />
                    <Route path='/load/simplewifi' component={SimpleWifiLoadWizard} />
                    <Route path='/wizard/uwg5nvent' component={UWG5NventCreateWizard} />
                    <Route path='/load/uwg5nvent' component={UWG5NventLoadWizard} />
                    <Route path='/wizard/nuheat' component={withNuheat(LegacyCreateWizard)} />
                    <Route path='/load/nuheat' component={withNuheat(LegacyLoadWizard)} />
                    <Route path='/wizard/uwg4' component={withUWG4(LegacyCreateWizard)} />
                    <Route path='/load/uwg4' component={withUWG4(LegacyLoadWizard)} />
                    <Route path='/wizard/senz' component={withSenz(LegacyCreateWizard)} />
                    <Route path='/load/senz' component={withSenz(LegacyLoadWizard)} />
                    <Route path='/wizard/owd5' component={withOWD5(LegacyCreateWizard)} />
                    <Route path='/load/owd5' component={withOWD5(LegacyLoadWizard)} />

                    {/* OIDC */}
                    <Route path="/authentication/callback" component={CustomCallback} />

                    {/* Default route */}
                    <Route path="*" component={NotFound} />
                </Switch>
            </Layout>
        </LocalizationProvider>
    );
};

const mapStateToProps = (state: ApplicationState & { router: { location: Location } }) : AppProps =>
    ({
        ...mapAuthContextStateToProps(state),
        location: state.router.location,
        oidc: state.oidc
    });

export default connect(mapStateToProps)(App);
