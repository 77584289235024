import { HubConnection, HubConnectionState } from "@microsoft/signalr";
import { Store } from "redux";
import { LegacyThermostatType, Thermostat } from "./models";
import {
    AddLoadableTypeThermostat,
    ReceieveLoadableTypeThermostats,
    ReceiveAllTypeThermostats,
    ReceiveNewTypeThermostat,
    RemoveLoadableTypeThermostat,
    RemoveTypeThermostat,
    UpdateLoadableTypeThermostat,
    UpdateTypeThermostatState
} from "./actionTypes";

import { buildHub } from "../../ApiGateway";

export const nuheatHub = buildHub("NuheatHub");
export const uwg4Hub = buildHub("UWG4Hub");
export const senzHub = buildHub("SenzHub");
export const owd5Hub = buildHub("OWD5Hub");

/**
 * Connect to UWG4 SignalR hub and register handlers.
 */
export function connectToHub(store: Store, connection: HubConnection, thermostatType: LegacyThermostatType) {
    if (connection.state !== HubConnectionState.Disconnected) {
        return;
    }

    connection.start()
        .catch(err => console.error("Failed to start " + thermostatType + "Hub", err));

    connection.on("ReceiveAllThermostats", (thermostats: Thermostat[]) => {
        store.dispatch({ type: "RECEIVE_ALL_TYPE_THERMOSTATS", thermostats, thermostatType } as ReceiveAllTypeThermostats);
    });

    connection.on("ReceiveNewThermostat", (thermostat: Thermostat) => {
        store.dispatch({ type: "RECEIVE_NEW_TYPE_THERMOSTAT", thermostat, thermostatType } as ReceiveNewTypeThermostat);
    });

    connection.on("UpdateThermostatState", (thermostat: Thermostat) => {
        store.dispatch({ type: "UPDATE_TYPE_THERMOSTAT_STATE", thermostat, thermostatType } as UpdateTypeThermostatState);
    });

    connection.on("RemoveThermostat", (id: string) => {
        store.dispatch({ type: "REMOVE_TYPE_THERMOSTAT", id, thermostatType } as RemoveTypeThermostat);
    });

    connection.on("ReceiveLoadableThermostats", (thermostats: Thermostat[]) => {
        store.dispatch({ type: "RECEIVE_LOADABLE_TYPE_THERMOSTATS", thermostats, thermostatType } as ReceieveLoadableTypeThermostats);
    });

    connection.on("RemoveLoadableThermostat", (id: string) => {
        store.dispatch({ type: "REMOVE_LOADABLE_TYPE_THERMOSTAT", id, thermostatType } as RemoveLoadableTypeThermostat);
    });

    connection.on("AddLoadableThermostat", (thermostat: Thermostat) => {
        store.dispatch({ type: "ADD_LOADABLE_TYPE_THERMOSTAT", thermostat, thermostatType } as AddLoadableTypeThermostat);
    });

    connection.on("UpdateLoadableThermostat", (thermostat: Thermostat) => {
        store.dispatch({ type: "UPDATE_LOADABLE_TYPE_THERMOSTAT", thermostat, thermostatType } as UpdateLoadableTypeThermostat);
    });
}
